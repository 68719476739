import { InputTextarea } from 'primereact/inputtextarea';
import { Rating } from 'primereact/rating';
import { Button } from "primereact/button";
import { Link } from 'react-router-dom';
import React, { useContext, useState , useEffect  } from 'react';
import { StoreContext } from './store/StoreProvider';
import {types}  from './store/StoreReducer';
import axios from "axios"; 

export const Puntuacion = (props) => {

const [store, dispatch ] = useContext(StoreContext);
const { contacto, user, score, ruta, img, api } = store;
const [val, setVal] = useState({v1:0, v2:0, v3:0, v4:0, calif:0});
const [comentario, setComentario] = useState('');  
const [score1, setScore1] = useState({votos:0});  
const fecha = new Date().toLocaleDateString("es-PE",{month: "long"})+' '+(new Date()).getDate()+', '+(new Date()).getFullYear();
const fotoF = ruta+ img.fotoF;
const fotoM = ruta+ img.fotoM;
const logo = ruta+ img.logo;


//console.log(contacto);

useEffect(() => {
  document.getElementById('footer').style.left='0';
  document.getElementById('footer').style.width='98.7vw';
  ContactoData(contacto.Contacto_ID);
  console.log(props);
  //eslint-disable-next-line
}, []);



const ContactoData = async (id) => {
	let resource = await serverContacto(id);
	dispatch({ type: types.contacto, payload: {...contacto, ...resource.data, load:'porContacto'} })
  }


const serverContacto = async (id) => {
  var config = { headers: { 'content-type': 'multipart/form-data' }};
  const formData = new FormData();
  formData.append('operacion', 5);
  formData.append('Contacto_ID', id);
  return await axios.post(api, formData, config);
  } 


const registrar = async (id) => {
	let resource = await serverRegistrar(id);
	console.log(resource.data);
	setScore1(resource.data);
  }


const serverRegistrar = async (id) => {
  var config = { headers: { 'content-type': 'multipart/form-data' }};
  const formData = new FormData();
  formData.append('operacion', 16);
  formData.append('Contacto_ID', contacto.Contacto_ID);
  formData.append('Usu_ID', user.Usu_ID);  
  formData.append('id', contacto.Evento_ID);   
  formData.append('tipo', val.calif);     
  formData.append('descripcion', comentario);   
  formData.append('alcance', val.v1);  // Alcance   
  formData.append('com', val.v2);   // Comunicacion   
  formData.append('time', val.v3);   // tiempo 
  formData.append('costo', val.v4);   // costo   
  return await axios.post(api, formData, config);
  } 


const enable = () =>{
let out = false
 if([val.calif, val.v1, val.v2, val.v3, val.v4].includes(0) || comentario==='') out =true;
 return out;
}

const evalu = (dato) =>{
console.log(dato);
console.log(contacto);
setVal({...val , calif:dato})
}



return (
<div className="p-fluid dashboard">
	<div className="card contactoN">
		<div className="layout-Seeker" style={{marginTop: '22px'}}>
		
		
			<div className='p-grid panelContacto'>
				{score1.votos===0?
				<div>
					<div className="acerca oculto"> 
						<h2>Puntuación</h2>
						<p style={{cursor:'pointer'}}>
						<i className="pi pi-star"  style={{float:'left',color:'#03a9f4', margin:'2px 0px 0 0'}}></i>
						<span style={{fontWeight:'bold', float:'left', color:'#03a9f4',marginRight:'8px'}}>
						{((val.v1+val.v2+val.v3+val.v4)/4).toFixed(2)}</span>(30 opiniones)</p>	
						<p>{comentario} </p>
						<p>{fecha+' . Juan Luis . Usuario verificado'} </p>
					</div> 	
					<h1>Dejar comentario</h1>
					<div > 

						<div style={{width:'100%', display:'flex', flexWrap:'wrap',position:'relative', textAlign:'center'}}>				
						
							<div  style={{width:'50%', textAlign:'left', padding:'0.6em 0'}}><h2>Transacción</h2></div>	
							<div  style={{width:'calc(50% - 1em)', textAlign:'left', padding:'0.6em 0'}}><h2 style={{marginLeft:'0.8em'}}>Vendedor</h2></div>	

							<div  style={{width:'50%', clear:'both', padding:'4em 10px 1em', margin:'-3.7em 1em -1em 0em', textAlign:'left', borderRight:'2px solid #f9f7f6'}}>	
							<span style={{fontSize:'16px'}}>{contacto.Evento} </span>
							</div>	
								
							<div style={{ width:'calc(50% - 1em)',  flexWrap:'wrap',position:'relative', textAlign:'center' }}>
								<img src={ contacto.tipo >= '3'?contacto.Foto?ruta + contacto.Foto:(contacto.tipo === '3' ? ruta + fotoM : ruta + fotoF):contacto.logo?ruta + contacto.logo:ruta + logo} alt="Foto de perfil" style={{background:contacto.color, width: contacto.tipo >= '3'?'15%':'25%', marginBottom:'1em'}}/>
								<div style={{float:'right', width:contacto.tipo >= '3'?'85%':'75%', padding: '0 0.7em'}}><h2>{contacto.Nombre_Comercial}<span style={{marginLeft:'5px', fontSize:'17px', color:'#006aa7' }}>(98734<i className='fa fa-star' style={{color:'#fecc00', fontSize:'12px', position:'relative', top:'-2px'}}/>)</span></h2>
								</div>							
							</div>
						</div>						

					</div> 	
					
					<div className="acerca"> 
						<table style={{width:'100%'}}>
							 <tr ><td style={{width:'30%'}}>
								<h2>¿Cómo fue tu experiencia?*</h2>
								<>Evalúa esta transacción</>
							 </td>
							 <td>
							<Button icon='fa fa-plus-circle' className="evaluar" label="Positiva" onClick={() => evalu('3')} style={{background:val.calif==='3'?'#fff9c4':'#ffffff', boxShadow:val.calif==='3'?'rgb(0 0 0 / 10%) 0px 0px 1px 1px':'rgb(0 0 0 / 35%) 0px 2px 3px 0px', width:'calc(33% - 10px)', margin:'5px', color:'#54a754'}}/>
							<Button icon='fa fa-stop-circle' className="evaluar" label="Neutral"  onClick={() => evalu('2')} style={{background:val.calif==='2'?'#fff9c4':'#ffffff', boxShadow:val.calif==='2'?'rgb(0 0 0 / 10%) 0px 0px 1px 1px':'rgb(0 0 0 / 35%) 0px 2px 3px 0px', width:'calc(33% - 10px)', margin:'5px', color:'#333333'}}/>
							<Button icon='fa fa-minus-circle' className="evaluar" label="Negativa" onClick={() => evalu('1')} style={{background:val.calif==='1'?'#fff9c4':'#ffffff', boxShadow:val.calif==='1'?'rgb(0 0 0 / 10%) 0px 0px 1px 1px':'rgb(0 0 0 / 35%) 0px 2px 3px 0px', width:'calc(33% - 10px)', margin:'5px', color:'#c0503a'}}/>
							 </td></tr> 
						</table>
					</div> 					
					<div className="acerca"> 
						<table style={{width:'100%'}}>
							 <tr ><td style={{width:'30%'}}>					
								<h2>¿Qué más quieres agregar?*</h2>
								<>Proporciona más detalles</>
							 </td>
							 <td>
							<InputTextarea id="inweblocal" placeholder="Comparte y comenta tu experiencia para ayudar a otros usuarios" value={comentario} onChange={(e) => setComentario(e.target.value)} autoResize={true} rows={3} cols={30}/> 
							 </td></tr> 
						</table>								
					</div> 					

					<div className="acerca"> 
						<h2>Evalúa esta transacción</h2>
						<table style={{ }}>
							 <tr ><td style={{width:'23em', height:'3em'}}>					
								<span>{score.alcance}</span>
							 </td>
							 <td>
							 <Rating value={val.v1} cancel={false} onChange={(e) => setVal({...val , v1:e.value})}/>
							 </td></tr> 
							 <tr ><td style={{height:'3em'}}>					
								<span>{score.comunica}</span>
							 </td>
							 <td>
							 <Rating value={val.v2} cancel={false} onChange={(e) => setVal({...val , v2:e.value})} />
							 </td></tr> 
							 <tr ><td style={{height:'3em'}}>					
								<span>{score.tiempo}</span>
							 </td>
							 <td>
							 <Rating value={val.v3} cancel={false} onChange={(e) => setVal({...val , v3:e.value})} />
							 </td></tr> 
							 <tr ><td style={{height:'3em'}}>					
								<span>{score.costo}</span>
							 </td>
							 <td>
							 <Rating value={val.v4} cancel={false} onChange={(e) => setVal({...val , v4:e.value})} />
							 </td></tr> 							 
						</table>							
						
					</div>

					<div className="acerca"> 
							<Button className="agendar" disabled={enable()?true:false} label="Dejar comentarios" onClick={() => registrar()} style={{margin:'5px'}}/>
							<Link to={'/'+user?.Nombres?.split(' ').join('-')+'/perfil'}><Button className="agendar" label="Cancelar"  onClick={() => evalu({etapa:'1'})} style={{ margin:'5px'}}/></Link>	
							<p>Una vez que envíes tus comentarios, no podrás cambiarlos.</p>
					</div> 	
 					
					
				</div>:
				<div style={{height:'calc(100vh - 193px)'}}>
				<h1>Gracias por registrar una realimentación al servicio recibido</h1>
				</div>}
			</div>
		
		
		</div>
	</div>    
</div>    
);









}






