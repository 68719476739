import classNames from 'classnames';
import { Link } from "react-router-dom";
import React, { useContext, useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { InfoData } from "./InfoData";
import { StoreContext } from './store/StoreProvider';
import { types }  from './store/StoreReducer';


export const Registro = (props) => {


const [store, dispatch] = useContext(StoreContext);
const {paises, ciudades, provincias, distritos, ubicacion, ruta, img, testMail} = store;
const [user, setUser] = useState({email:'', celular:'', nombres:'', apellidoP:'', apellidoM:'', usuario:'', password:'', genero:'', departamento:'',distrito:'' }); 
const [emailDisp, setEmailDisp] = useState("libre");  
const [emailValido, setEmailValido] = useState("");  
const [celularDisp, setCelularDisp] = useState("libre");  
const [celularValido, setCelularValido] = useState("");  
const [usuarioDisp, setUsuarioDisp] = useState("libre");  
const [password2, setPassword2] = useState(""); 
const [pwdValido, setPwdValido] = useState(""); 
const [pwdtipo, setPwdtipo] = useState("password");  
const [pwdComparacion, setPwdComparacion] = useState("");  
const [editarUbica, setEditarUbica] = useState(""); 
const [ubicacionesf, setUbicacionesf] = useState([{label: "", value: ""}]); 
const [selectedCountry, setSelectedCountry] = useState(''); 
const [provincia, setProvincia] = useState('');
const [campos, setCampos] = useState('ok');
const [botonRegistro, setBotonRegistro] = useState('disabled');
const [botonSiguiente, setBotonSiguiente] = useState('disabled');
const ac=document.getElementsByClassName("p-inputtext p-component p-autocomplete-input")[0];
const generos = [{ label: 'Masculino', value: 'M' }, { label: 'Femenino',  value: 'F' }];

useEffect(() => {
dispatch({type:types.ubicacion, payload:[]});
document.getElementById('inemail').type="email";
document.getElementById('inpwd').type="password";
document.getElementById('inpwd2').type="password";
document.getElementById('incel').type="number";
//eslint-disable-next-line
}, []);


const onUsuarioChange = (e) => {
	disponibilidadUsuario(e.target.value);
	setUser({...user, usuario:e.target.value});		
	habilitarS();
    }
	
const onCelularChange = (e) => {
	disponibilidadCelular(e.target.value);   
	setUser({...user, celular:e.target.value});	
	habilitarS();
    }
	
	
const validarCelular = (valor) => {
	let validez = '';
  if (valor.length >8 ||valor===''){
   setCelularValido(""); validez = '';
  } else {
   setCelularValido('invalido'); validez = 'invalido';
  }
  return validez;
}
	
const onEmailChange = (e) => {
	disponibilidadEmail(e.target.value);  
	setUser({...user, email:e.target.value});
	habilitarS();
    }
	
	
const validarEmail = (valor) => {
	let validez = '';
  if (testMail.test(valor)||valor===''){
   setEmailValido(""); validez = '';
  } else {
   setEmailValido('invalido'); validez = 'invalido';
  }
  return validez;
}

const validarPwd = (valor) => {
	let validez = '';
  if (valor.length >=5 ||valor===''){
   setPwdValido(""); validez = '';
  } else {
   setPwdValido('invalido'); validez = 'invalido';
  }
  return validez;
}
	
const verPwd = (e) => {
	if(pwdtipo ==='password') {document.getElementById('inpwd').type="text"; document.getElementById('inpwd2').type="text"; setPwdtipo('text');} 
	else {document.getElementById('inpwd').type="password"; document.getElementById('inpwd2').type="password"; setPwdtipo('password'); }
    }

const pwdClass = classNames({
        'pi pi-eye': pwdtipo === 'password',
        'pi pi-eye-slash': pwdtipo === 'text',
     });	
	
const disponibilidadUsuario = async (user) => {	
	let resource = await InfoData('disponibilidadUsr', {usuario:user,celular:'',email:''});	
	if(resource.data.disponibilidad==='ocupado'){
	document.getElementById('inuser').style.backgroundColor = '#fff2ef';
	}else{
	document.getElementById('inuser').style.backgroundColor = '#FFFFFF';
	}
	setUsuarioDisp(resource.data.disponibilidad || '');
	return resource.data.disponibilidad;
	}
	
const disponibilidadEmail = async (user) => {	
	let resource = await InfoData('disponibilidadUsr', {usuario:'',celular:'',email:user});	
	if(resource.data.disponibilidad==='ocupado'){
	document.getElementById('inemail').style.backgroundColor = '#fff2ef';
	}else{
	document.getElementById('inemail').style.backgroundColor = '#FFFFFF';
	}
	setEmailDisp(resource.data.disponibilidad || '');
	return resource.data.disponibilidad;
	}
	
const disponibilidadCelular = async (user) => {	
	let resource = await InfoData('disponibilidadUsr', {usuario:'',celular:user,email:''});	
	if(resource.data.disponibilidad==='ocupado'){
	document.getElementById('incel').style.backgroundColor = '#fff2ef';
	}else{
	document.getElementById('incel').style.backgroundColor = '#FFFFFF';
	}
	setCelularDisp(resource.data.disponibilidad || '');
	return resource.data.disponibilidad;
	}

	
const onCountryChange = (e) => {
		obtenerciudades(e.value);
        setSelectedCountry(e.value);
    }
 
  
const onCiudadChange = (e) => {
if(selectedCountry==='89'){obtenerProvincias(e.value);}else{obtenerDistritos('',e.value);}
		setUser({...user, departamento:e.value});		
		document.getElementsByClassName("p-dropdown-label p-inputtext p-placeholder")[0].style.background='#ffffff';
		setProvincia('');
    }
	
	
const obtenerciudades = async (pais) => {
        let db = await InfoData(types.ciudades, pais);
        dispatch({type:types.ciudades, payload: db.ciudades}); 
}
  
  
const onProvinciaChange = (e) => {
		obtenerDistritos(e.value,'');
        setProvincia(e.value);
		setUser({...user, distrito:''});		
    }
	
	
const obtenerProvincias = async (dpto) => {
    let db = await InfoData(types.provincias, dpto);
    dispatch({type:types.provincias, payload: db.provincias}); 
}
  
  
const onDistritoChange = (e) => {
		setUser({...user, distrito:e.value});		
		document.getElementsByClassName("p-dropdown-label p-inputtext p-placeholder")[2].style.background='#ffffff';
		obtenerUbicacion(e.value);
    }
	
	
const obtenerDistritos = async (prov,dpto) => {
    let db = await InfoData(types.distritos, {prov:prov,dpto:dpto});
    dispatch({type:types.distritos, payload: db.distritos});  
}
	
	
const obtenerUbicacion = async (distrito_ID) => {
	let resource = await InfoData('ubicacion', {nombre:'', distrito_ID:distrito_ID});	
	dispatch({type:types.ubicacion, payload:resource.data[0]});
}
	
	
const ubicacionChange = (e) => {
	dispatch({type:types.ubicacion, payload:e.value});
	let country = e.value.Pais_ID;
	let depart = e.value.Departamento_ID;	
	let province = e.value.Provincia_ID;
	let distrit = e.value.Distrito_ID;
	if(typeof province!=='undefined')  setProvincia(province);
	if(typeof distrit!=='undefined' ) { 
	setUser({...user, distrito:distrit}); 
	document.getElementsByClassName("p-dropdown-label p-inputtext p-placeholder")[2].style.background='#ffffff'; }
	if(typeof depart!=='undefined'){
	setUser({...user, departamento:depart, distrito:distrit}); 
	document.getElementsByClassName("p-dropdown-label p-inputtext p-placeholder")[0].style.background='#ffffff'; }
	if(typeof country!=='undefined' && selectedCountry==='') {
	setSelectedCountry(country);
	obtenerciudades(country);
	if(country==='89'){obtenerProvincias(depart);obtenerDistritos(province,'')}else{obtenerDistritos('',depart)};
	document.getElementsByClassName("p-inputtext p-component p-autocomplete-input")[0].style.background='#ffffff';}
	
	if(distrit===''){setEditarUbica('editar');
	document.getElementsByClassName("p-dropdown-label p-inputtext p-placeholder")[2].style.background='#fff2ef';
	}
    }	

	
const filtroubicaciones = async (event) => {    
  var resource = [];
  var ubica = [];
   if(event.query.length >= 1){
   resource = await InfoData('ubicacion', {nombre:event.query.toLowerCase(), distrito_ID:''});   
   ubica = resource.data; }; 
    setTimeout(() => {
    var results = ubica.filter((ubicacion) => {
    return ubicacion.label.toLowerCase();
    });
    setUbicacionesf(results);
        }, 350);
    }


const selectedCountryTemplate = (option, props) => {
    if (option) {
        return (
            <div className="country-item country-item-value">
                <img alt={option.label} src={ruta+img.flag} onError={e => e.target.src = ruta+img.flagEr} className={`flag flag-${option.code.toLowerCase()}`} />
                <div style={{flex: 'none'}}>{window.innerWidth>'800'?option.label:option.code.toUpperCase()}</div>
            </div>
        );
    }

    return (
        <span>
            {props.placeholder}
        </span>
    );
  }
  

const countryOptionTemplate = (option) => {
    return (
        <div className="country-item">
            <img alt={option.label} src={ruta+img.flag} onError={(e) => e.target.src=''} className={`flag flag-${option.code?.toLowerCase()}`} />
            <div style={{flex: 'none'}}>{option.label}</div>
        </div>
    );
  }  
  

const acEstado = (e) => {
if(user.departamento===''){
		ac.style.background='#fff2ef';
    dispatch({type:types.ubicacion, payload:''});
		console.log(ac);
    } else {ac.style.background='#ffffff';habilitarG();}
	}
	
const nombreEstado = (e) => {
if(user.nombres===''){
		document.getElementById('innombres').style.background='#fff2ef';
    } else {document.getElementById('innombres').style.background='#ffffff';habilitarG();}
	habilitarS();
	}
	
const apellidoEstado = (e) => {
if(user.apellidoP===''){
		document.getElementById('apelliPat').style.background='#fff2ef';
    } else {document.getElementById('apelliPat').style.background='#ffffff';habilitarG();}
	habilitarS();
	}
	
const emailEstado = (e) => {
let validez= validarEmail(user.email); 
if(emailDisp==='libre'){
			if((user.email===''||validez==='invalido' )&& user.usuario ==='' && user.celular ===''){
			document.getElementById('inemail').style.background='#fff2ef';
			} else {
			if(celularDisp==='libre')document.getElementById('incel').style.background='#ffffff';
			if(usuarioDisp==='libre')document.getElementById('inuser').style.background='#ffffff';
			document.getElementById('inemail').style.background='#ffffff';habilitarG();}
		}
	habilitarS();
	}

const movilEstado = (e) => {
let validez= validarCelular(user.celular); 
if(celularDisp==='libre'){
			if(user.email==='' && user.usuario ==='' && (user.celular ===''||validez==='invalido')){
			document.getElementById('incel').style.background='#fff2ef';
			} else {
			document.getElementById('incel').style.background='#ffffff';habilitarG();
			if(usuarioDisp==='libre')document.getElementById('inuser').style.background='#ffffff';
			if(emailValido==='')if(emailDisp==='libre')document.getElementById('inemail').style.background='#ffffff';}
		}
	habilitarS();
	}
	
const userEstado = (e) => {
if(usuarioDisp==='libre'){
			if(user.email==='' && user.usuario ==='' && user.celular ===''){
			document.getElementById('inuser').style.background='#fff2ef';
			} else {
			if(celularDisp==='libre')document.getElementById('incel').style.background='#ffffff';
			document.getElementById('inuser').style.background='#ffffff';habilitarG();
			if(emailValido==='')if(emailDisp==='libre')document.getElementById('inemail').style.background='#ffffff';}
		}
	habilitarS();
	}
	
const pwdEstado = (e) => {
let validez= validarPwd(user.password); 
if(user.password===''||validez==='invalido'){
		document.getElementById('inpwd').style.background='#fff2ef';
    } else {document.getElementById('inpwd').style.background='#ffffff'; habilitarG();}
	
	
	if(user.password !==password2) 
	{
		document.getElementById('inpwd').style.backgroundColor = '#fff2ef';
		document.getElementById('inpwd2').style.backgroundColor = '#fff2ef';
	} 
	else 
	{
		document.getElementById('inpwd').style.backgroundColor = '#FFFFFF';
		document.getElementById('inpwd2').style.backgroundColor = '#FFFFFF';
	}	
	habilitarS(password2);
	}	

const paisEstado = (e) => {
if(selectedCountry===''){
		document.getElementsByClassName("p-dropdown-label p-inputtext")[0].style.background='#fff2ef';
    } else {document.getElementsByClassName("p-dropdown-label p-inputtext")[0].style.background='#ffffff';habilitarG();}
	}	
	
const dptoEstado = (e) => {
if(user.departamento===''){
		document.getElementsByClassName("p-dropdown-label p-inputtext")[1].style.background='#fff2ef';
    } else {document.getElementsByClassName("p-dropdown-label p-inputtext")[1].style.background='#ffffff';habilitarG();}
	}
	
const proviEstado = (e) => {
if(provincia===''){
		document.getElementsByClassName("p-dropdown-label p-inputtext")[2].style.background='#ffffff';
    } else {document.getElementsByClassName("p-dropdown-label p-inputtext")[2].style.background='#ffffff';habilitarG();}
	}	
 
const distriEstado = (e) => {
if(user.distrito===''){
		document.getElementsByClassName("p-dropdown-label p-inputtext")[3].style.background='#ffffff';
    } else {document.getElementsByClassName("p-dropdown-label p-inputtext")[3].style.background='#ffffff';habilitarG();}
	}	 
 
	
const habilitarG = (e) => {
	if([user.nombres,user.apellidoP,user.email||user.celular||user.usuario,user.password,password2,user.distrito].includes(''))
	{	setBotonRegistro('disabled');}else{
	setBotonRegistro('');}
	habilitarS();
    }
	
	
const habilitarS = (e) => {
	if([user.nombres,user.apellidoP,user.email||user.celular||user.usuario,user.password,password2].includes(''))
	{	setBotonSiguiente('disabled');}else{
	setBotonSiguiente('');}

	if(typeof e!=='undefined'){if(user.password !==e){setBotonSiguiente('disabled');}}
	
	if(celularDisp==='ocupado'){setBotonSiguiente('disabled');}
	if(emailDisp==='ocupado')  {setBotonSiguiente('disabled');}
	if(usuarioDisp==='ocupado'){setBotonSiguiente('disabled');}
    }

	
const siguiente = (e) => {
	document.getElementsByClassName("form-container")[0].classList.add('oculto');
	document.getElementsByClassName("form-container")[1].classList.remove('oculto');
    }	
	
	
const atras = (e) => {
	document.getElementsByClassName("form-container")[0].classList.remove('oculto');
	document.getElementsByClassName("form-container")[1].classList.add('oculto');
    }		
	
const validacion = async (e) => {	
	let validacionDatos='';
	let usuarioVal = await disponibilidadUsuario(user.usuario);   
	let emailVal = await disponibilidadEmail(user.email);   
	let celularVal = await disponibilidadCelular(user.celular);  
	let usuarioLibre = '';
	let camposObligatorios = ''; 
	let pwdConfirmado = ''; 
		
	if(user.password !==password2) 
	{
		document.getElementById('inpwd').style.backgroundColor = '#fff2ef';
		document.getElementById('inpwd2').style.backgroundColor = '#fff2ef';
		setPwdComparacion('falla');
		pwdConfirmado ='';
	} 
	else 
	{
		document.getElementById('inpwd').style.backgroundColor = '#FFFFFF';
		document.getElementById('inpwd2').style.backgroundColor = '#FFFFFF';
		setPwdComparacion('');
		pwdConfirmado ='ok';
	}

	if([user.nombres,user.apellidoP,user.email||user.celular||user.usuario,user.password,selectedCountry,user.departamento,user.distrito].includes(''))
	{	
		setCampos('')
		camposObligatorios = ''; 
//		alert('Rellene los campos obligatorios');  
		nombreEstado();
		apellidoEstado();
		emailEstado();
		movilEstado();
		userEstado();
		pwdEstado();
		paisEstado();
		dptoEstado();
		proviEstado();
		distriEstado();
		acEstado();
	}
	else
	{
		setCampos('ok'); 
		camposObligatorios = 'ok';
	}
	if([usuarioVal,emailVal,celularVal].includes('ocupado'))
	{
		setCampos('')
		usuarioLibre = ''; 
//		alert('El usuario ya esta registrado'); 
	}
	else
	{
		setCampos('ok'); 
		usuarioLibre = 'ok';
	}
		
	if([pwdConfirmado,camposObligatorios,usuarioLibre].includes(''))
	{
		let a=''; if(usuarioVal==='ocupado')a='o';
		let b=''; if(emailVal==='ocupado')b='o';
		let c=''; if(celularVal==='ocupado')c='o';
		alert('Este '+(a?'usuario':'')+((a&&b)||(a&&c)||(b&&c)?', ':'')+(b?'Correo electrónico':'')+(b&&c?', ':'')+(c?'teléfono móvil':'')+((a&&b)||(a&&c)||(b&&c)?' ya se encuentran registrados.':' ya se encuentra registrado.'));

	}else{
	validacionDatos='ok';
	}
	return validacionDatos;
}
	
const registrar = async (e) => {	
	let datosValidos = await validacion();
	if (datosValidos==='ok'){
		let resource = await InfoData('registrar', user);		
		console.log(resource.data.last_id);
		console.log(resource.data);	
		if(resource.data.last_id!=='')
		{
		alert(resource.data.Nombres+' '+resource.data.ApellidoPaterno+' '+resource.data.ApellidoMaterno+' fue registrado satisfactoriamente')
		return	document.getElementById("botono").click();
		}
		
	}	
}


  return (
    <div className="p-fluid dashboard">
    
      <div className="marco reg" style={{padding:'0.4em'}}>
          
        <div className="reg-content-wrapper single reg-box">
          <div className="join-form-container form-container">
            <div className="join-form-wrapper form-wrapper" >
              <h1 style={{'borderBotton':'1px solid #bbb', textAlign:'center'}}>Regístrate</h1>
              <div className="alerts-cont">
              </div> 
              <div className="loading-indicator hidden">
              </div>  
              <fieldset> 
                <legend>Únete a Supercontacto</legend> 
                <ul className="input-fields container-box"  style={{listStyle:'none',paddingInlineStart:'0px'}} >							
                  <li className="cell-input">
                    <div className="cell-body"><label className="float-label" htmlFor="innombres">Nombres *</label>  
                    <InputText id="innombres"  value={user.nombres} onChange={(e) => setUser({...user, nombres:e.target.value})} onBlur={() => nombreEstado()}/></div>
                  </li>
                  <li className="cell-input">
                    <div className="cell-body" style={{width:'48%',float:'left'}}><label className="float-label" htmlFor="apelliPat">Apellido paterno *</label>
                    <InputText id="apelliPat"  value={user.apellidoP} onChange={(e) => setUser({...user, apellidoP:e.target.value}) } onBlur={() => apellidoEstado()}/></div>
    
                    <div className="cell-body" style={{width:'48%',float:'right'}}><label className="float-label" htmlFor="apelliMat">Apellido materno</label>
                    <InputText id="apelliMat"  value={user.apellidoM} onChange={(e) => setUser({...user, apellidoM:e.target.value}) }/> 
                    </div>
                  </li>
                  <li className="cell-input">
                    <div className="cell-body"> <label className="float-label" htmlFor="inemail">{(user.email&&!user.email)||(user.celular||user.usuario)?'Correo electrónico':'Correo electrónico *'}</label>
                    <InputText id="inemail" value={user.email} onChange={onEmailChange} aria-describedby="inemail-help"  onBlur={() => emailEstado()}/><small id="inemail-help" className="p-error p-d-block">{ (emailDisp === 'ocupado' ?  user.email+' ya está registrado.':'')+(emailValido ?  user.email+' no es un correo electrónico válido':'')}</small></div>
                  </li>
                  <li className="cell-input">
                    <div className="cell-body" style={{width:'48%',float:'left'}}><label className="float-label" htmlFor="incel">{(user.celular&&!user.celular)||(user.email||user.usuario)?'Teléfono móvil':'Teléfono móvil *'}</label>
                    <InputText id="incel" value={user.celular} onChange={onCelularChange} aria-describedby="incel-help"  onBlur={() => movilEstado()}/><small id="incel-help" className="p-error p-d-block">{ (celularDisp === 'ocupado' ?  user.celular+' ya está registrado.':'')+(celularValido ?  'Este número no es válido':'')}</small></div>
     
                    <div className="cell-body" style={{width:'48%',float:'right'}}><label className="float-label" htmlFor="inuser">{(user.usuario&&!user.usuario)||(user.celular||user.email)?'Nombre de usuario':'Nombre de usuario *'}</label>
                    <InputText id="inuser" value={user.usuario} onChange={onUsuarioChange} aria-describedby="inuser-help"  onBlur={() => userEstado()}/><small id="inuser-help" className="p-error p-d-block">{ usuarioDisp === 'ocupado' ?  user.usuario+' ya está registrado.':''}</small></div>
                  </li>
                  <li className="cell-input">
                    <div className="cell-body"><label className="float-label" htmlFor="inpwd">Contraseña *</label>
                    <span className="p-input-icon-right"><i className={pwdClass} style={{right: '0.5em',cursor:'pointer',top:'0.8em', fontSize:'1.5em'}} onClick={() => verPwd()}/>
                    <InputText id="inpwd" value={user.password} onChange={(e) => setUser({...user, password:e.target.value}) } aria-describedby="inpwd-help"/></span><small id="inpwd-help" className="p-error p-d-block">{ pwdValido ?  'La contraseña debe tener al menos 5 digitos':''}</small></div>
                  </li>
                  <li className="cell-input" style={{'display': user.password ? 'inline-block':'none'}}>
                    <div className="cell-body"><label className="float-label" htmlFor="inpwd2">Confirmación de contraseña</label>
                    <InputText id="inpwd2" value={password2} onChange={(e) => {setPassword2(e.target.value); setPwdComparacion('');}} aria-describedby="inpwd2-help" onBlur={() => pwdEstado()} className={campos||password2 !=='' ? 'p-d-block':'p-invalid p-d-block'}/><small id="inpwd2-help" className="p-error p-d-block">{ pwdComparacion === 'falla' ?  'La contraseña no coincide con la confirmación.':''}</small></div>
                  </li>
                </ul>
                <p className="signin-link" style={{marginBottom:'0'}}>(*) Campos obligatorios.</p>
                <Button className="logboton join-btn" label="Siguiente" onClick={() => siguiente()} disabled={botonSiguiente}/>
                </fieldset>
                <p className="signin-link movil"> Al crear la cuenta de Supercontacto, estoy de acuerdo con las <Link to= "/legal/user-agreement">Condiciones de uso</Link>, la <Link to="/legal/privacy-policy">Política de privacidad</Link>  y la <Link to= "/legal/cookies-policy">Política de cookies</Link> de Supercontacto.</p>
              
            </div>
          </div>
          
          
          <div className="join-form-container form-container oculto">
            <div className="join-form-wrapper form-wrapper" >
              <h1 style={{'borderBotton':'1px solid #bbb', padding:'0.4em', textAlign:'center'}}>Regístrate</h1>
              <div className="alerts-cont">
              </div> 
              <div className="loading-indicator hidden">
              </div>  
              <fieldset> 
                <legend>Únete a Supercontacto</legend>
                <ul className="input-fields container-box"  style={{listStyle:'none',paddingInlineStart:'0px', marginBlockStart:'0'}} >							
                  <li className="cell-input" style={{'display': editarUbica ? 'inline-block':'none'}}>
                    <div className="cell-body"><label className="float-label" htmlFor="inpais">País *</label>
                    <Dropdown value={selectedCountry} options={paises} onChange={onCountryChange} placeholder="Pais" onBlur={() => paisEstado()}
                    valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} id="inpais" /></div>
                  </li>
                  <li className="cell-input" style={{'display': editarUbica ? 'inline-block':'none'}}>
                    <div className="cell-body"><label className="float-label" htmlFor="indepa">Departamento *</label> 
                    <Dropdown value={user.departamento} options={ciudades} onChange={onCiudadChange} placeholder="Departamento" id="indepa" onBlur={() => dptoEstado()}/></div>
                  </li>
                  <li className="cell-input" style={{'display':(editarUbica!=='')&&(selectedCountry==='89')?'inline-block':'none'}}>
                    <div className="cell-body"><label className="float-label" htmlFor="inprov">Provincia *</label>
                    <Dropdown value={provincia} options={provincias} onChange={onProvinciaChange} placeholder="Provincia" id="inprov" onBlur={() => proviEstado()}/></div>
                  </li>
                  <li className="cell-input" style={{'display': editarUbica ? 'inline-block':'none'}}>
                    <div className="cell-body"><label className="float-label" htmlFor="indistrit">Distrito *<i className='pi pi-search' style={{color: '#ff0000', float :'right', cursor:'pointer'}} onClick={() => setEditarUbica('')} tooltip="Ingresar ubicación manualmente"/>
                    <span style={{fontWeight:'normal', float:'right', marginRight:'4px'}}>(búsqueda automática)</span></label>
                    <Dropdown value={user.distrito} options={distritos} onChange={onDistritoChange} placeholder="Distrito" id="indistrit" onBlur={() => distriEstado()}/></div>
                  </li>			
                  <li className="cell-input" style={{'display': editarUbica ? 'none':'inline-block'}}>
                    <div className="cell-body"><label className="float-label" htmlFor="inubicacion">Ubicación *<i className='pi pi-pencil' style={{color: '#ff0000', fontSize: '12px', float :'right', cursor:'pointer'}} onClick={() => setEditarUbica('editar')} tooltip="Ingresar ubicación manualmente"/>
                    <span style={{fontWeight:'normal', float:'right', marginRight:'4px'}}>(búsqueda manual)</span></label>
                    <AutoComplete id="inubicacion" value={ubicacion} suggestions={ubicacionesf} completeMethod={filtroubicaciones} field="label" size={30} placeholder="Escribe el nombre de tu distrito" minLength={1} onChange={ubicacionChange}  onBlur={() => acEstado()} style={{'background':campos||user.departamento!==''?'#ffffff':'#fff2ef'}}/></div>
                    <small id="inubicacion-help" className="p-d-block">{ selectedCountry ?  'Busqueda de distritos en '+paises.find(item => item.value === selectedCountry).label :'Busqueda de distritos en todo el mundo'}</small>
                  </li>
                  <li className="cell-input">
                    <div className="cell-body"><label className="float-label" htmlFor="ingenero">Género</label>
                    <Dropdown value={user.genero} options={generos} onChange={(e) => setUser({...user, genero:e.value}) } placeholder="Género" /></div>
                  </li>
                </ul>
                <p className="signin-link">(*) Campos obligatorios.</p>
                <Button className="logboton join-btn" label="Atrás" onClick={() => atras()} style={{width:'48%', float:'left', clear:'both', background:'#2196f3'}}/>
                <Button className="logboton join-btn" label="Registrar" onClick={() => registrar()} disabled={botonRegistro} style={{width:'48%',float:'right'}}/>
                

                </fieldset>
                <p className="signin-link"> Al crear la cuenta de Supercontacto, estoy de acuerdo con las <Link to= "/legal/user-agreement">Condiciones de uso</Link>, la <Link to="/legal/privacy-policy">Política de privacidad</Link>  y la <Link to= "/legal/cookies-policy">Política de cookies</Link> de Supercontacto.</p>
				<Link to="/usuario/login"><span id='botono' className="oculto" >home</span></Link>
				</div>
          </div>			
    
        </div>	
    
      </div>    
    </div>    
    );
		
  }