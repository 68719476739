import React, { /*useContext,*/ useEffect } from 'react';
//import {StoreContext} from './store/StoreProvider.js';
import { Galleria } from "primereact/galleria";
import portada3 from './assets/images/portada-3-990-400.png';
import portada4 from './assets/images/portada-4-990-400.png';
import portada5 from './assets/images/portada-5-990-400.png';


export const Home = (props) => {
//  const [store] = useContext(StoreContext);
//  const {ruta, img } = store;

const images = [
  { "itemImageSrc": portada3, "alt":"Descripción de imagen 1", "width":"100%" },
  { "itemImageSrc": portada4, "alt":"Descripción de imagen 2", "width":"100%" },
  { "itemImageSrc": portada5, "alt":"Descripción de imagen 3", "width":"100%" },	
];
const responsiveOptions = [
  { breakpoint: "1024px", numVisible: 5 },
  { breakpoint: "768px",  numVisible: 3 },
  { breakpoint: "560px",  numVisible: 1 },
];

useEffect(() => {
  document.getElementById('superMenu').style.display = 'block';
}, []);

const itemTemplate = (item) => {
  return React.createElement("img", {
    src: item.itemImageSrc,
    alt: item.alt,
    style: { display: "block" },
  });
};

const thumbnailTemplate = (item) => {
  return React.createElement("img", {
    src: item.thumbnailImageSrc,
    alt: item.alt,
    style: { display: "block" },
  });
};

return (
<div className="home">
  <Galleria id="galeria" className="movil banner-image" style={{position:'relative', bottom:'-80px'}} value={images} responsiveOptions={responsiveOptions} numVisible={5}  
  showItemNavigators showThumbnails={false} showItemNavigatorsOnHover showIndicators item={itemTemplate} thumbnail={thumbnailTemplate} circular autoPlay transitionInterval={3000} />
</div>    
);
}