import axios from "axios";
const host="https://servicontacto.com/";
const api = host+"supercontacto/portal.php";
const apiLogin = host+"supercontacto/login.php";
const config = { headers: {'content-type': 'multipart/form-data'}};
const formData = new FormData();

export const InfoData = (type,payload) => {


const serverPaises = async () => { 
    formData.append('operacion', 2);
    return await axios.post(api, formData, config);
  } 

  
const serverCiudades = async (pais) => { 
    formData.append('operacion', 1);
    formData.append('pais', pais);  
    return await axios.post(api, formData, config);
  } 


const serverProvincias = async (dpto) => { 
    formData.append('operacion', 1.1);
    formData.append('departamento', dpto);  
    return await axios.post(api, formData, config);
  } 
  

const serverDistritos = async (prov,dpto) => { 
    formData.append('operacion', 1.2);
    formData.append('provincia', prov);  
	formData.append('departamento', dpto);  
    return await axios.post(api, formData, config);
  } 


const serverUbicacionAuto = async (nombre,distrito_ID) => { 
	formData.append('operacion', 8.2);
	formData.append('nombre', nombre);
	formData.append('distrito', distrito_ID);
    return await axios.post(api, formData, config);
  }  

  
const servercategoria = async (accion) => {
    formData.append("operacion", 3);
    formData.append("accion", accion);
    return await axios.post(api, formData, config);
	}/*viene de supermenu*/

	
const serverCategorias = async (padre) => {
    formData.append('operacion', 10);
	formData.append('padre', padre);
    return await axios.post(api, formData, config);
	}/*Selector de categoría en árbol*/
  

const serverCategoriasAutoCto = async (nombre,catID) => {  
	formData.append('operacion', 10.1);
	formData.append('nombre', nombre);
	formData.append('catID', catID);
    return await axios.post(api, formData, config);
	} 
  
const serverCategoriasAuto = async (nombre,catID) => {  
	formData.append('operacion', 10.2);
	formData.append('nombre', nombre);
	formData.append('catID', catID);
    return await axios.post(api, formData, config);
	} 


const serverSociosAuto = async (nombre) => {	
	formData.append('operacion', 10.3);
	formData.append('nombre', nombre);
    return await axios.post(api, formData, config);
	}

	
const serverInsertaSocio = async (Contacto_ID,catID,socio,modo) => {
    formData.append('operacion', 10.4);
    formData.append('Contacto_ID', Contacto_ID );  
	formData.append('catID', catID);  
	formData.append('id', socio);  
	formData.append('accion', modo);  
    return await axios.post(api, formData, config);
	} 	


const UsuarioData = async (usu_ID) => {
	let user = {Usu_ID:usu_ID, Nombres:'', ApellidoPaterno:'', ApellidoMaterno:''};
    let resource = await serverUsuario(user,'','');
	let perfil=resource.data;
	let ubicacion={
	Departamento: perfil.Departamento,
	Departamento_ID: perfil.Departamento_ID,
	Distrito: perfil.Distrito,
	Distrito_ID: perfil.Distrito_ID,
	Pais: perfil.Pais,
	Pais_ID: perfil.Pais_ID,
	Provincia: perfil.Provincia,
	Provincia_ID: perfil.Provincia_ID,
	label:perfil.Usu_ID?perfil.Distrito+', '+perfil.Provincia+' - '+perfil.Departamento+' - '+perfil.Pais:'',
	value:perfil.Distrito}
	return {perfil,ubicacion};
  }


const registrarServer = async (user) => {		
	formData.append('operacion', 9);
	formData.append('nombre', user.nombres.trim());
	formData.append('apellidoP', user.apellidoP.trim());
	formData.append('apellidoM', user.apellidoM.trim());
	formData.append('Email', user.email.trim());
	formData.append('Celular', user.celular);
	formData.append('usuario', user.usuario.trim());
	formData.append('password', user.password);         
	formData.append('distrito', user.distrito);     
	formData.append('departamento', user.departamento || '0');
	formData.append('genero', user.genero || '0');
	formData.append('nombreDB', user.nombres+" "+user.apellidoP+" "+user.apellidoM);
    return await axios.post(api, formData, config);
  }
  

const serverUsuario = async (user, caso, pwd) => { 
	formData.append('operacion', 9.1);
	formData.append('Usu_ID', user.Usu_ID);
	formData.append("tipo", caso);
    formData.append("nombre", user.Nombres?.trim());
    formData.append("apellidoP", user.ApellidoPaterno?.trim());
    formData.append("apellidoM", user.ApellidoMaterno?.trim());
    formData.append("Email", user.Email?.trim() || '');
    formData.append("Celular", user.Celular || '');
    formData.append("usuario", user.Usuario?.trim() || '');
    formData.append("password", pwd);
    formData.append("distrito", user.Distrito_ID || '');
    formData.append("genero", user.Genero || '');
    formData.append("nombreDB", user.Nombres + " " + user.ApellidoPaterno + " " + user.ApellidoMaterno);  
	return await axios.post(api, formData, config);
} 

 
const obtenerEventos = async (Usu_ID) => {
	let resource = await serverEventosUsr(Usu_ID);
	let salida = resource.data.length===0?'':resource.data;
return salida
}	

	
const serverEventosUsr = async (Usu_ID) => { 
	formData.append('operacion', 15.1);
	formData.append('Usu_ID', Usu_ID);
    return await axios.post(api, formData, config);
  }
  
  
const serverEventos = async (Contacto_ID) => { 
	formData.append('operacion', 15.2);
	formData.append('Contacto_ID', Contacto_ID);
    return await axios.post(api, formData, config);
  }  

  
const serverlistaContactos = async (Cat_ID,padre,abuelo,departamento,pais,provincia,distrito,skip,limit) => {
	formData.append('operacion', 4);
	formData.append('skip', skip);  
	formData.append('limit', limit);    
	formData.append('catID', Cat_ID);
	formData.append('padre', padre);
	formData.append('abuelo', abuelo);
	formData.append('distrito', distrito);
	formData.append('provincia', provincia);  
	formData.append('departamento', departamento);
	formData.append('pais', pais);
	return await axios.post(api, formData, config);
  }   

  
const serverCtoData = async (userweb) => {
    formData.append('operacion', 5);
    formData.append('userweb', userweb);
    return await axios.post(api, formData, config);
    } 


const serverContactosUser = async (Usu_ID) => { 
	formData.append('operacion', 5.1);
	formData.append('Usu_ID', Usu_ID);
	return await axios.post(api, formData, config);
}
  
  
const serverEditor = async (Contacto_ID, Usu_ID, accion) => { 
	formData.append('operacion', 5.7);
	formData.append('Contacto_ID', Contacto_ID);
	formData.append('Usu_ID', Usu_ID);
	formData.append('accion', accion);
	return await axios.post(api, formData, config);
}


const serverPersonasAuto = async (nombre,Usu_ID) => { 
	formData.append('operacion', 5.8);
	formData.append('nombre', nombre);
	formData.append('Usu_ID', Usu_ID);
    return await axios.post(api, formData, config);
  }  
  
const registrarCtoServer = async (contacto, usuario, catID ) => {		
	formData.append('operacion', 12);
	formData.append('nombre', contacto.Nombre_Comercial.trim());
	formData.append('distrito', contacto.distrito);  
	formData.append('direccion', contacto.direccion.trim());
	formData.append('Celular', contacto.celular.trim());
	formData.append('telefono', contacto.telefono.trim());
	formData.append('tipo', contacto.tipo); //'' - 1 - 2
	formData.append('Usu_ID',usuario);
	formData.append('color',contacto.color);
	formData.append('catID', catID);
    return await axios.post(api, formData, config);
	}   
  
  
  const guardarCtoServer = async (contacto, usuario, textoWeb) => {	
	formData.append('operacion', 14);
	formData.append('Contacto_ID', contacto.Contacto_ID);	
	formData.append('nombre', contacto.Nombre_Comercial.trim());
	formData.append('distrito', contacto.distrito);  
	formData.append('direccion', contacto.direccion.trim());
	formData.append('Celular', contacto.celular.trim());
	formData.append('telefono', contacto.telefono.trim());
	formData.append('tipo', contacto.tipo); //'' - 1 - 2
	formData.append('Usu_ID',usuario.Usu_ID);
	formData.append('color',contacto.color);
	formData.append('web', contacto.web.trim());
	formData.append('userweb', contacto.userweb?.trim() || '');
	formData.append('usoweb', contacto.usoweb);
	formData.append('Email', contacto.email.trim());
	formData.append('gmaps', contacto.Gmaps.trim());    
	formData.append('presentacion', textoWeb?textoWeb:contacto.Presentacion);   
	formData.append('fb', contacto.Facebook.trim());     
	formData.append('Instagram', contacto.Instagram);     
	formData.append('Youtube', contacto.Youtube);     
	formData.append('Twitter', contacto.Twitter);   
	formData.append('Tiktok', contacto.Tiktok);   	

    return await axios.post(api, formData, config);
} 


const borrarCtoServer = async (contacto) => {		
	formData.append('operacion', 12.2);
	formData.append('Contacto_ID', contacto);		
    return await axios.post(api, formData, config);
	} 

    
  const serverEspecialidades = async (Contacto_ID,catID,borrar,texto) => {
	formData.append('operacion', 6);
	formData.append('Contacto_ID', Contacto_ID);
	formData.append('presentacion', texto || '');  
	formData.append('catID', catID || '');
	formData.append('accion', borrar || '');
    return await axios.post(api, formData, config);
    } 

  
const serverEspecialistas = async (Contacto_ID,catID) => {
	formData.append('operacion', 6.1);
	formData.append('Contacto_ID', Contacto_ID);
	formData.append('catID', catID);  
	return await axios.post(api, formData, config);
	}
	
	
const disponibilidadUsrServer = async (usuario, celular, email, pwd, Usu_ID, accion, codigo) => {	
	formData.append('operacion', 7);
	formData.append("password", pwd || '');
	formData.append('usuario', usuario);
	formData.append('Celular', celular);	
	formData.append('Email', email);
    formData.append("Usu_ID", Usu_ID || '');
	formData.append('accion', accion || '');
	formData.append('tipo', codigo || '');	
    return await axios.post(api, formData, config);
	}	/*Disponibilidad en usuarios*/


const disponibilidadServer = async (usuario, celular, email) => {	
	formData.append('operacion', 7.1);
	formData.append('usuario', usuario);
	formData.append('Celular', celular);	
	formData.append('Email', email);	
    return await axios.post(api, formData, config);
	}	/*Disponibilidad en contactos*/
	
	
const serverFotoProcess = async (data,contacto,usu_ID,tipo) => {	
    formData.append('operacion', 11);
	formData.append('doc', data);
    formData.append("Usu_ID", usu_ID || '');	
	formData.append('Contacto_ID', contacto || '');	
	formData.append('tipo', tipo);
	return await axios.post(api, formData, config);
	} /*confirmar y almacenar en base de datos, el documento subido por el componente hijo UploadForm*/
  
	
const serverVisitaWeb = async (contacto, tipo) => {
    formData.append('operacion', 5.2);
    formData.append('tipo', tipo);	
    formData.append('Contacto_ID', contacto);	
    return await axios.post(api, formData, config);
    } 

	
const serverVisitaInfo = async (contacto) => {
    formData.append('operacion', 5.3);
    formData.append('Contacto_ID', contacto);	
    return await axios.post(api, formData, config);
    } 
	
	
const serverVisitaCto = async (contacto, tipo) => {
    formData.append('operacion', 5.4);
    formData.append('tipo', tipo);	
    formData.append('Contacto_ID', contacto);	
    return await axios.post(api, formData, config);
    } 

	
const serverVisita = async (tipo) => { 
	formData.append('operacion', 5.5);
	formData.append('tipo', tipo);
	return await axios.post(api, formData, config);
	} 
 

const serverComentarios = async (contacto) => {
	formData.append('operacion', 16.1);
	formData.append('Contacto_ID', contacto);  
	return await axios.post(api, formData, config);
	} 


const inicioServer = async (tipo, password, checked) => {
    formData.append("operacion", 1);
    formData.append("inT1", tipo.Usuario.trim());
    formData.append("inT1a", tipo.Email.trim());
    formData.append("inT1b", tipo.Celular.trim());
    formData.append("inT2", password);
    formData.append("equipo", checked ? 1 : 0);
    return await axios.post(apiLogin, formData, config);
  };

  

const salir = async (Usu_ID) => {	
		let resource = await salirServer (Usu_ID);
		var ip='192.168.0.1';
		document.cookie = "Usuario="+ip+'@'+ Usu_ID + "@; max-age=0";
		let perfil = resource.data;
	if(perfil === '') {
	localStorage.removeItem('user');
	localStorage.removeItem('ciudad');
	localStorage.removeItem('provincia');
	localStorage.removeItem('distrito'); 
	localStorage.setItem('user',JSON.stringify({login:false}));
	}
}


const salirServer = async (Usu_ID) => { 
	formData.append('operacion', 2);
	formData.append('usuario', Usu_ID);
    return await axios.post(apiLogin, formData, config);
  }
 
 
const obtenerApi = async (type,payload) => {
		let user= '';
		let paises = [];
		let ciudades = [];
		let provincias = [];
		let distritos = [];
		let salida = '';
		let alertas = '';
		let contacto = [];		
		let contactos = [];
		let data = [];

	 	switch(type){
		case 'auth - login':
		user= await UsuarioData(payload);
		paises = [{value:user.perfil.Pais_ID, label:user.perfil.Pais, code:user.perfil.code}];
		ciudades = [{value:user.perfil.Departamento_ID, label:user.perfil.Departamento, id:user.perfil.Departamento_ID}];
		provincias = [{value:user.perfil.Provincia_ID, label:user.perfil.Provincia, id:user.perfil.Provincia_ID}];
		distritos = [{value:user.perfil.Distrito_ID, label:user.perfil.Distrito, id:user.perfil.Distrito_ID}];		
			return {user: user, paises: paises, ciudades:ciudades, provincias:provincias, distritos:distritos}
		case 'user':
		data = await serverUsuario(payload.user, payload.caso, payload.pwd );
			return data
		case 'registrar':
		data = await registrarServer(payload);
			return data
		case 'persona':
		data = await serverPersonasAuto(payload.nombre, payload.Usu_ID );
			return data		
		case 'paises':
		paises = await serverPaises();
			return { paises: paises.data }	
		case 'ciudades': 
		ciudades = await serverCiudades(payload);
			return { ciudades: ciudades.data }			
		case 'provincias':
		provincias = await serverProvincias(payload);
			return { provincias: provincias.data }	
		case 'distritos':
		distritos = await serverDistritos(payload.prov, payload.dpto );
			return { distritos: distritos.data }
		case 'ubicacion':
		data = await serverUbicacionAuto(payload.nombre, payload.distrito_ID );
			return data
		case 'categoria':
		data = await serverCategoriasAuto(payload.nombre, payload.catID );
			return data
		case 'categorias':
		data = await servercategoria(payload.nombre, payload.catID );
			return data
		case 'categoriaCto':
		data = await serverCategoriasAutoCto(payload.nombre, payload.catID );
			return data			
		case 'categoriaSelect':
		data = await serverCategorias(payload);
			return data			
		case 'socio':
		data = await serverSociosAuto(payload);
			return data
		case 'insertaSocio':
		data = await serverInsertaSocio(payload.Contacto_ID,payload.catID,payload.socio,payload.modo);
			return data			
		case 'alertas':
		alertas = await obtenerEventos(payload);
			return { alertas: alertas }
		case 'eventos':
		data = await serverEventos(payload);
			return data;
		case 'editor':
		data = await serverEditor(payload.Contacto_ID, payload.Usu_ID, payload.accion);
			return data;			
		case 'contactosUser':
		contactos = await serverContactosUser(payload);
			return contactos;
		case 'contactos':
		data = await serverlistaContactos(payload.Cat_ID, payload.padre, payload.abuelo, payload.departamento, payload.pais, payload.provincia, payload.distrito, payload.skip, payload.limit);		
			return data;				
 		case 'contacto':
		contacto = await serverCtoData(payload);
			return contacto
		case 'contactoReg':
		data = await registrarCtoServer(payload.contacto, payload.usuario, payload.catID);
			return data
		case 'contactoSave':
		data = await guardarCtoServer(payload.contacto, payload.usuario, payload.textoWeb);
			return data
		case 'contactoDel':
		data = await borrarCtoServer(payload);
			return data			
		case 'especialidad':
		data = await serverEspecialidades(payload.Contacto_ID,payload.catID,payload.borrar,payload.texto);
			return data.data;
		case 'especialista':
		data = await serverEspecialistas(payload.contacto, payload.catID);
			return data.data;
		case 'disponibilidad':
		data = await disponibilidadServer(payload.usuario, payload.celular, payload.email);
			return data;
		case 'disponibilidadUsr':
		data = await disponibilidadUsrServer(payload.usuario, payload.celular, payload.email, payload.pwd, payload.Usu_ID, payload.accion, payload.codigo);
			return data;			
		case 'foto':
		data = await serverFotoProcess(payload.data, payload.contacto, payload.usu_ID, payload.tipo);
			return data;
		case 'leeComentarios':
		data = await serverComentarios(payload);
			return data;			
		case 'zona':
			return {
			zona: payload
			}
		case 'login':
			data = await inicioServer(payload.tipo, payload.password, payload.checked);
			return data		
		case 'auth - logout':
			salida = await salir(payload);
			return { salida: salida }		
		case 'visita':
			salida = await serverVisita(payload);
			return salida.data;
		case 'visitaInfo':
			salida = await serverVisitaInfo(payload);
			return salida.data;			
		case 'visitaCto':
			salida = await serverVisitaCto(payload.contacto, payload.tipo);
			return salida.data;
		case 'visitaWeb':
			salida = await serverVisitaWeb(payload.contacto, payload.tipo);
			return salida.data;			
			default: 
			return '';
		}
}


return obtenerApi(type,payload);
}
