import React, { useContext, useState } from 'react';
import axios from "axios";
import { ProgressBar } from 'primereact/progressbar';
import { StoreContext } from './store/StoreProvider';


export const UploadForm = (props) => {

const [store] = useContext(StoreContext);
const {user, contacto, apiFile} = store;
const [file, setFile] = useState(null);  
const [progress, setProgress] = useState(0);  

console.log(props.uploadCase);

 const onSubmit = async (e) => {
	e.preventDefault();
	let res = await uploadFile(file);  
	if (res.data.status === "success"){
	  switch (props.uploadCase){
		case 'SubirFotoPerfil':
		props.callBackOnSuccessUpload(res.data.url,contacto.Contacto_ID,contacto.tipo);
		break;
	  case 'SubirBanner':
		props.callBackOnSuccessUpload(res.data.url,contacto.Contacto_ID,'5');
        break;
	  case 'SubirBanner1':
		props.callBackOnSuccessUpload(res.data.url,contacto.Contacto_ID,'9');
        break;
	  case 'SubirBanner2':
		props.callBackOnSuccessUpload(res.data.url,contacto.Contacto_ID,'10');
        break;		
	  case 'SubirBanner3':
		props.callBackOnSuccessUpload(res.data.url,contacto.Contacto_ID,'11');
        break;		
	  case 'subirStaff':
		props.callBackOnSuccessUpload(res.data.url,contacto.Contacto_ID, contacto.tipo >=3?'7':'6');
        break;	
	  case 'SubirFotoUsuario':
		props.callBackOnSuccessUpload(res.data.url,user.Usu_ID,'8');
        break;	
    default: 
    console.log("No hay coincidencias");
	  }
  } 

  }
  
  const onChange = (e) => {
	setFile(e.target.files[0]);
	setProgress(0);
  }  
  
  
  const uploadFile = async (file) => { 
    var config = {
      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(progressEvent.loaded * 100 / progressEvent.total);
       // console.log("Progress:-" + percentCompleted);
		setProgress(percentCompleted);
      },
      headers: {'content-type': 'multipart/form-data'}
    };
    const formData = new FormData();

    switch (props.uploadCase) {
      case 'SubirFotoPerfil':
        formData.append('avatar', file);
		formData.append('idp', contacto.Contacto_ID);
        return await axios.post(apiFile, formData, config);
	  case 'SubirBanner':
        formData.append('avatar', file);
		formData.append('idp', contacto.Contacto_ID);
        return await axios.post(apiFile, formData, config);
	  case 'SubirBanner1':
        formData.append('avatar', file);
		formData.append('idp', contacto.Contacto_ID);
        return await axios.post(apiFile, formData, config);
	  case 'SubirBanner2':
        formData.append('avatar', file);
		formData.append('idp', contacto.Contacto_ID);
        return await axios.post(apiFile, formData, config);
	  case 'SubirBanner3':
        formData.append('avatar', file);
		formData.append('idp', contacto.Contacto_ID);
        return await axios.post(apiFile, formData, config);		
	  case 'subirStaff':
        formData.append('avatar', file);
		formData.append('idp', contacto.Contacto_ID);
        return await axios.post(apiFile, formData, config);
	  case 'SubirFotoUsuario':
        formData.append('avatar', file);
		formData.append('idp', user.Usu_ID);
        return await axios.post(apiFile, formData, config);
    default: 
    console.log("No hay coincidencias");
    }
  }  
  
  
  
  const titulo = () => {
	let salida = '';
      switch (props.uploadCase) {
      case 'SubirFotoPerfil':
        salida = contacto.tipo >=3?'La foto debe tener una resolución de 175 x 175 píxeles':'La imagen puede ser tranparente y debe tener un ancho de 220 píxeles';
		break;
		case 'SubirBanner':
        salida = 'La foto de portada debe tener una resolución de 1200 x 450 píxeles';
		break;
		case 'SubirBanner1':
        salida = 'La 2da foto de portada debe tener una resolución de 1200 x 450 píxeles';
		break;
		case 'SubirBanner2':
        salida = 'La 3ra foto de portada debe tener una resolución de 1200 x 450 píxeles';
		break;
		case 'SubirBanner3':
        salida = 'La 4ta foto de portada debe tener una resolución de 1200 x 450 píxeles';
		break;		
	  case 'subirStaff':
		salida = contacto.tipo >=3?'La foto debe tener una resolución de 500 x 700':'La foto debe tener una resolución de 1000 x 500'
		break;
	  case 'SubirFotoUsuario':
		salida = 'La foto de perfil debe tener una resolución de 340 x 300 píxeles';
		break;
    default: 
    console.log("No hay coincidencias");
    }
	console.log(salida);
	return salida;
  }
  
  
  
  return (
    <form onSubmit={ onSubmit }>
	  <p>{titulo()}</p>
      <input type="file" onChange={ onChange } />
      <button className="logboton join-btn" type="submit" style={{float:'right'}}>Subir archivo</button>
<span className="p-float-label" style={{color:'#fafafa'}}>{progress}</span>
<ProgressBar style={{'display': progress !==0 ? 'block':'none', marginBottom:'1em'}} value={progress}></ProgressBar>
    </form>
 )
  
}
