import { Galleria } from "primereact/galleria";
import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { MegaMenuLink } from "./MegaMenuLink";
import { MegaMenuCel } from "./MegaMenuCel";
import { StoreContext } from "./store/StoreProvider";
import { types } from "./store/StoreReducer";
import { InfoData } from "./InfoData";
//import { Slideshow, Slide } from './Slideshow';
import portada3 from './assets/images/portada-3-990-400.png';
import portada4 from './assets/images/portada-4-990-400.png';
import portada5 from './assets/images/portada-5-990-400.png';


export const Supermenu = (props) => {
  const [store, dispatch] = useContext(StoreContext);
  const { categoria ,zona, ruta, menu, img } = store;
  const [menuCel, setMenuCel] = useState([]);
  //console.log(zona);  

  const images = [
    { "itemImageSrc": portada3,  "alt":"Descripción de imagen 1" },
    { "itemImageSrc": portada4, "alt":"Descripción de imagen 2" },
    { "itemImageSrc": portada5, "alt":"Descripción de imagen 3" },	
  ];
  const responsiveOptions = [
    { breakpoint: "1024px", numVisible: 5 },
    { breakpoint: "768px", numVisible: 3 },
    { breakpoint: "560px", numVisible: 1 },
  ];

  useEffect(() => {
    	categorias('leer');
  }, []);   //eslint-disable-line react-hooks/exhaustive-deps

  const ParametroBus = (item, etiqueta, categoria) => {
    let data = {
      abueloID: item.abuelo || "",
      abuelo: categoria,
      padreID: item.papa || "",
      padre: etiqueta,
      Arbol_ID: item.Cat_ID,
      Arbol: item.label,
    };
    let query = {
      abueloID: item.abuelo || "",
      padreID: item.papa || "",
      Arbol_ID: item.Cat_ID,
    };
	let barra=query.Arbol_ID?'c='+query.Arbol_ID:query.padreID?'f='+query.padreID:'a='+query.abueloID;
    let Categoria = categoria;
    let Etiqueta = etiqueta;
    let Label = item.label;
    let v = "/";
    let parametro = Categoria + v + Etiqueta + v + Label;
    parametro = parametro.split(" ").join("-");
    dispatch({ type: types.categoria, payload: { data, query, parametro, barra } });
    dispatch({ type: types.contactos, payload: [{ Contacto_ID: "0" }] });
  };
  
  
  const categorias = async (accion) => {
    let datos = JSON.parse(localStorage.getItem("items")) || [];
    if (datos.length === 0) {
      let resource = await InfoData( types.categorias, accion);
      console.log(resource.data);	  
      localStorage.setItem("items", JSON.stringify(resource.data));
      datos = resource.data;
    }
    let categorias = crearMenu(0, datos);
    let categorias1 = crearMenuCel(0, datos);	
    dispatch({ type: types.menu, payload: categorias });
	setMenuCel(categorias1);
    return categorias;
  };  


  const crearMenu = (ID_padre, arbol) => {
    let hijos = [];
    let top = -120;
    for (var tallo in arbol) {
      if (parseInt(arbol[tallo].padre) === ID_padre) {
        let items = [];
        let contador = 0;
        let set = 0;
        let por = 1;
        for (var rama in arbol) {
          if (arbol[rama].padre === arbol[tallo].Cat_ID && contador <= 12) {
            let lista = [];
            let conteo = 0;
            let clase = "";
            let padre = "";
            for (var hoja in arbol) {
              if (arbol[hoja].padre === arbol[rama].Cat_ID) {
                if (conteo < 3) {lista[conteo] = {label: arbol[hoja].label, Cat_ID: arbol[hoja].Arbol_ID};} 
                else {lista[conteo] = {label: arbol[hoja].label, Cat_ID: arbol[hoja].Cat_ID, padre: arbol[hoja].padre, oculto: true};}
                conteo = conteo + 1;
                padre = arbol[hoja].padre;
                clase = document.getElementsByClassName("p-menuitem-link " + arbol[hoja].padre);
              }
              if (conteo > 3) {
                //eslint-disable-next-line
                lista[conteo] = {label: "Mostrar", padre: padre, mas: true, command: () => {
                    for (let n in clase) {if (!isNaN(n)) {clase[n].classList.toggle("p-oculto");}};
                    document.getElementsByClassName("p-mas " + padre)[1].classList.toggle("p-menos");
                    document.getElementsByClassName("p-mas " + padre)[1].classList.toggle("p-oculto");
                    if (document.getElementsByClassName("p-mas " + padre)[0].classList.value === padre + " p-menuitem-link p-mas") 
                    {if (window.scrollY > 150) {window.scrollTo(0, 0);}}
                  }};
              }
            }
            if ([0, 6, 24, 54].includes(contador * por)) {
              items[contador - set] = [{label: arbol[rama].label, Cat_ID: arbol[hoja].Cat_ID, items: lista}];} 
            else {
              items[contador - 1 - set].push({label: arbol[rama].label, Cat_ID: arbol[hoja].Cat_ID, items: lista});
              por = set = set + 1;
            }
            contador = contador + 1;
          }
        }
        hijos.push({icon: arbol[tallo].icon, items: items, label: arbol[tallo].label, Cat_ID: arbol[hoja].Cat_ID, top: top,});
        top = top - 100;
      }
    }
    return hijos;
  };
  
  
    const crearMenuCel = (ID_padre, arbol) => {
    let hijos = [];
    let top = -142;
    for (var tallo in arbol) {
      if (parseInt(arbol[tallo].padre) === ID_padre) {
        let items = [];
        let contador = 0;
        let set = 0;
        let por = 1;
        for (var rama in arbol) {
          if (arbol[rama].padre === arbol[tallo].Cat_ID && contador <= 12) {
            let lista = [];
            let conteo = 0;
            let clase = "";
            let padre = "";
            for (var hoja in arbol) {
              if (arbol[hoja].padre === arbol[rama].Cat_ID) {
                if (conteo < 3) {lista[conteo] = {label: arbol[hoja].label, Cat_ID: arbol[hoja].Cat_ID};} 
                else {lista[conteo] = {label: arbol[hoja].label, Cat_ID: arbol[hoja].Cat_ID, padre: arbol[hoja].padre, oculto: true};}
                conteo = conteo + 1;
                padre = arbol[hoja].padre;
                clase = document.getElementsByClassName("p-menuitem-link " + arbol[hoja].padre);
              }
              if (conteo > 3) {
                //eslint-disable-next-line
                lista[conteo] = {label: "Mostrar", padre: padre, mas: true, command: () => {
                    for (let n in clase) {if (!isNaN(n)) {clase[n].classList.toggle("p-oculto");}};
                    document.getElementsByClassName("p-mas " + padre)[0].classList.toggle("p-menos");
                    document.getElementsByClassName("p-mas " + padre)[0].classList.toggle("p-oculto");
                    if (document.getElementsByClassName("p-mas " + padre)[0].classList.value === padre + " p-menuitem-link p-mas") 
                    {if (window.scrollY > 150) {window.scrollTo(0, 0);}}
                  }};
              }
            }
            if ([0, 12].includes(contador * por)) {
              items[contador - set] = [{label: arbol[rama].label, Cat_ID: arbol[hoja].Cat_ID, items: lista}];} 
            else {
              items[contador - 1 - set].push({label: arbol[rama].label, Cat_ID: arbol[hoja].Cat_ID, items: lista});
              por = set = set + 1;
            }
            contador = contador + 1;
          }
        }
        hijos.push({icon: arbol[tallo].icon, items: items, label: arbol[tallo].label, Cat_ID: arbol[hoja].Cat_ID, top: top,});
        top = top - 100;
      }
    }
    return hijos;
  };

  
  

  const getMenuClickData = (event, item) => {
    console.log(event);
    console.log(item);
	dispatch({ type: types.contactos, payload: [{Contacto_ID:'0'}] });
  };

  const buscar = (caso, web, gen) => {
    ParametroBus(
      {
        Cat_ID: gen === 1 ? caso : "",
        papa:   gen === 2 ? caso : "",
        abuelo: gen === 3 ? caso : "",
        label: web.split("/")[2],
      },
      web.split("/")[1],
      web.split("/")[0]
    );
    document.getElementById("superMenu").style.display = "none";
    window.scrollTo(0, 0);
  };
  
  const prepara = (caso, web, gen) => {
    ParametroBus(
      {
        Cat_ID: gen === 1 ? caso : "",
        papa:   gen === 2 ? caso : "",
        abuelo: gen === 3 ? caso : "",
        label: web.split("/")[2],
      },
      web.split("/")[1],
      web.split("/")[0]
    );
  };

  

  const itemTemplate = (item) => {
    return React.createElement("img", {
      src: item.itemImageSrc,
      alt: item.alt,
      style: { display: "block" },
    });
  };

  
  const thumbnailTemplate = (item) => {
    return React.createElement("img", {
      src: item.thumbnailImageSrc,
      alt: item.alt,
      style: { display: "block" },
    });
  };

  
  const regresar = () => {
	document.getElementById('lateral').style.width='20em'
	document.getElementById('categorias').style.display='block';
	document.getElementById('regreso').style.display='none';
	document.getElementById('superMenu').style.position='fixed';	
  };  
  

  return  (
    <div className="main-content main-first-floor" id="superMenu">
      <div className="panelMenu movil" style={{background:'#FFFFFF', boxShadow:'rgb(0 0 0 / 35%) 0px 1px 1px 0px'/*, marginTop:margenMovil()?'auto':'4em'*/}}>
        <div className="colu col-left"> 
          <div className="home-category">
              <div className="category-left">
                <div className="left-title" style={{borderColor:'#fecc00'}}>
                  <div id="categorias" style={{display:'block', background:'none', color:'#333', padding:'1em'}}>
                  TODAS LAS CATEGORÍAS</div>
				  {false?<div id="regreso" style={{display:'none', width:'95vw', height:'3.65', textAlign:'center', padding:'0.5em'}}>
					  <div className="btnAmarillo" onClick={(e) => regresar() }  style={{float:'left', width:'40%', padding:'1em'}}>
					  REGRESAR</div>
					  <div id="mensaje" style={{float:'right', padding:'1em 0', width:'60%', fontSize:'1.25em', color: '#006aa7' }}>
					  Mensaje</div>					  
				  </div>:''}
				</div>
                <div className="left-expo" style={{display:'none'}}>
                </div>
    
                <div className="menu-category"> 
                <MegaMenuCel parametros={zona} model={menuCel} id="lateral" ParametroBus={ParametroBus} getMenuClickData={getMenuClickData} orientation="vertical"/>
                </div>
              </div>
              <div id="cerrar" style={{float:'right'}}>
			  <i className='pi pi-times cerrar' onClick={() => document.getElementById('superMenu').style.display = 'none'}/>			  
              </div>			  
          </div>  
          </div>
      </div>
	  
      <div className="panelMenu escritorio">
        <div className="row category-banner-ta">
          <div className="fix-content fix-content-left">
            <div className="fix-content fix-content-right">
              <div id="first-banner" className="first-banner ui-switchable">
                <div className="scroller" style={{position: 'relative'}}>
                  <div className="first-banner-content ui-switchable-content" data-role="content" style={{position: 'relative', left: '-100%'}}>
                    <div  className="banner-item ui-switchable-panel" style={{float: 'left'}}>   
                    </div>        
                    <div  className="banner-item ui-switchable-panel" style={{float: 'left', zIndex:'0'}}>
                    <Galleria className="banner-image" value={images} responsiveOptions={responsiveOptions} numVisible={5} style={{/* maxWidth: '640px'*/ }}
                    showItemNavigators showThumbnails={false} showItemNavigatorsOnHover showIndicators item={itemTemplate} thumbnail={thumbnailTemplate} circular autoPlay transitionInterval={3500} />
					{/*<Slideshow>
							<Slide sc>
								<img src={ruta + img.portada} alt='Portada principal'/>
							</Slide>
							<Slide sc>
								<img src={ruta + img.portada1} alt='Portada secundaria'/>
							</Slide> 	
					</Slideshow>*/}
                    </div>    
                  </div>      
                </div>     
              </div>
            </div>
            <div className="colu col-right">
              <div className="ta-promotion row-content" data-spm="201704">
                <Link className="top-banner" to={zona.parametro+'Profesionales/todos-los-profesionales/todas-las-categorias?'+categoria.barra+zona.query}>
                <span onMouseOver={(e) => prepara(1,'Profesionales/todos-los-profesionales/todas-las-categorias',3)} onClick={(e) => buscar(1,'Profesionales/todos-los-profesionales/todas-las-categorias',3)}>Tus servicios profesionales
                <span className="view-more">A solo un clic</span>
                </span></Link>
                <div className="ta-promotion-list">
                  <div className="ta-promotion-item zoom-wrap">
                    <div className="title" onMouseOver={(e) => prepara(2,'profesionales/abogados/todas-las-especialidades',2)} onClick={(e) => buscar(2,'profesionales/abogados/todas-las-especialidades',2)}>
					<Link to= {zona.parametro+'profesionales/abogados/todas-las-especialidades?'+categoria.barra+zona.query}><div style={{color:'#333333'}}>Abogados</div></Link></div>
                    <Link to= {zona.parametro+'profesionales/abogados/Derecho-de-familia?'+categoria.barra+zona.query}>
                    <div className="view-more" onMouseOver={(e) => prepara(7,'profesionales/abogados/Derecho-de-familia',1)} onClick={(e) => buscar(7,'profesionales/abogados/Derecho-de-familia',1)}>Derecho familiar</div></Link>
                    <div className="item-banner">
                    <Link to= {zona.parametro+'profesionales/abogados/todas-las-especialidades?'+categoria.barra+zona.query}>
                    <img onMouseOver={(e) => prepara(2,'profesionales/abogados/todas-las-especialidades',2)} onClick={(e) => buscar(2,'profesionales/abogados/todas-las-especialidades',2)} src={ruta+"assets/images/abogados.png"} alt="abogados" className="zoom-in"/></Link>
                    </div>
                  </div>
                  <div className="ta-promotion-item zoom-wrap">
                    <div className="title" onMouseOver={(e) => prepara(16,'profesionales/médicos/todas-las-especialidades',2)} onClick={(e) => buscar(16,'profesionales/médicos/todas-las-especialidades',2)}>
					<Link to= {zona.parametro+'profesionales/médicos/todas-las-especialidades?'+categoria.barra+zona.query}><div style={{color:'#333333'}}>Medicos</div></Link></div>
                    <Link to= {zona.parametro+'profesionales/médicos/Dermatólogos?'+categoria.barra+zona.query}>
                    <div className="view-more" onMouseOver={(e) => prepara(34,'profesionales/médicos/Dermatólogos',1)} onClick={(e) => buscar(34,'profesionales/médicos/Dermatólogos',1)}>Dermatólogos</div></Link>
                    <div className="item-banner">
                    <Link to= {zona.parametro+'profesionales/médicos/todas-las-especialidades?'+categoria.barra+zona.query}>
                    <img onMouseOver={(e) => prepara(16,'profesionales/médicos/todas-las-especialidades',2)} onClick={(e) => buscar(16,'profesionales/médicos/todas-las-especialidades',2)} src={ruta+"assets/images/medicos.png"} alt="medicos" className="zoom-in"/></Link>
                    </div>
                  </div>
                  <div className="ta-promotion-item zoom-wrap">
                    <div className="title" onMouseOver={(e) => prepara(114,'profesionales/arquitectos/todas-las-especialidades',2)} onClick={(e) => buscar(114,'profesionales/arquitectos/todas-las-especialidades',2)}>
					<Link to= {zona.parametro+'profesionales/arquitectos/todas-las-especialidades?'+categoria.barra+zona.query}><div style={{color:'#333333'}}>Arquitectos</div></Link></div>
                    <Link to= {zona.parametro+'profesionales/arquitectos/Edificador-residencial?'+categoria.barra+zona.query}>
                    <div className="view-more" onMouseOver={(e) => prepara(115,'profesionales/arquitectos/Edificador-residencial',1)} onClick={(e) => buscar(115,'profesionales/arquitectos/Edificador-residencial',1)}>Edif. residencial</div></Link>
                    <div className="item-banner">
                    <Link to= {zona.parametro+'profesionales/arquitectos/todas-las-especialidades?'+categoria.barra+zona.query}>
                    <img onMouseOver={(e) => prepara(114,'profesionales/arquitectos/todas-las-especialidades',2)} onClick={(e) => buscar(114,'profesionales/arquitectos/todas-las-especialidades',2)} src={ruta+"assets/images/arquitectos.png"} alt="arquitectos" className="zoom-in"/></Link>
                    </div>
                  </div>                
                </div>
              </div>
            </div>
          </div> 
          
          <div className="colu col-left"> 
          <div className="home-category">
              <div className="category-left">
                <div className="left-title" style={{borderColor:'#F4F4F4'}}>
                  <h3 style={{background:'none', color:'#333'}}>
                  MIS CONTACTOS</h3>
                </div>
                <div className="left-expo" style={{display:'none'}}>
                </div>
    
                <div className="menu-category"> 
                <MegaMenuLink parametros={zona} model={menu} ParametroBus={ParametroBus} getMenuClickData={getMenuClickData} orientation="vertical"/>
                </div>
              </div>
          </div>  
          </div>
        </div>
      </div>
    </div>
  );
  
  };
