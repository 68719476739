import React, {Component} from 'react';
import { Link } from 'react-router-dom';


export class AppFooter extends Component {
  render() {
    return  (
        <div className="layout-footer" id="footer" style={{padding:'1em', display:'flex', flexFlow:'wrap', width:'100%', position:'relative', bottom:'0'}}>
			<div className="derechos">
            <span className="footer-text" >&copy; 2022 <Link to= "/">Servicontacto</Link></span>
            <span className="footer-text movil">| Comercio electrónico cerca</span>			
            <span className="footer-text escritorio" >| El comercio electrónico más cerca a la comunidad</span>
	        </div>
			<div style={{float:'right'}}>
			<span className="footer-text movil"><Link to= "/acerca/about">Acerca de SC</Link></span>						
			<span className="footer-text escritorio"><Link to= "/acerca/about">Acerca de Servicontacto</Link></span>			
			<span className="footer-text"><Link to= "/legal/privacy-policy">Privacidad</Link></span>			
			<span className="footer-text"><Link to= "/legal/user-agreement">Condiciones</Link></span>
			<span className="footer-text"><Link to= "/legal/cookies-policy">Cookies</Link></span>			
			</div>
        </div>
   );
}

}
