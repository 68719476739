import classNames from 'classnames';
import { useContext, useState, useEffect, Fragment } from 'react';
import { Link /*, useHistory*/ } from 'react-router-dom';
import { Agenda } from './Agenda';
import { Button } from "primereact/button";
import { StoreContext } from './store/StoreProvider';
import { types }  from './store/StoreReducer';
import { InfoData } from "./InfoData";
import axios from "axios";

export const Contacto = (props) => {

const [store, dispatch] = useContext(StoreContext);
const {contacto, especialidad, categorias, zona, api } = store;
const [pag404, setPag404] = useState(false);
const [cargo, setCargo] = useState(false);
const lateral = window.innerWidth>'800'?true:false;
//const history = useHistory();
//console.log(props.match.params);

useEffect(() => {
  ContactoData(props.match.params.userweb);
  //eslint-disable-next-line
}, [contacto?.Contacto_ID!=='']);


const verifica = (nombre) => { 
let web1=props.match.params.contacname?.split('_C')[0];
let web2=nombre?.split(' ').join('-');
console.log(web1);
console.log(web2);
if(web1!==web2){
//history.push({pathname:"/"})
setPag404(true);
}
setCargo(true);
}


const visita = async (contacto, tipo) => {
    let db = await InfoData('visitaCto', {contacto:contacto,tipo:tipo});  
	console.log(db)
}

	
const ContactoData = async (userweb) => {
	let interna = contacto.load==='porListado'?true:false;
	let resource = await serverContacto(userweb);
//	let resource = await InfoData( types.contacto, userweb);	
	if(props.match.params.contacname.split('_')[1]==='C')
	{dispatch({ type: types.contacto, payload: { ...contacto, ...resource.data, load:'porContacto', booking:'agenda' } });}
	else{
	dispatch({ type: types.contacto, payload: { ...contacto, ...resource.data, load:'porContacto'} })
	}
    Especialidades(resource.data?.Contacto_ID || contacto.Contacto_ID);		
	verifica(resource.data.Nombre_Comercial || contacto.Nombre_Comercial);
	if(interna){visita(resource.data.Contacto_ID || contacto.Contacto_ID, 'i'); console.log(resource.data.visitaCtoIn+' interno '+resource.data.Contacto_ID)}
	else{visita(resource.data.Contacto_ID, ''); console.log(resource.data.visitaCtoEx+' externo '+resource.data.Contacto_ID)}	
}


const serverContacto = async (userweb) => {
  var config = { headers: { 'content-type': 'multipart/form-data' }};
  const formData = new FormData();
  formData.append('operacion', 5);
  formData.append('userweb', userweb);
  return await axios.post(api, formData, config);
}  

  
const Especialidades = async (Contacto_ID) => {
    let cadena = await InfoData( types.especialidad, {Contacto_ID:Contacto_ID} );
    let cadena1 = cadena.pop(); 	
	dispatch({ type: types.especialidad, payload: cadena });
	dispatch({ type: types.categorias, payload: cadena1 });
}  

  
const barras=<div id="initialLoadingContainer" className="loader-icon-container" style={{height:window.innerWidth>'800'?'calc(100vh - 142px)':'calc(100vh - 155px)', position:'relative'/*, 'display':!contacto.Contacto_ID?'block':'none'*/}}>
    <div className="loader is-active centrar-bars">
        <div className="loader-bars"></div>
    </div>
  </div>;
const i = <i className='pi pi-chevron-right' style={{margin:'0 7px', fontSize:'9px'}}/>
  
  return (
    <Fragment>
      {!pag404?<div className="marco">
        <Link to= {'/'+zona.data?.Pais+'/'+contacto.Nombre_Departamento?.split('-').join(' ')+'/'+contacto.Nombre_Distrito?.split('-').join(' ')+'/categoria/'+contacto.Nombre_Padre+"/"+contacto.Nombre_Cat+'?c='+categorias[0]+'&d='+contacto.distrito}> 
        <div className="rutaweb contactoN" style={{'visibility':cargo?'visible':'hidden', paddingLeft:'0.5em'}} onClick={(e)=> dispatch({ type: types.contactos, payload: [{Contacto_ID:'0'}] })}>{zona.data?.Pais}{i}{contacto.Nombre_Departamento?.split('-').join(' ')}{i}{contacto.Nombre_Distrito?.split('-').join(' ')}{i}{contacto.Nombre_Padre}{i}{contacto.Nombre_Cat}</div>
        </Link>
        <div className="layout-Seeker contactoN" style={{marginTop: '5px'}}>
		{!cargo?barras:
        <Superpanel lateral={lateral} contacto={contacto} especialidad={especialidad} categorias={categorias.length}/>}
        </div>
      </div>:
	   <div style={{height:'calc(100vh - 114px)'}}>
			<div style={{top:'50%', left:'50%', position:'absolute', transform:'translate(-50%, -50%)', textAlign:'center'}}>
			<h1>Esta página no esta disponible</h1>
			<p>Es posible que el enlace este roto o que se haya eliminado</p>	
			<p>la página. Verifica que el enlace que quieres abrir es correcto</p>
			<Link><Button  className="usuBoton" label="Ir al inicio"/></Link>
			</div>
	   </div>}    
    </Fragment>    
    );

}






export const Superpanel = (props) => {

const [store, dispatch] = useContext(StoreContext);
const {contacto, especialidad, ruta, img, fecha, monedas, horario} = store;
const fotoF = ruta+img.fotoF;
const fotoM = ruta+img.fotoM;
const logoC = ruta+img.logoC;
const logoB = ruta+img.logoB;
const fotoI = ruta+img.fotoI;
const calendar = ruta+img.calendar;
const tiktok = ruta+img.tiktok;
const instagram = ruta+img.instagram;
const imagen = [{img:fotoI},{img:logoC },{img:logoB},{img:fotoM},{img:fotoF}];
const [especialidad1, setEspecialidad1] = useState([]); 
var futuro=false;


const Fecha = (e) => {
futuro = (new Date(e?.cerradohasta)).valueOf() > (new Date()).valueOf()?true:false;
let hasta = 'HASTA '+ (new Date(e?.cerradohasta)).toLocaleDateString("es-PE",{weekday: "short", day: "numeric"}).toUpperCase()
let fechaPanel = futuro?hasta:fecha;
return fechaPanel;
}
	
		
const salto = (id) => { 
//document.getElementById('listaCat').scrollIntoView({behavior: 'smooth'});
//window.scrollTo(0, document.getElementById('listaCat').offsetTop - 120);
window.scrollTo({top: document.getElementById(id).offsetTop - 120, behavior:'smooth'})
 }

 
const especialistas = (id,catID,item) => { 
	document.getElementsByClassName('masContacto')[item].style.display='block';
	if(item!==especialidad1.item){
		Especialistas(id,catID,item);
			if(typeof document.getElementsByClassName('masContacto')[especialidad1.item] !== 'undefined'){
			document.getElementsByClassName('masContacto')[especialidad1.item].style.display='none';}
	}
	especialidad.map(lista => columnas(lista));  
	if(especialidad1.uso >1 ){document.getElementById('listEsp').style.minHeight = especialidad1.uso*2+'em';}
 }
 

const columnas = (cadena) => {
if (cadena.label.length > 32){document.getElementsByClassName("columnas")[0].style.columnCount='2'}
if (cadena.uso >1 ){document.getElementById('listEsp').style.minHeight = cadena.uso*2+'em';}
}
 

const Especialistas = async (Contacto_ID,catID,item) => {
	if(item!==especialidad1.item){setEspecialidad1({...especialidad1, socio:[]});}
	document.getElementsByClassName('masCajita')[item].style.display='block';
    let cadena = await InfoData('especialista', {contacto:Contacto_ID,catID:catID});
	cadena.pop(); 	
 	setEspecialidad1({...especialidad1, socio:cadena, item:item});
	document.getElementsByClassName('masCajita')[item].style.display='none';
  } 
 
 
const webSite = (e,modo) => {
	dispatch({ type: types.contacto, payload:{...contacto, socio:e, booking:modo}   })
	document.getElementById('quilla1').innerHTML = e.Presentacion;
    }
	

const acercaDe = (texto) => {
	let text = texto?.split('</p>')[0]+'</p><p id="masp" class="mas" style="text-decoration:underline;">Leer mas</p>';
	let donde = document.getElementById('breve');
	let largo = document.getElementById("quilla");
		if(donde!==null){donde.innerHTML = text;	largo.innerHTML = texto;
		document.getElementById('masp').onclick = function(){largo?.classList.toggle('escritorio'); donde?.classList.toggle('oculto');} 	
		}
    }	

	
/*Zona de renderización*/	

const icons = 7-(1+(contacto.Tiktok?1:0)+(contacto.Instagram?1:0)+(contacto.Facebook?1:0)+(contacto.Twitter?1:0)+(contacto.Youtube?1:0)+(contacto.celular?1:0));
const anchoImg = String(icons<2?(11.5+2.5*icons):icons<4?(7+5*icons):22)+'vw';
const anchoIco = String(icons<2?(12.5+3*icons):icons<4?(8+icons*5.5):24.5)+'vw';
const dia = <span  className="calendario" style={{ fontSize:window.innerWidth>'800'?'14px':'28px'}} onClick={e => dispatch({ type: types.contacto, payload: {...contacto, booking:contacto.booking?'':'agenda'} })}>{new Date().toLocaleDateString("es-PE", {day: "numeric" })}</span>;
const mes = <span className="calendario" style={{top:32-2*icons+'%'}}>{new Date().toLocaleDateString("es-PE", {month: "long" })}</span>;
const booking = <Agenda contacto={props?.contacto} socio={props?.socio}/>;
const agenda = <i className="pi pi-calendar icoBoton" onClick={ e => dispatch({ type: types.contacto, payload: {...contacto, booking:'agenda'} })} style={{color: '#03a9f4',verticalAlign:'text-top',float:'left',margin:'auto','display':/*props.preview === 'ver'*/true?'block':'none' }}></i>;
const agendar = <div style={{float:'left', position:'relative', cursor:'pointer'}} onClick={e => dispatch({ type: types.contacto, payload: {...contacto, booking:contacto.booking?'':'agenda'} })}>{mes}{dia}<img className="imgPag" alt='' src={calendar} style={{width:'calc('+anchoImg+' + 0.5vw)'}}/></div>
const linkWeb = props.router?<i className="fas fa-globe" style={{color: '#1877f2'}}><span style={{color: '#777', marginLeft:'5px'}}>Contacto web</span></i>:
<Link to= {"/"+contacto?.userweb}><i className="fas fa-globe" style={{color: '#1877f2'}}><span style={{color: '#777', marginLeft:'5px'}}>Contacto web</span></i></Link>;
const TT = contacto.Tiktok?<a href={contacto.Tiktok} target="_blank" rel="noreferrer"><img className="imgPag" alt='' src={tiktok} style={{width:anchoImg}}/></a>:'';
const IG = contacto.Instagram?<a href={contacto.Instagram} target="_blank" rel="noreferrer"><img className="imgPag" alt='' src={instagram} style={{width:anchoImg}}/></a>:'';
const FB = contacto.Facebook?<a href={contacto.Facebook} target="_blank" rel="noreferrer"><i className="fab fa-facebook-square icoPag" style={{fontSize:anchoIco, verticalAlign:'top'}}></i></a>:'';
const TW = contacto.Twitter?<a href={contacto.Twitter} target="_blank" rel="noreferrer"><i className="fab fa-twitter icoPag" style={{color: '#1d9bf0', fontSize:anchoIco, verticalAlign:'top'}}></i></a>:'';	
const YT = contacto.Youtube?<a href={contacto.Youtube} target="_blank" rel="noreferrer"><i className="fab fa-youtube icoPag" style={{color: '#f70000', fontSize:'calc('+anchoIco+' - 5vw)', padding:'7% 0'}}></i></a>:'';
const WA = contacto.celular?<a href={'https://wa.me/'+contacto.DDI+contacto.celular} target="_blank" rel="noreferrer"><i className="fab fa-whatsapp-square icoPag" style={{color: '#44e561', fontSize:anchoIco, verticalAlign:'top'}}></i></a>:'';
const linkSite =  props.router?<i className="fas fa-home" style={{color: '#f9f9f9', margin:'0 8px', fontSize:'1em'}}></i>:
	  <Link to = '/modelo/web'><i className="fas fa-home" style={{color: '#f9f9f9', margin:'0 8px', fontSize:'1em'}}></i></Link>;
const pagina = <a href={contacto.web} target="_blank" rel="noreferrer" style={{fontFamily: 'times'}}><i className="fas fa-globe" style={{color: '#1877f2',margin:'0 3px' }}></i>Página web</a>
const className = classNames('p-grid panelContacto', props.className);
const titulo = contacto?.tipo >=3? contacto.Nombre_Cat_Solo?props.categorias>1?contacto.Nombre_Padre_solo+' '+ contacto.Nombre_Cat?.toLowerCase():contacto.Nombre_Cat_Solo:contacto.Nombre_Padre_solo+' '+ contacto?.Nombre_Cat?.toLowerCase():
				contacto?.Nombre_Padre_Grupo+' '+ contacto.Nombre_Cat?.toLowerCase();
				
const cajita =  especialidad1?.socio?.map(fila  =>  <Fragment key={fila.item}>
				<li  onClick={(e) => webSite(fila)} style={{float:'left', cursor:'pointer'}}>{fila.Nombre_Comercial}</li>
				<i className='pi pi-calendar icoBoton' onClick={(e) => webSite(fila,'agenda')} style={{float:'right', margin:'0'}}></i>
				<i className='pi pi-times icoBoton' onClick={(e) => props.subContacto(contacto.Contacto_ID,fila,'del')} style={{float: 'right', color:'red', 'display':props.preview === 'no'?'block':'none'}}></i>
				</Fragment>);
const listaEsp = especialidad?.map(lista => <Fragment key={lista.Arbol_ID}>
				<li className={lista.uso!==null?"mas":''} style={{background:lista?.Arbol_ID===contacto.editArea?.Arbol_ID?'#dcefff':'', width:'110%'}}>
					<span onClick={() => lista.uso!==null?especialistas(contacto.Contacto_ID,lista.Arbol_ID, lista.item):''}>{lista.label}</span>  
					<div className="masContacto" onMouseLeave={() => document.getElementsByClassName('masContacto')[lista.item].style.display='none'} style={{'display':'none', minHeight:lista.uso*1.5+'em'}}> 
					<img  style={{width:'auto'}} className="masCajita" alt="loader" src={ruta+img.loader}/>
						<ul style={{fontWeight:'bold', color:'#006aa7'}}>
						{cajita}
						</ul>
					</div>
					<i className='fas fa-pen icoBoton' onClick={(e) => dispatch({ type: types.contacto, payload: {...contacto, editArea:lista} })} style={{float: 'right', margin: '0 0 0 10px', color:'#bdbdbd', 'display':props.preview === 'no'?'block':'none'}}></i>
				{contacto.tipo >=3?'':<i className='pi pi-user-plus icoBoton' onClick={(e) => props.subContacto(contacto.Contacto_ID, lista ,'add')} style={{float: 'right', margin: '0 0 0 10px', color:'#bdbdbd', 'display':props.preview === 'no'?'block':'none'}}></i>}
				</li></Fragment>);
const caso = ((contacto.tarifa!=='0'||contacto.vertarifa)?1:0) + ((contacto.usoweb==='0'&&!contacto.web)?0:1);
const ancho = String(caso<2?(49-17*caso):(49-17*(1+1/caso)))+'%';				
const barraMenu = <Fragment>
		<ul className="menuCto" style={{margin:'0'}}>
		<li style={{width:caso<2?ancho:'calc('+ancho+' + 7%)', marginRight:'2%', display:contacto.usoweb==='0'&&!contacto.web?'none':'block'}}><div>{contacto.web?contacto.usoweb==='4'?linkWeb:pagina:contacto.usoweb==='0'?'':linkWeb}</div></li>
		<li onClick={(e) => salto('ubicacion')} style={{width:ancho}}><i className="fas fa-map-marker-alt" style={{color: '#18b32b'}}><span style={{color: '#777'}}> Ubicación</span></i></li>
		<li onClick={(e) => salto('telefono')} style={{width:ancho, marginLeft:'2%'}}><i className="fas fa-phone-alt" style={{color:'#3F51B5'}}><span style={{color: '#777', marginLeft:'5px'}}>Contacto</span></i></li>
		<li onClick={(e) => salto('tarifa')} style={{width:caso<2?ancho:'calc('+ancho+' - 7%)', marginLeft:'2%','display':(contacto.tarifa!=='0'||contacto.vertarifa) ? 'block':'none'}}><i className="fas fa-dollar-sign" style={{color:'#1f9f2f'}}><span style={{color: '#777', marginLeft:'5px'}}>Tarifa</span></i></li></ul>
	  </Fragment>;
const verif = <><i className="fa fa-certificate" style={{color: '#1d9bf0', margin:'0 8px', fontSize:'15px', verticalAlign:'middle'}}></i><i className="fa fa-check" style={{color: '#fff', fontSize:'7px', margin:'-19px',  verticalAlign:'middle'}}></i></>
const inicio = new Date(new Date(new Date().setMinutes(contacto.inicio)).setHours(contacto.inicio/60)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'/*, hour12: true*/});	  
const fin = new Date(new Date(new Date().setMinutes(contacto.fin)).setHours(contacto.fin/60)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'/*, hour12: true*/});	  
const inicio1 = new Date(new Date(new Date().setMinutes(contacto.inicio1)).setHours(contacto.inicio1/60)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});	  
const fin1 = new Date(new Date(new Date().setMinutes(contacto.fin1)).setHours(contacto.fin1/60)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});	  
const refrigerio = new Date(new Date(new Date().setMinutes(contacto.refrigerio)).setHours(contacto.refrigerio/60)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});	  
const intermedio = new Date(new Date(new Date().setMinutes(parseInt(contacto.refrigerio)+parseInt(contacto.entretiempo))).setHours((parseInt(contacto.refrigerio)+parseInt(contacto.entretiempo))/60)).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});	  
const nombreCto = <h1 className='h1M'>{contacto.Nombre_Comercial}{contacto.verificado==='1'?verif:''}</h1>;


return (
<Fragment>
{props.revisar?<div style={{position:'relative', width:'100%', textAlign:'end'}}>{props.revisar}</div>:''}
<div className={className} style={{...props.style , marginBottom:'20px'}}>

	<div className="movilMini" style={{textAlign:'center', width: '100vw', margin:'0.8rem -0.4rem 0.3rem'}}>{nombreCto}</div> 
	<div className="sangria" style={{display:'flex', flexWrap:'wrap', width:'100%'}}>
	<div className="p-col-3 col-foto">  
		<div className="alturaFoto">  
		<img className={contacto.tipo >=3?'fotoContacto panelM':'logoContacto'} alt="imagen" src={contacto.tipo >=3?contacto.Foto?ruta+contacto.Foto:imagen[contacto.tipo?contacto.tipo:'0'].img:contacto.logo?ruta+contacto.logo:imagen[contacto.tipo?contacto.tipo:'0'].img} style={{background:contacto.tipo ==='2'?contacto.color:'none',padding:contacto.tipo ==='2'?'0.5em':'0', borderRadius:contacto.tipo <'3'?'':'1em'}}/>   
		</div>
	</div>
	<div className="p-col-7 col-dato"> 
		<div className="p-grid box-dato sangria">
			<div className="p-col-12" style={{fontSize:'14', padding:'10px 0 0 10px', lineHeight:'1.5'}}><span className="movilGrande">{nombreCto}</span><Fragment>{titulo}<span className='mas'><span onClick={(e) => salto('listaCat')}>{props.categorias>1?', '+(props.categorias - 1)+' especialidades más':''}</span></span></Fragment></div>
			<div className="p-col-12" style={{top:'0.5em', lineHeight:'1.5', marginBottom:window.innerWidth>500?'40px':'0'}}>{"Distrito de "+contacto.Nombre_Distrito+", "+contacto.Nombre_Provincia}</div> 
		</div>
		<div className="p-col-12 movil500mas barraHeight sangria" > {barraMenu}</div> 		
	</div>
	<div className="p-col-2 col-lado">  
		{/*props.revisar*/}
		<p style={{cursor:'pointer'}}><i className="pi pi-star"  style={{float:'left',color:'#03a9f4', margin:'-2px 3px 0 0'}}></i><span style={{fontWeight:'bold', float:'left', color:'#03a9f4'}}><Link to = {"/feedback/"+contacto.userweb+'/'}>{contacto.alcance}</Link> </span>{'('+contacto.votos+' opiniones)'}</p>		
		{(contacto.tipo >=3 && contacto.videollamada )?<p style={{textAlign:'left'}}><i className="pi pi-video"  style={{float:'left',color:'#03a9f4',margin:'-1px 4px 0 0'}}></i>Consulta on-line</p>:''}
		{contacto.tipo >=3?contacto.nodisponible!=='0'?<div style={{height: '48px', boxShadow:'rgb(0 0 0 / 20%) 0px 1px 2px 0px'}}>
			<div style={{padding:'5px'}}>{agenda}{Fecha(contacto)}</div>
			<div style={{background:contacto.nodisponible>'1' || futuro?'#fffafa':'#f7fff2', padding:'5px',color:contacto.nodisponible>'1' || futuro?'red':'green'}}>{contacto?.nodisponible>'1' || futuro?'Sin citas disponibles':'Citas disponibles'}</div>
		</div>:'':''}
		<div style={{textAlign:'left', width:'100%', position:'absolute', bottom:'-5px'}}>{agendar}{WA}{FB}{IG}{TT}{/*YT*/}{/*TW*/}</div>
	</div>
	</div>
	<div className="p-col-12 movil500menos" style={{padding:'0.5rem 0'}}>{barraMenu}</div>


	
	<div className="p-col-12 movil500menos" style={{padding:'0.5rem 0', display:'flex'}}>{contacto.tipo>2?agendar:''}{WA}{FB}{IG}{TT}{YT}{TW}</div>	
	{contacto.tipo >= 3 ? contacto?.booking ? booking : '' : ''}
	<div className="cuadro">	
		{props.editorweb}
		<div className="acerca"> 
			<h2>{contacto.tipo >=3?"Acerca de mi":"Acerca de Nosotros"}</h2>
			<span className="presentacion escritorio" id="quilla"></span>
			<span className="presentacion movil" id="breve"></span>{acercaDe(contacto.Presentacion || '')}
		</div>
		{props.selectorCat}
		<div className="acerca"> 
			<h2>Especialidades</h2>
			<div id='listaCat' className="columnas" style={{clear:'both'}}>
				<ul id='listEsp' style={{listStyle:'inside',marginBlockStart:'0', display:especialidad.length<10?'inline-block':''}}> 
					{listaEsp}
				</ul>
			</div>
			{contacto.editArea?props.editorweb1(contacto.editArea):''}
			<Button className="agendar" label="Cancelar"  onClick={() => dispatch({ type: types.contacto, payload: {...contacto, editArea:''} })} style={{margin:'5px', display:contacto.editArea?'block':'none', top:'-50px', left:'10.5em', width:'8em'}}/>
		</div> 
		<div className="acerca" style={{'display':contacto.socio?.Contacto_ID? 'block':'none'}}> 
			<h2>{'Especialistas de '+contacto.Nombre_Comercial}</h2>
		<PanelSocio contacto={contacto.socio} socio={contacto} fecha={fecha} categorias={contacto?.socio?.categorias} lateral={props.lateral} booking={contacto.booking} style={{boxShadow: 'rgb(0 0 0 / 25%) 0px 0px 0px 0px', marginTop:'1rem'}}/>
		</div>
		{(contacto.tarifa!=='0'||contacto.vertarifa)?<div className="acerca" id="tarifa"> 
			<h2>Tarifa</h2>
			<div style={{clear:'both'}}>
			{contacto.tarifa!=='0'?'Costo de consulta: '+contacto.tarifa+' '+(monedas.find(item => item.value === contacto.moneda)?.label ||''):'Consulta gratuita'}
			</div>
		</div> :''}	
		<div className="acerca" id="horario"> 
			<h2>Horario de atención</h2>		
			 <div>
				 <div style={{float:'left'}}>
				 <div style={{margin:'0'}}><div className="horario"><span style={{margin:'0 2em 0 0'}}>{horario[contacto.horario]?.label1}</span> <span style={{fontWeight:'100', float:'right', 'display':contacto.horario==='0'?'none':'block'}}>{parseInt(contacto.entretiempo)===0?inicio+' - '+fin: inicio+' - '+refrigerio+' y desde '+ intermedio+' - '+fin}</span></div></div>
				 <div style={{margin:'0', display:(contacto.horario==='0'||contacto.horario>='4'||contacto.horario==='')?'none':'block'}}><div className="horario"><span style={{margin:'0'}}>{horario[contacto.horario]?.solo}</span> <span style={{fontWeight:'100', float:'right', 'display':contacto.horario==='0'?'none':'block'}}>{ inicio1+' - '+fin1}</span></div></div>
				 </div> 
				 <div style={{textAlign:'center', width:'65%', 'display':contacto.horario==='0'?'none':'none'}}>
					<p style={{textAlign:'left'}}>De acuerdo a disponibilidad de citas</p>
					<div style={{height: '48px', boxShadow:'rgb(0 0 0 / 20%) 0px 1px 2px 0px'}}>
					<div style={{padding:'5px'}}>{agenda}{Fecha(contacto, false)}</div>
					<div style={{background:contacto.nodisponible || futuro?'#fffafa':'#f7fff2', padding:'5px',color:contacto.nodisponible || futuro?'red':'green'}}>{contacto?.nodisponible || futuro?'Sin citas disponibles':'Citas disponibles'}</div>
					</div>
				 </div>
			 </div>
		</div>	

		<div className="acerca"> 
			<h2>Ubicación y contacto</h2>
			<div className="datosPagina" id="ubicacion">
			<div style={{'display':props.preview==='ver' ? 'block':props.preview==='no' ?'none':'block'}}>
			<i className="fas fa-map-marker-alt" style={{color: '#18b32b'}} ></i><span>Dirección</span> <br/>
			 <p>{contacto.direccion}</p>
			 <p>{"Distrito de "+contacto.Nombre_Distrito+", "+contacto.Nombre_Provincia+ " - "+contacto.Nombre_Departamento}</p>
			{contacto.telefono?<Fragment>	
			 <i className="fas fa-phone-alt" id="telefono" style={{color: '#3F51B5'}}></i><span>Teléfono fijo</span><br/>
            <p>{contacto.telefono}</p>
			</Fragment>:''}	
			<i className="fas fa-mobile-alt" id={contacto.telefono?'':"telefono"} style={{color: '#3F51B5'}}></i><span>Teléfono móvil</span><br/>
			<p>{contacto.celular}</p>
			{contacto.email?<Fragment>			
			<i className="far fa-envelope" style={{color: '#3F51B5'}}></i><span>Correo electrónico</span><br/>
            <p style={{overflow:'auto'}}>{contacto.email}</p> 
			</Fragment>:''}			
			{contacto.web?<Fragment>
			<i className="fas fa-globe" style={{color: '#1877f2'}}></i><span>Página web</span>{linkSite}<br/>
			<p style={{overflow:'auto'}}><a href={contacto.web} target="_blank" rel="noreferrer">{contacto.web}</a></p> 
			</Fragment>:''}
			<i className="fas fa-users" style={{color: '#3F51B5', marginBottom:'1em'}}></i><span>Redes Sociales</span><br/>
			<div style={{display:'flex', marginBottom:'1em'}}>{FB}{IG}{TT}{TW}{YT}{WA}</div>
			</div>
			{props.contactoWeb}
			</div>
			<div className="mapa">
			<iframe title="mapa" src={contacto.Gmaps} width="100%" height="490" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>
			</div> 
			{props.mapa}
		</div> 	
	</div>
</div>

{props.preview==='no'?
<div className="movil p-fluid reg-content-wrapper" style={{padding:'1em', background:'#fff'}}>
	<div className="form-container edit-box">
		<fieldset>			
		{props.editPerfil}
		</fieldset>
	</div>
</div>		
:''}

</Fragment>
);


}








export const PanelSocio = (props) => {

const [store, dispatch] = useContext(StoreContext);
const {contacto, ruta, img, fecha} = store;
var futuro=false;

useEffect(() => {
window.scrollTo({top: document.getElementById('Socio').offsetTop - 120, behavior:'smooth'});
}, [contacto.socio?.Contacto_ID]);


const Fecha = (e) => {
futuro = (new Date(e?.cerradohasta)).valueOf() > (new Date()).valueOf()?true:false;
let hasta = 'HASTA '+ (new Date(e?.cerradohasta)).toLocaleDateString("es-PE",{weekday: "short", day: "numeric"}).toUpperCase()
let fechaPanel = futuro?hasta:fecha;
return fechaPanel;
}



	
const agenda = <i className="pi pi-calendar icoBoton"  onClick={ e => dispatch({ type: types.contacto, payload: {...contacto, socio:{...contacto.socio, booking:'agenda'}} })} style={{color: '#03a9f4',verticalAlign:'text-top',float:'left',margin:'auto'}}></i>;
const booking = <Agenda contacto={contacto.socio} socio={contacto}/>
const className = classNames('p-grid', props.className);
const titulo = contacto.socio?.tipo >=3? contacto.socio.Nombre_Cat_Solo?props.categorias>1?contacto.socio.Nombre_Padre_solo+' '+ contacto.socio.Nombre_Cat.toLowerCase():contacto.socio.Nombre_Cat_Solo:contacto.socio.Nombre_Padre_solo+' '+ contacto.socio?.Nombre_Cat.toLowerCase():
				contacto.socio?.Nombre_Padre_Grupo+' '+ contacto.socio?.Nombre_Cat.toLowerCase();
//const pcol7 = classNames(props.lateral?'p-col-7':'p-col-9');
//const pcol2 = classNames(props.lateral?'p-col-2':'oculto');
return (
<div id='Socio' className={className} style={props.style}>
	<div className="p-col-3 col-foto">  
		<div className="alturaFoto">  
		<img className='fotoContacto panelM' alt="foto" src={contacto.socio?.Foto ? ruta + contacto.socio?.Foto : contacto.socio?.tipo === '3' ? ruta + img.fotoM : ruta + img.fotoF} style={{borderRadius:'0', background:contacto.socio?.color,padding:'0'}}/>   
		</div>
	</div>
	<div className='p-col-7 col-dato'> 
		<div className="p-grid box-dato">  
			<div className="p-col-12" style={{minHeight:window.innerWidth>'800'?'6em':'auto', fontSize:'14', padding:'10px 0 0 0', lineHeight:'1.5'}}><h2>{contacto.socio?.Nombre_Comercial}</h2> <Fragment>{titulo}<span className='mas'>{props.categorias>1?', '+(props.categorias - 1)+' especialidades más':''}</span></Fragment></div>
			<div className="p-col-12" > </div> 
		</div>
	</div>
	<div className="p-col-2 col-lado" style={{textAlign:'center', padding:'0', font:'1em / 1em fallback-font, Arial, sans-serif'}}>  
		<p style={{cursor:'pointer'}}><i className="pi pi-star"  style={{float:'left',color:'#03a9f4', margin:'-2px 3px 0 0'}}></i><span style={{fontWeight:'bold', float:'left', color:'#03a9f4'}}>{/*<Link to = {"/feedback/"+contacto.socio?.userweb+'/'}>*/contacto.socio?.alcance} </span>{'('+contacto.socio?.votos+' opiniones)'}</p>	
		<p style={{textAlign:'left', marginBottom:'6em'}}><i className="pi pi-video"  style={{float:'left',color:'#03a9f4',margin:'-1px 4px 0 0'}}></i>Consulta on-line</p>
		<div style={{height: '48px', boxShadow:'rgb(0 0 0 / 20%) 0px 1px 2px 0px'}}>
			<div style={{padding:'5px'}}>{agenda}{ Fecha(contacto.socio)}</div>
			<div style={{background:contacto.socio || futuro?.nodisponible?'#fffafa':'#f7fff2', padding:'5px',color:contacto.socio || futuro?.nodisponible?'red':'green'}}>{contacto.socio?.nodisponible || futuro?'Sin citas disponibles':'Citas disponibles'}</div>
		</div>		
	</div>	
	{props.booking||contacto.socio?.booking?booking:''}
	<div>
	<span className="presentacion" id="quilla1"></span>  
	</div>
</div>
);
	
	
	
}