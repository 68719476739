import { useContext, useState, useEffect, Fragment } from 'react';
import { Button } from "primereact/button";
import { StoreContext } from './store/StoreProvider';
import { Link } from 'react-router-dom'; 
import { RadioButton } from "primereact/radiobutton"; 
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Steps } from 'primereact/steps';
import { InfoData } from "./InfoData";
//import axios from "axios";
import classNames from 'classnames';


export const Billing = (props) => {

const [store/*, dispatch*/] = useContext(StoreContext);
const { contactosUser/*, user, contacto*/} = store;
let opt=true;


// Zona de carga automática

useEffect(() => {
  document.getElementsByClassName("layout-main")[0].style.padding='50px 16px 16px 0px';
  document.getElementById('Seeker').style.display='none';
  document.getElementById('superMenu').style.display='none';  
  if(window.innerWidth>'800'){
  document.getElementById('footer').style.position='relative';
  document.getElementById('footer').style.left='23.49em';
  document.getElementById('footer').style.width='74.6vw';
  }
}, []);



const editar = (contacto) => { 
//	dispatch({ type: types.contacto, payload: {...contacto, editar:'ok'} }) 
	window.scrollTo(0, 0);	
	contactosUser.map((i)=> document.getElementsByClassName("layout-Seeker")[i.item].style.display='none');
	document.getElementsByClassName("layout-Seeker")[contacto.item].style.display='block';
  }

 

  const contactosMenu=contactosUser.map((number) => <li className="menuContactos" onClick={() => editar(number)} key={number.item} style={{borderTop:number.item===0?'solid 1px #cfd3e099':'0' }}>
  {number.Nombre_Comercial}</li>);
  const contactosPanel=contactosUser.map((number) => 
  <div className="layout-Seeker" key={number.Contacto_ID} style={{display:'none'}}><li>
  <PanelCosto contacto={number}/>
  </li></div>
  );
  const menu=         <div className="layout-sidebar layout-sidebar-light" style={{width:'100%', position:'unset' }} >
  <div>
  <span className="menuContactosH">
  {contactosUser.length===1?'Administras '+contactosUser.length+' contacto':'Administras '+contactosUser.length+' contactos'}
  <i className="pi pi-chevron-down icoCombo"></i>
  </span>
  <ul >
  {contactosMenu}
  </ul> 				
  </div>  
  <div className="formulario">
    <div className="reg-content-wrapper single" style={{width:'auto'}}>
      <div className="join-form-container form-container">
      <fieldset style={{height:'100vh'}}> 
      </fieldset>
      </div>
    </div>
  </div>  
</div>;
  return (
  <div id="panelEdicion" className="p-grid p-fluid dashboard" style={{background:'#f9f7f6',minHeight:'100vh'}}> 
    <button onClick={()=>{document.getElementById("menuMovil").style.display=opt?'block':'none'; opt=!opt;}} type="button" className="p-link layout-menu-button movil" style={{display:'none', 'float':'right', padding:'0 0.3em', fontSize:'2em', margin:'0 0.4em',color:'#999', position:'relative', top:'1em' }}>
      <span className="pi pi-bars"/>
    </button>

  <Fragment> 
    <div className="p-col-3 escritorio">	
      <div style={{width:'23.8vw', position:'fixed'}}>
      {menu}
      </div>  
    </div>  		
    <div className="p-col-8 negocio">
      <div className="card contactoN" style={{width:'auto'}}>
        <ul>
          {contactosPanel}
        </ul> 
      </div>  
    </div>  
    <div className="p-col-1 escritorio">	
    </div> 
  </Fragment> 	

  <div id="menuMovil"  style={{display:'none'}} >
      {menu}
  </div> 

  </div>    
  ); 
	
}



export const PanelCosto = (props) => {

const [store/*, dispatch*/] = useContext(StoreContext);
const {user, ruta, img} = store;    
const [pago, setPago] = useState({etapa:'0'}); 
const [vicard, setVicard] = useState(''); 
const [decard, setDecard] = useState(''); 
const [ppcard, setPpcard] = useState(''); 
const [cucard, setCucard] = useState(''); 
const [tarjeta, setTarjeta] = useState({num:'', fecha:'', code:''}); 
const [credito, setCredito] = useState('$0'); 
const [pin, setPin] = useState(''); 
 


useEffect(() => {

}, []);


const email = async (Usu_ID) => {
//	let resource = await serverEmail(Usu_ID);
	let resource = await InfoData('visitaInfo', Usu_ID)
	let salida = resource.data.length===0?'':resource.data;
return salida
}	

/*	
const serverEmail = async (Usu_ID) => { var config = { headers: { 'content-type': 'multipart/form-data' }  };
    const formData = new FormData();
	formData.append('operacion', 15.3);
	formData.append('Usu_ID', Usu_ID);
    return await axios.post(api, formData, config);
}*/

const select = (caso) => {
	setVicard(''); setDecard(''); setPpcard(''); setCucard('');
      switch (caso) {
      case '1':
		setVicard('1');
		break;
		case '2':
        setDecard('1');
		break;
	  case '3':
		setPpcard('1');
		break;
	  case '4':
		setCucard('1');
		break;
    default: 
    console.log("No hay coincidencias");
    }
} 
  
	
	//--------------------------------
    // Zona de renderización final

    const link = props.router?<h1 className='h1Panel h1M' >{props.contacto.Nombre_Comercial}</h1>:
    <Link to= {"/"+props.contacto.userweb+'/'}><h1 className='h1Panel h1M' >{props.contacto.Nombre_Comercial}</h1></Link>
    const className = classNames('p-grid panelContacto', props.className);
    const titulo = 
    props.contacto.tipo >= 3 ? 
    props.contacto.Nombre_Cat_Solo ? 

    props.contacto.categorias > 1 ? 
    props.contacto.Nombre_Padre_solo + ' ' + props.contacto.Nombre_Cat?.toLowerCase() : 
    props.contacto.Nombre_Cat_Solo 
    : 
    props.contacto.Nombre_Padre_solo!==undefined?
    props.contacto.Nombre_Padre_solo + ' ' + props.contacto.Nombre_Cat?.toLowerCase():''    
    : 
    props.contacto.Nombre_Cat!==undefined? 
    props.contacto.Nombre_Padre_Grupo + ' ' + props.contacto.Nombre_Cat?.toLowerCase():'';
	const visa = <img alt="Visa" height='30' src={ruta+img.flag} className={`cc card-visa`}/>;
	const mc = <img alt="Mastercard" height='30' src={ruta+img.flag} className={`cc card-mc`}/>;
	const ae = <img alt="American Express" height='30' src={ruta+img.flag} className={`cc card-ae`}/>;
	const debito = <img alt="débito" height='30' src={ruta+img.flag} className={`cc card-deb`}/>;
	const paypal = <img alt="paypal" height='30' src={ruta+img.flag} className={`cc card-pp`}/>;
	const sc = <img alt="servicontacto" height='30' src={ruta+img.flag} className={`cc card-sc`}/>;
	const cupon = <div className="p-col-6"><p>Canjear las tarjetas en la sección de abajo.</p>
					  <div style={{width:'100%', marginBottom:'1em'}}>
					  <img alt="cupon" src={ruta+img.cupon}/>
					  </div>
					  <div className="p-grid">
						  <div style={{float:'left', width:'41%', paddingLeft:'0.6em'}}>
						  <label className="float-label" htmlFor="inpin" style={{/*clear:'both'*/}}>Ingresar PIN</label>
						  <InputText id="inpin" value={pin} onChange={(e) => setPin(e.target.value)} />
						  </div>
						  <div style={{float:'right', top:'0.2em', position:'relative'}}>
						  <Button  className="usuBoton" label="Canjear" onClick={() => setCredito('$14')} disabled={pin==='1415926535'?false:true}/>
						  </div>
					  </div>
					  <table style={{borderCollapse:'collapse', width:'90%', marginTop:'1.5em'}}>
					  <tbody>
					  <tr><td>Crédito de Supercontacto</td><td>{credito}</td> </tr>
					  <tr><td>Un mes de servicio  </td><td> $14</td> </tr>
					  </tbody> 
					  <tfoot style={{borderTop:'1px solid #c8c8c8'}}>
					  <tr><th>Saldo</th><th> {credito==='$0'?'$14':'$0'}</th></tr>
					  </tfoot>			  
					  </table> 
					  <Button  className="usuBoton" label="Enviar pedido" onClick={() => setPago({etapa:'3'})} style={{width:'10em'}} disabled={pin==='1415926535'?false:true}/>
					  <Button  className="usuBoton" label="Cancelar" onClick={() => setPago({etapa:'1'})}/>
					  </div>;
	const payp = <div className="p-col-6"><p>Pago a través de Paypal</p>
					  <div style={{width:'100%', marginBottom:'1em'}}>
					  
					  </div>

					  <Button  icon="pi pi-paypal"  iconPos="right" label="Pagar con Paypal" onClick={() => setPago({etapa:'3'})} style={{width:'12em', margin:'1em', padding:'0.7em 1em', background:'#26a1d9',color:'#ffffff', border:'#26a1d9', borderRadius:'5px'}}/>
					  <Button  className="usuBoton" label="Cancelar" onClick={() => setPago({etapa:'1'})}/>
					  <p> Vas a completar la transacción en PayPal</p>
					  </div>;
	const card = <div className="p-col-6"><p>Tarjeta de crédito</p><p style={{fontSize:'12px'}}><i className="fa fa-lock" style={{marginRight:'6px'}}></i>Tu pago es seguro. Los datos de tu tarjeta no se compartirán</p>
				  <div style={{width:'100%', marginBottom:'1em'}}>
				  <label className="float-label" htmlFor="inCard" >Número de la tarjeta</label>
				  <span className="p-input-icon-left"><i className="fa fa-credit-card" style={{left:'0.5em'}}/>
				  <InputText id="inCard" value={tarjeta.num} onChange={(e) => setTarjeta({...tarjeta, num:e.target.value})} style={{paddingLeft:'2.5em'}}/></span>
				  <small id="inCard" className="p-error p-d-block" style={{color:'red'}}>{false?'Error: ingresa el número de tu tarjeta':''}</small>
				  </div>
				  <div className="p-grid" style={{margin:'0'}}>
					  <div style={{float:'left', width:'48%', marginRight:'2%', marginBottom:'0.7em'}}>
						  <label className="float-label" htmlFor="inFecha" >Fecha de vencimiento</label>
						  <InputMask id="inFecha" placeholder="MM/AA" mask="99/99" slotChar="MM/AA" value={tarjeta.fecha} onChange={(e) => setTarjeta({...tarjeta, fecha:e.target.value})} />
						  <small id="inFecha" className="p-error p-d-block" style={{color:'red'}}>{false?'Error: ingresa la fecha de vencimiento de tu tarjeta':''}</small>
					  </div>
					  <div style={{float:'right', width:'48%', marginLeft:'2%'}}>
						  <label className="float-label" htmlFor="inCode" >Código de seguridad</label>
						  <span className="p-input-icon-right"><i className="far fa-question-circle" style={{right:'5%', cursor:'pointer'}}/>
						  <InputText id="inCode" value={tarjeta.code} onChange={(e) => setTarjeta({...tarjeta, code:e.target.value})} /></span>
						  <small id="inCode" className="p-error p-d-block" style={{color:'red'}}>{false?'Error: ingresa el código de seguridad de tu tarjeta':''}</small>
					  </div>
					  <div style={{float:'left', width:'48%', marginRight:'2%'}}>
						  <label className="float-label" htmlFor="inName" >Nombre</label>
						  <InputText id="inName" value={tarjeta.nombre} onChange={(e) => setTarjeta({...tarjeta, nombre:e.target.value})} />
						  <small id="inName" className="p-error p-d-block" style={{color:'red'}}>{false?'Error: ingresa tu nombre en tu tarjeta':''}</small>
					  </div>
					  <div style={{float:'right', width:'48%', marginLeft:'2%'}}>
						  <label className="float-label" htmlFor="inApe" >Apellidos</label>
						  <InputText id="inApe" value={tarjeta.apellido} onChange={(e) => setTarjeta({...tarjeta, apellido:e.target.value})} />
						  <small id="inApe" className="p-error p-d-block" style={{color:'red'}}>{false?'Error: ingresa tu apellido en tu tarjeta':''}</small>
					  </div>
				  </div>
				  <p style={{fontWeight:'bold'}}>
					Datos de facturación
				  </p> 
				  <p> {user.Nombres+' '+user.ApellidoPaterno+' '+user.ApellidoMaterno+', Distrito de '+user.Distrito+', departamento de '+user.Departamento+' - '+user.Pais}
				  </p> 
				  <Button  className="usuBoton" label="Realizar pago" onClick={() => setPago({etapa:'3'})} style={{width:'10em'}} disabled={tarjeta.num&&tarjeta.code&&tarjeta.fecha?false:true}/>
				  <Button  className="usuBoton" label="Cancelar" onClick={() => setPago({etapa:'1'})}/>
				  </div>;			   
	const confirma = <div className="p-col-6">Confirmación de pago:
					<h1>Su pedido ha sido realizado</h1>
					<p>Ahora tienes un mes de servicio activado</p>
			<Button  className="usuBoton" label="Aceptar" onClick={() => {setPago({etapa:'0'}); email('1') } } style={{width:'10em'}}/>
			</div>;
	const items = [{label:'Selección'},{label:'Proceso' },{label: 'Confirmación'}];
	const seleccion = <div className="p-col-6">Seleccionar el tipo de pago:
				<ul style={{lineHeight:'3', margin:'1em 0'}}>
					<li><RadioButton value='1' onChange={(e) => select(e.value)} checked={vicard==='1'}/>{visa}{mc}{ae}Tarjeta de crédito</li>
					<li><RadioButton value='2' onChange={(e) => select(e.value)} checked={decard==='1'}/>{debito}Tarjeta de dédito</li>
					<li><RadioButton value='3' onChange={(e) => select(e.value)} checked={ppcard==='1'}/>{paypal}Paypal</li>
					<li><RadioButton value='4' onChange={(e) => select(e.value)} checked={cucard==='1'}/>{sc}Cupon de Servicontacto</li>
				</ul>
			<Button  className="usuBoton" label="Continuar" onClick={() => setPago({etapa:'2'})} disabled={[vicard, decard, ppcard, cucard].includes('1')?false:true} style={{width:'10em'}}/>
			<Button  className="usuBoton" label="Cancelar" onClick={() => setPago({etapa:'0'})}/>
			</div>;			
    return (
    <Fragment>
        {pago.etapa==='0'?<div className={className} style={props.style}>
		  <div className="p-col-10" style={{/*height:'5em'*/}}>{link}<Fragment>{titulo}</Fragment>
		  </div>
		  <div className="p-col-2" ><p>Cupon de Servicontacto</p><p>PIN 1415926535</p>Saldo a favor: $14
		  </div>
		  <div className="p-col-12" >
			  <table style={{borderCollapse:'collapse', border:'1px solid #c8c8c8'}}>
				  <thead style={{borderBottom:'1px solid #c8c8c8'}}>
				  <tr><th style={{width:'25em'}}>Detalle de servicio</th><th>costo</th></tr>
				  </thead>
				  <tbody>
				  <tr><td>Publicación de sitio web en Servicontacto </td><td> $7</td> </tr>
				  <tr><td>Servicio de hosting en nuestro servidor</td><td> $7</td> </tr>
				  </tbody> 
				  <tfoot style={{borderTop:'1px solid #c8c8c8'}}>
				  <tr><th>Monto facturado</th><th> $14 </th></tr>
				  </tfoot>			  
			  </table>   
			  <p>Monto a pagar a Servicontacto: $14 </p>
		  </div> 
		  <div className="p-col-12" >
		  <Button  className="usuBoton" label="Relizar el pago" onClick={() => setPago({etapa:'1'})} style={{width:'10em'}}/>
		  </div> 
        </div>:
		<div className={className} style={props.style}>
		    <div className="p-col-12"><h1 className='h1Panel h1M' style={{textAlign:'center'}}>Pago seguro con Servicontacto</h1>
				<Steps model={items} activeIndex={pago.etapa - '1'} style={{width:'45%', margin:'2em auto'}}/>
			</div>
			<div className="p-col-6">
			<h1 className='h1Panel h1M'>{props.contacto.Nombre_Comercial}</h1>
			<Fragment>{titulo}</Fragment>
			<p>Saldo pendiente: $14</p>
			</div>
			{pago.etapa==='1'?seleccion:
			 pago.etapa==='2'?
			 (vicard?card:
			 decard?card:
			 ppcard?payp:
			 cucard?cupon:''):
			 pago.etapa==='3'?confirma:''}
		</div>}
    </Fragment> 
      );


}
