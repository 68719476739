import React, { useContext, useState, Fragment } from 'react';
import {Link, useHistory/*, useLocation*/} from 'react-router-dom'
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from "primereact/checkbox";
import {Button} from "primereact/button";
import {StoreContext} from './store/StoreProvider.js';
import {types}  from './store/StoreReducer.js';
import { InfoData } from "./InfoData";


export const AppSeeker = (props) => {

//const menu = document.getElementById('superMenu'); 
const [store, dispatch] = useContext(StoreContext);
const {categoria, zona, ruta, img, provincias} = store;
const [categoriaAC, setCategoriaAC] = useState([]); 
const [categoriasf, setCategoriasf] = useState([{label: "", value: ""}]); 
const [seleccion, setSeleccion] = useState(false); 
const [checked, setChecked] = useState(false); 
//const location = useLocation();
const history = useHistory();
const icono = ruta+img.icono;
const query = new URLSearchParams(''/*location.search*/);
 

/*
useEffect(() => {
 console.log(categoria);
document.getElementsByClassName('p-autocomplete-input')[0].style.height='100%';
document.getElementsByClassName('p-c p-button-icon-left')[0].style.margin='0px 3px 0px 6px';
document.getElementsByClassName('p-c p-button-icon-left')[0].style.fontSize='1.5em';
//eslint-disable-next-line
}, []);*/

const cambio = (busca) => {
  document.getElementsByClassName('buscarDistrito')[0].classList.toggle('oculto');
  dispatch({type:types.ubicacion, payload:[]});
  if(busca){document.getElementsByClassName('menuHome')[2].click()}
  }
  
  
const Menu = () => {
  let menu=document.getElementById('superMenu'); 
//  let foto=document.getElementById('galeria'); 
        if (menu.style.display === 'none') {
//            menu.style.display = 'block'; 
			cierraPops(1);
			window.scrollTo(0, 0);
        //    if(foto!==null)foto.style.bottom = '-31.82em'
			cierraPops(1);
        } else {
//            menu.style.display = 'none';
        //    if(foto!==null)foto.style.bottom = '-5.7em'
			cierraPops(0);			
        }
//      document.getElementsByClassName("layout-main")[0].style.padding = '106px 0px 16px 0px';
  }
  
  
    const cierraPops = (caso) => {
	if(caso!==3){document.getElementById('buscador').classList.add('oculto');}
	if(caso!==2){document.getElementById('seleccionZona').classList.add('oculto')}
	document.getElementById('superMenu').style.display = caso===1?'block':'none';
	}
  
  
const tecla =(event) => {
	if(event.key === 'Enter')
	{ 
	history.push({pathname:''}); 
	Buscar(); 
	let a = categoria.barra.split('=');
	let b = zona.query.split('=');
	query.set(a[0], a[1] );
	query.set(b[0].replace("&","").toString(), b[1]);
	history.push({pathname:zona.parametro + categoria.parametro, search:query.toString()}); Buscar();  
	}
  } 


const Buscar = () => {
  document.getElementById('superMenu').style.display = 'none';
  window.scrollTo(0, 0);
//  document.getElementById('busqueda').value='true';
  setCategoriaAC('');
  dispatch({ type: types.contactos, payload: [{Contacto_ID:'0'}] });
  }



const categoriaACChange = (e) => {
setCategoriaAC(e.value);
let cat = e.value.abuelo;
  if(typeof cat!=='undefined'){
//reconocer(e.value);  
Parametros(e.value);    
  }
if (document.getElementById('superMenu').style.display === 'block'){
cierraPops(1);
}
} 

/*
const reconocer = (dato) => {

let gen = dato.abueloID === dato.padreID? 3 : dato.abueloID ==='0'? 2 : 1;
let label = 'todas-las-categorias';
let etiqueta = 'todo '+dato.value;
let categoria = dato.value;

if(gen===3){ Parametros( { abueloID:dato.Arbol_ID, abuelo:categoria, padreID:'', padre:etiqueta, Arbol_ID:'', Arbol:label } ) }
else {  
if(gen===2){ Parametros( { abueloID:dato.padreID, abuelo:padre, padreID:dato.Arbol_ID, padre:categoria, Arbol_ID:'', Arbol:label } ) }
Parametros(dato);
}

}
*/


const Parametros = (categoria) => {
  let data={
   abueloID : categoria.abueloID,
   abuelo : categoria.abuelo,
   padreID : categoria.padreID,
   padre : categoria.padre,
   Arbol_ID : categoria.Arbol_ID,
   Arbol : categoria.categoria,  
};
let query={
   abueloID : categoria.abueloID==='0'?categoria.padreID==='0'?categoria.Arbol_ID:'':'',
   padreID : categoria.abueloID==='0'?categoria.padreID!=='0'?categoria.Arbol_ID:'':'', 
   Arbol_ID : categoria.abueloID!=='0'?categoria.Arbol_ID:'',
};
let barra=query.Arbol_ID?'c='+query.Arbol_ID:query.padreID?'f='+query.padreID:'a='+query.abueloID;
let Categoria= categoria.abueloID!=='0'?categoria.abuelo:categoria.padreID!=='0'?categoria.padre:categoria.categoria;
let Etiqueta= categoria.abueloID!=='0'?categoria.padre:categoria.padreID!=='0'?categoria.categoria:'todos los '+categoria.categoria.toLowerCase();
let Label= categoria.abueloID!=='0'?categoria.categoria:categoria.padreID!=='0'?'todos los '+categoria.categoria.toLowerCase():'todas-las-categorias';
let v='/'
let parametro=Categoria+v+Etiqueta+v+Label;
parametro=parametro.split(' ').join('-');
dispatch({type:types.categoria, payload:{data,query,parametro, barra}});
return {data,parametro};
}


const filtrocategoriasAC = async (event) => {   
  var resource = [];
  var ubica = [];
   if(event.query.length >= 1){resource = await InfoData('categoria', {nombre:event.query.toLowerCase(), catID:''}); 
   console.log(resource.data);  ubica = resource.data;}; 
    setTimeout(() => {
    var results = ubica.filter((categoriaAC) => {
    return categoriaAC.label.toLowerCase();
    });
    setCategoriasf(results);
        }, 250);
    }


/*
const filtrocategoriasAC = async (event) => {   
  var resource = [];
  var ubica = [];
   if(event.query.length >= 1){resource = await serverCetegoriasAuto(event.query.toLowerCase(),''); console.log(resource.data);  ubica = resource.data;}; 
    setTimeout(() => {
    var results = ubica.filter((categoriaAC) => {
    return categoriaAC.label.toLowerCase();
    });
    setCategoriasf(results);
        }, 250);
    }

  
const serverCetegoriasAuto = async (nombre,catID) => {  
    var config = { headers: {  'content-type': 'multipart/form-data' }  };

    const formData = new FormData();
  formData.append('operacion', 10.2);
  formData.append('nombre', nombre);
  formData.append('catID', catID);
    return await axios.post(api, formData, config);
  } 
*/





  /*Zona de renderización*/


  
const barra = <Button className="botonCategoriaM movil" style={{color:'#006aa7'}} icon={seleccion?"pi pi-times":"pi pi-bars"} onClick={(event) => setSeleccion(!seleccion)} />;
const categ = <Button className="botonCategoriaM movil" label="Buscar por categorías" style={{padding:'1px 1px 1px 5px'}} icon="pi pi-sitemap" onClick={() => {Menu(); setSeleccion(!seleccion)}} />;
const ubica = <Button className="botonCategoriaM movil" label="Selecciona ubicación" style={{padding:'1px 1px 1px 5px'}} icon="fas fa-map-marker-alt" onClick={() => {document.getElementById('seleccionZona').classList.toggle('oculto'); setSeleccion(!seleccion); cierraPops(2);}} />;
const busca = <div className="buscaDistrito movil" onClick={() => {cambio(true); /*setSeleccion(!seleccion);*/ cierraPops(3);}}>
				  <img alt='' width='24' height="24" src={icono} style={{transform:'translate(8px, 35%)'}}/>
				  <div style={{width:'calc(87.8% - 11px)', transform:'translate(0, 0%)', color:'#006aa7', fontSize:'15px', fontWeight:'bold', float:'right', padding:'14px 3px'}}>Busca tu distrito en el mundo</div>
			  </div>;
const dispro = zona.data?.Departamento?(zona.data?.Provincia?zona.data.Provincia:(zona.data?.Distrito?zona.data.Distrito:'todo '+zona.data?.Departamento)):'';
const ciudad = zona.data?.Departamento?zona.data?.Departamento:'todo '+zona.data?.Pais;
const i = <i className='pi pi-chevron-right' style={{margin:'0 7px', fontSize:'9px'}}/>;
const l = (zona.data?.Pais+ciudad+dispro).length
const donde = <div className="movil" style={{color:'#006aa7'}}>
				<h3 style={{margin:'1px 0 7px', whiteSpace:'nowrap'}}>Zona de búsqueda<span style={{fontWeight:'100', fontSize:'14px'}}>{l>37?' ('+zona.data?.Pais+')':''}</span> </h3>
				<span onClick={() => {document.getElementById('seleccionZona').classList.remove('oculto'); cierraPops(2)}} style={{whiteSpace:'nowrap'}}> {l<38?zona.data?.Pais:''}{l<38?i:''}{ciudad}{dispro?i:''}{dispro}</span> 
			  </div>;	

  
  return  (
    <div className="Seeker" id="Seeker" style={{...props.style}}>
          <div className="p-grid barrabuscador" style={{backgroundColor:'white'}}>
              <div className="p-col-12 p-md-8 alineaMovil" style={{display:'flex', margin:'auto'}}>  
                  <Button className="botonCategoria escritorio" style={{width:'98px', minWidth:'98px', height:'42px', borderRadius:'5px', margin:'0 10px 0 0', float:'left'}} label="Buscar por categoría" icon="pi pi-sitemap" onClick={(event) => Menu()} />
				  {seleccion?
					<div className="p-grid movil">
						<div className="p-col-12 p-md-2" style={{width:'64px'}}>{barra}</div>
						<div className="p-col-12 p-md-5" style={{width:'calc(50% - 32px)'}}>{categ}</div>	
						<div className="p-col-12 p-md-5" style={{width:'calc(50% - 32px)'}}>{ubica}</div>					
					</div>:
					<div className="p-grid movil">
						<div className="p-col-12 p-md-3" style={{width:'64px'}}>{barra}</div>
						<div className="p-col-12 p-md-9" style={{width:'calc(100% - 64px)'}}>{donde}</div>								
					</div>}
  
                  <div className="p-inputgroup buscador" style={{width:'100%', height:'44px'}}>
                    <img className="icoBoton escritorio" onClick={() => cambio()} alt='' width='24' height="24" src={icono}/>
                    <Dropdown className="escritorio" style={{'display': props.departamento ? 'none' : 'flex'}} value={props.departamento} options={props.ciudades} onChange={props.onCiudadChange} placeholder={"Todo "+ props.pais}/>
                    <Dropdown className="escritorio" style={{'display': props.departamento ? 'flex' : 'none'}} value={props.provincia} options={props.provincias} onChange={props.onProvinciaChange} placeholder={"Todo "+ props.dpto}/>
                    <AutoComplete style={{width:'40%'}} id="inbuscador" onKeyPress={(e) => tecla(e)} value={categoriaAC} suggestions={categoriasf} completeMethod={filtrocategoriasAC} field="label" size={30} placeholder="Servicio o producto que buscas" minLength={1} onChange={categoriaACChange} />
                    <Link to={zona.parametro + categoria.parametro+'?'+categoria.barra+zona.query}>
                    <Button icon="pi pi-search" onClick={() => Buscar()} style={{color:'#fff', fontWeight:'bold'}}/></Link>
                  </div> 
              </div> 
          </div>
		  <div id='seleccionZona' className="reg-content-wrapper oculto" style={{padding:'2em', color:'#006aa7', background:'#f9fbfd', display:'inline-block', boxShadow:'rgb(0 0 0 / 35%) 0px 1px 2px 0px', top:'1px', width:'100%'}}>
			  <i className='pi pi-times cerrar' onClick={() => document.getElementById('seleccionZona').classList.add('oculto')}/>
			  <h1>Selección de ubicación</h1>
			  <label className="float-label" htmlFor="inubicacion">{'Selecciona región o departamento de '+zona.data?.Pais}</label>	  
					  <Dropdown className="movil" style={{'display': 'inline-flex', marginBottom:'10px', width:'100%'}} value={props.departamento} options={props.ciudades} onChange={props.onCiudadChange} placeholder={"Todo "+ props.pais}/>
			  <label className="float-label" htmlFor="inubicacion">{"Selecciona "}{provincias?.length === 0 ?'distrito':'provincia'}{' del departamento de '+zona.data?.Departamento}</label>	
					  <Dropdown className="movil" style={{'display':props.departamento ? 'inline-flex':'none', float:'right', width:'100%', marginBottom:'20px'}} value={props.provincia} options={props.provincias} onChange={props.onProvinciaChange} placeholder={"Todo "+ props.dpto}/>
			  {zona.data?.Pais_ID==='89'?<Fragment><Checkbox inputId="binary" checked={checked}  onChange={(e) => {setChecked(e.checked); props.expandir(e.checked?1:2)}}/>
			  <span htmlFor="binary" style={{ verticalAlign: "middle", marginLeft: "0.7em" }}>
			  {'Sekección por distritos de '+zona.data?.Departamento}</span></Fragment>:''}
			  <div style={{marginTop:'1em'}}>{busca}</div>
		  </div>

    </div>
    );


    }


