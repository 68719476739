import React, {Component, useState} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {Panel} from './Panel.js';
import { useContext, useEffect } from 'react';
import {StoreContext} from './store/StoreProvider.js';
import {types}  from './store/StoreReducer.js';
import { Paginator } from 'primereact/paginator';
import { InfoData } from "./InfoData";


export const Listado = (props) => {

const [store, dispatch] = useContext(StoreContext);
const {contactos, cuenta} = store;
const [basicFirst, setBasicFirst] = useState(0);
const [basicRows, setBasicRows] = useState(10);
const history = useHistory();
const bar = props.match.params;
const pais = bar.pais.split('-').join(' ');
const dpto = bar.departamento.split('-').join(' ');
const distProv = bar.distProv.split('-').join(' ');
const Categoria = bar.categoria.split('-').join(' ');
const SubCategoria = bar.SubCategoria.split('-').join(' ');
const hoja = bar.hoja.split('-').join(' '); 
const lateral = window.innerWidth>'800'?true:false;
const location = useLocation();
const query = new URLSearchParams(location.search);
const Cat_ID = query.get("c") || '';
const Padre = query.get("f") || '';  
const Abuelo = query.get("a") || '';
const pais_ID = query.get("P") || '';
const departamento_ID = query.get("D") || ''; 
const provincia_ID = query.get("p") || ''; 
const distrito_ID = query.get("d") || '';
const skip = query.get("s") || 0; 
const limit = query.get("l") || 10;
var parametros = '/'+dpto+'/'+distProv+'/';
parametros = parametros.split(' ').join('-');


useEffect(() => {
  if(contactos[0]?.Contacto_ID==='0'){
  listaContactos(Cat_ID,Padre,Abuelo,departamento_ID,pais_ID,provincia_ID,distrito_ID,skip,limit);  
  cierraPops();
  }
  document.getElementById('footer').style.position='relative';
  //eslint-disable-next-line
}, [contactos[0]?.Contacto_ID==='0']);


    const cierraPops = () => {
	document.getElementById('buscador').classList.add('oculto');	
	document.getElementById('seleccionZona').classList.add('oculto');
	document.getElementById('superMenu').style.display = 'none';
	}


    const onBasicPageChange = (event) => {
	
	if(cuenta >= event.first)
		{
        setBasicFirst(event.first);
        setBasicRows(event.rows);	
		console.log(event.first);  //skip
		console.log(event.rows);   //limit	
		query.set("s",event.first);
		query.set("l",event.rows);
		history.push({search:query.toString()});
		window.scrollTo({top: 0, behavior:'smooth'});
		dispatch({ type: types.contactos, payload: [{Contacto_ID:'0'}] });
		}
		
    }

	
const listaContactos = async (Cat_ID,padre,abuelo,departamento,pais,provincia,distrito,skip,limit) => {
    let resource = await InfoData( types.contactos, {Cat_ID:Cat_ID, padre:padre, abuelo:abuelo, departamento:departamento, pais:pais, provincia:provincia, distrito:distrito, skip:skip, limit:limit});
	console.log(resource.data);	
	if(resource.data.length!==0){
	let conteo = resource.data.pop();
	dispatch({ type: types.cuenta, payload: conteo });}
	dispatch({ type: types.contactos, payload: resource.data || [] });
  }	
	
  
  	const rutear = (contacto) => {
	dispatch({ type: types.contacto, payload: {...contacto, load:'porListado'} });
	history.push({pathname:'/contacto/'+contacto.Nombre_Comercial?.split(' ').join('-') +'/'+ contacto.userweb});
	}
  

  const i = <i className='pi pi-chevron-right' style={{margin:'0 7px', fontSize:'9px'}}/>
  return (
      <div className="contactoN">
        <div className="rutawebM movil" style={{paddingTop:'4em', marginBottom:'0'}}>{Categoria}{i}{SubCategoria}{i}{hoja}</div>
        <div className="rutaweb escritorio" style={{paddingTop:'0.5em'}}>{pais}{i}{dpto}{i}{distProv}{i}{Categoria}{i}{SubCategoria}{i}{hoja}</div>
        <div className="rutawebM movil" style={{padding:'0 0 0.5em', 'visibility':contactos[0]?.Contacto_ID==='0'?'hidden':'visible'}}>{cuenta+" resultados encontrados"}</div>
        <div className="rutaweb escritorio" style={{'visibility':contactos[0]?.Contacto_ID==='0'?'hidden':'visible'}}>{cuenta+" resultados encontrados"}</div>
        <Lista contacto={contactos} parametros={parametros} lateral={lateral} rutear={rutear}/>
		<Paginator style={{'visibility':cuenta<10?'hidden':'visible', marginBottom:cuenta<10?'0':'1.5em'}} first={basicFirst} rows={basicRows} totalRecords={120} rowsPerPageOptions={[10, 20, 30]} onPageChange={onBasicPageChange}></Paginator>
      </div>    
    );

  }



class Lista extends Component {
static propTypes = { rutear: PropTypes.func };

render() {
	const barras=<div id="initialLoadingContainer" className="loader-icon-container" style={{ minHeight:'calc(100vh - 377px)', position:'relative'}}>
		<div className="loader is-active centrar-bars">
			<div className="loader-bars"></div>
		</div>
	</div>;
	const contactosPanel=this.props.contacto?.map((number) => 
	<div className="layout-Seeker"  key={number.Contacto_ID} style={{marginBottom:window.innerWidth>'800'?'auto':'1.2rem' }}><li>
	<Panel contacto={number} parametros={this.props.parametros} lateral={this.props.lateral} elegir={this.props.rutear}/>
	</li></div>);
	return (
	<ul className="listamh">
	{this.props.contacto[0]?.Contacto_ID!=='0'?contactosPanel:barras}
	</ul>
); 
   }
}
