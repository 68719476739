import React, { useContext, useState, useEffect } from "react";
import {StoreContext} from './store/StoreProvider.js';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import FullCalendar from '@fullcalendar/react';
import { InfoData } from "./InfoData";


var calendarioGlobal = 0;

export const Notificacion = (props) => {
const [store] = useContext(StoreContext);
const {user, contactosUser, alertas} = store;


useEffect(() => {
  if(alertas.length !== 0){crearCalendario();
  document.getElementsByClassName('fc-toolbar fc-header-toolbar')[0].style.display='none';
  calendarioGlobal.addEventSource(alertas);
  document.getElementsByClassName('fc-scroller')[0].style.height='auto';}
  //eslint-disable-next-line
  }, []);


const crearCalendario = () => {
	var calendarE1 = document.getElementById('calendario');
 	calendarioGlobal = new FullCalendar.Calendar(calendarE1, {
		plugins:['list'],
		defaultView: 'listWeek',
		defaultDate: new Date(), 
 		editable: false,
	});
	calendarioGlobal.setOption('locale', 'es');
	calendarioGlobal.render(); 
}


const misContactos=contactosUser.map((number) => 
<li className="layout-Seeker" key={number.Contacto_ID}>
<ContactoNotif contacto={number}/>
</li>
);
return (
<div className="marco">
  <div className='negocio contactoN' style={{ minHeight:'80vh', position:'relative'}}>
  <h1>Notificaciones</h1>
	{alertas.length!==0? <div className="acerca"> 
	  <h3>{'Citas agendadas para '+user.Nombres }</h3>
	  <div id="calendario" style={{width:'100%', minHeight: '200px'}}>
	  </div>
	</div> :''}
	<ul>
	  {misContactos}
	</ul> 
  </div>
</div>
);

}



export const ContactoNotif = (props) => {

const [agenda, setAgenda] = useState([]); 


useEffect(() => {
obtenerEventos(props.contacto.Contacto_ID);
//eslint-disable-next-line
}, []);

const obtenerEventos = async (Contacto_ID) => {
	let resource = InfoData('eventos', Contacto_ID);
	setAgenda(resource.data);
}	

  
 const Fecha = (rowData, column) => {
	let dia = (new Date(rowData.start)).toLocaleDateString("es-PE", {month:"long", weekday: "long", day: "numeric", year:"numeric" });
	let hora = (new Date(rowData.start)).toTimeString().substring(0,5) +' - '+(new Date(rowData.end)).toTimeString().substring(0,5)
	return React.createElement("div", { onClick: () => alert('Hola')}, React.createElement("span", null, dia +' de '+ hora));
	}
  

  return (
     <div className='p-grid panelContacto'  style={{display:agenda?.length !==0 ?'block':'none'}} >
      <div className="acerca"> 
        <h3>{'Citas agendadas para '+props.contacto.Nombre_Comercial }</h3>
           <DataTable className="Roster" value={agenda} editable={true} >
            <Column field="start" header="Fecha" style={{width:'25%',textAlign:'center'}} />
            <Column body={ Fecha } header="Fecha" style={{width:'25%',textAlign:'center'}} />
            <Column field="title" header="Evento" />
          </DataTable>  
      </div>
    </div>
    );

}