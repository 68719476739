import React, { useContext, useEffect } from 'react';
import { StoreContext } from '../store/StoreProvider';


export const About = (props) => {

 const [store] = useContext(StoreContext);
 const {ruta, img} = store;


useEffect(() => {
//document.getElementById('Seeker').style.display = "none";
document.getElementById('superMenu').style.display = "none";
//document.getElementsByClassName("layout-main")[0].style.padding = '50px 0px 0px 0px';
}, []);


  return (
	<div className="marco" style={{background:'#ffffff'}}>
		<div className="component component-banner component-banner--banner" data-component-type="banner">
			<div className="banner banner--v3 banner--full-width banner--light-background banner--center banner--visible" data-banner="">
			  <div className="banner__headline-container">
				<div className="banner__position" style={{paddingTop:window.innerWidth>'800'?'10em':'5em', width:window.innerWidth>'800'?'50%':'90%', margin:'auto', textAlign:'center'}}>
					<h1 className="banner__headline t-64" style={{fontSize:window.innerWidth>'800'?'3em':'2em'}}>Nuestra misión es de organizar los servicios profesionales en el mundo y hacer que sea útil y accesible para todos.</h1>
					<p className="banner__subheadline t-20"></p>
					<div className="banner__button-container">
					</div>
				</div>
			  </div>
			</div>  
		</div>
		<div className="component component-richText component-richText--richText" data-component-type="richText">
			<ol className="list list--article-width list--none">
				<li id="int" className="list__item list__item--hairline t-16px" data-component-reorder="false" data-component-list-container="false">
				<div className="rich-text global-paragraph-style--p1" style={{paddingTop:'2em', margin:'auto', textAlign:'center'}}>
					<div data-global-paragraph-value="p1"> 
                    <img src={ruta + img.portada} alt="servicios profesionales" className="zoom-in" style={{borderRadius:'0.5em', width:'90%'}}/>
					</div>
				</div>  
				</li>
				<li id="int" className="list__item list__item--hairline t-16px" data-component-reorder="false" data-component-list-container="false">
				<div className="banner__position" style={{paddingTop:'4em', width:window.innerWidth>'800'?'70%':'90%', margin:'auto', textAlign:'center'}}>
					<h1 className="banner__headline t-64" style={{fontSize:window.innerWidth>'800'?'2.5em':'2em'}}>Trabajamos para ser una herramienta que haga de todas las personas más productivas con la ayuda de ellas mismas.</h1>
					<p className="banner__subheadline t-20"></p>
					<div className="banner__button-container">
					</div>
				</div>
				</li>					
				<li id="int" className="list__item list__item--hairline t-16px" data-component-reorder="false" data-component-list-container="false">
				<div className="rich-text global-paragraph-style--p1" style={{paddingTop:'1em', margin:'auto', textAlign:'center'}}>
					<div data-global-paragraph-value="p1"> 
                    <img src={ruta + img.portada1} alt="servicios profesionales" className="zoom-in" style={{borderRadius:'0.5em', width:'90%'}}/>
					</div>
				</div>  
				</li>	
				<li id="int" className="list__item list__item--hairline t-16px" data-component-reorder="false" data-component-list-container="false">
				<div className="banner__position" style={{paddingTop:'3em', width:window.innerWidth>'800'?'50%':'85%', margin:'auto', textAlign:'center'}}>
					<h1 className="banner__headline t-64" style={{fontSize:'2em'}}>Estamos trabajando para que la exploración de mejores servicios sea desde una computadora en lugar de visitas en persona antes de decidir por la contratación de un servicio.</h1>
					<p className="banner__subheadline t-20"></p>
					<div className="banner__button-container">
					</div>
				</div>
				</li>				
			</ol> 
				<div className="p-grid" style={{paddingTop:'3em'}}>			
					<div className="banner__position" style={{float:'left', width:window.innerWidth>'800'?'50%':'100%', padding:window.innerWidth>'800'?'1em 5.5em':'2em'}}>
						<h1 className="banner__headline t-64" style={{fontSize:'2em'}}>
						Prensa</h1>
						<h2 style={{fontSize:'18px',marginTop:'1em', color:'#909090'}}>Si eres miembro de prensa, envíanos un correo electrónico a press@servicontacto.com</h2>
					</div>
					<div className="banner__position" style={{float:'right', width:window.innerWidth>'800'?'50%':'100%', padding:window.innerWidth>'800'?'1em 5.5em':'2em'}}>
						<h1 className="banner__headline t-64" style={{fontSize:'2em'}}>
						Oficinas centrales de Servicontacto</h1>
						<h2 style={{fontSize:'18px',marginTop:'1em', color:'#909090'}}>Trädgårdsgatan 55 Mölndal, Gotemburgo - Suecia</h2>
					</div>					
				</div>		
				<div className="p-grid" style={{paddingTop:'3em'}}>			
					<div className="banner__position" style={{padding:'1em 5.5em'}}>
						<span style={{fontSize:'18px',marginTop:'1em', color:'#909090'}}>Siguenos</span>
						
					</div>
				</div>						
		</div>   
	</div>   	
    );
	
	
	
	
	
  }