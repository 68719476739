import { useContext, useState, useEffect, Fragment } from 'react';
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from "primereact/dialog";
import { AutoComplete } from 'primereact/autocomplete';
import { InputTextarea } from 'primereact/inputtextarea';
import { ListBox } from 'primereact/listbox';
import { Slider } from 'primereact/slider';
import { StoreContext } from './store/StoreProvider';
import { types }  from './store/StoreReducer';
import { InfoData } from "./InfoData";
import { Superpanel } from './Contacto';
import { Panel } from './Panel';
import { WebSite } from './Site';
import { Stats } from './Estadistica';
import { Consultor } from './Consultor';
import { Calendario } from './Calendario';
import { UploadForm } from './Uploadform';
import { Editor } from 'primereact/editor';
import { ColorPicker } from 'primereact/colorpicker';
import {ScrollPanel} from 'primereact/scrollpanel';
import { Rating } from 'primereact/rating';
import axios from "axios";
var textoWeb = '';

export const FormularioContacto = (props) => {

const [store, dispatch] = useContext(StoreContext);
const {user, ruta, contacto, contactosUser, especialidad, paises, ciudades, provincias, distritos, zona, img, testMail, api} = store;
const [nombreAbuelo, setNombreAbuelo] = useState("");  
const [categoriaAC, setCategoriaAC] = useState({abuelo:'Categoría', padre:contacto.Nombre_Padre, categoria:contacto.Nombre_Cat }); 
const [editarCat, setEditarCat] = useState(""); 
const [categoriasf, setCategoriasf] = useState([{label: "", value: ""}]); 
const [especialidad1, setEspecialidad1] = useState([]); 
const [emailDisp, setEmailDisp] = useState("libre");  
const [emailValido, setEmailValido] = useState("");  
const [celularDisp, setCelularDisp] = useState("libre");  
const [celularValido, setCelularValido] = useState("");   
const [ubicacion, setUbicacion] = useState({Departamento: contacto.Nombre_Departamento, Distrito: contacto.Nombre_Distrito, Distrito_ID: contacto.distrito, Pais: zona.data.Pais, Pais_ID: zona.data.Pais_ID, Provincia: contacto.Nombre_Provincia, label:contacto.Nombre_Distrito+', '+contacto.Nombre_Provincia+' - '+contacto.Nombre_Departamento+' - '+zona.data.Pais, value:contacto.Nombre_Distrito}); 
const [editarUbica, setEditarUbica] = useState(""); 
const [ubicacionesf, setUbicacionesf] = useState([{label: "", value: ""}]); 
const [selectedCountry, setSelectedCountry] = useState(zona.data.Pais_ID);
const [departamento, setDepartamento] = useState("");
const [provincia, setProvincia] = useState("");
const [campos, setCampos] = useState('ok');
const [botonRegistro, setBotonRegistro] = useState('disabled');
const [categoria, setCategoria] = useState(""); 
const [categorias, setCategorias] = useState([{label: '',value: ""}]);
const [subcategoria, setSubCategoria] = useState(""); 
const [subcategorias, setSubCategorias] = useState([{label: '',value: ""}]);
const [catID, setCatID] = useState(""); 
const [categoriasID, setCategoriasID] = useState([{label: '',value: ""}]);
const [cajaImg, setCajaImg] = useState(""); 
const [mostrar, setMostrar] = useState(""); 
const [coloreo, setColoreo] = useState("0"); 
const [paleta, setPaleta] = useState(""); 
const [preview, setPreview] = useState("ver");  
const [editar, setEditar] = useState("");  
const [mando, setMando] = useState({select:'Panel'});  
const [socio, setSocio] = useState([]);  
const [verMapa, setVerMapa] = useState(false);
const [menu, setMenu] = useState(true);
const [invitacion, setInvitacion] = useState('');
const [match, setMatch] = useState(false);

const fotoF = ruta+img.fotoF;
const fotoM = ruta+img.fotoM;
const logoC = ruta+img.logoC;
const logoB = ruta+img.logoB;
const fotoI = ruta+img.fotoI;
const espectro = ruta+img.espectro;
const gmaps = ruta+img.gmaps;
const imagenPerfil = [{name: 'Foto de inicio', value:'0',img:fotoI},{name: 'Logo de negocio', value:'1',img:logoC },{name: 'Logo transparente', value:'2',img:logoB},{name: 'Foto de perfil hombre', value:'3',img:fotoM},{name: 'Foto de perfil mujer', value:'4',img:fotoF}];
const [Encabezado, SetEncabezado]=useState("");
const [uploadWeb, setUploadWeb]=useState("");



useEffect(() => {
//document.getElementsByClassName('menuContactos')[0].classList.add('elegido');
if(contacto.Contacto_ID!==''){
setBotonRegistro('');
setEditar('');
Especialidades(contacto.Contacto_ID);
obtenerUbicacion(contacto.distrito);
}
else{
setEditar('ok');
setUbicacion('');
setCategoriaAC('');
}
obtenerCategorias('0');
//eslint-disable-next-line
}, []);


const subContacto = (id,especialidad, modo) => { 
	if(modo==='add'){
	setMostrar('ok');
	setSocio({Contacto_ID:id,catID:especialidad.Arbol_ID,modo:modo, mostrar:'ok', label:especialidad.label});
	} 
	if(modo==='del'){  setSocio({Contacto_ID:id, catID:especialidad.Arbol_ID, especialista:{Contacto_ID:especialidad.item}}); 
	if (window.confirm("¿Está seguro que desea borrar el especialista seleccionado?")) {
	AgregarSocio({Contacto_ID:id, catID:especialidad.Arbol_ID, especialista:{Contacto_ID:especialidad.Contacto_ID},modo:modo })
	}
	}
	SetEncabezado("Agregar un contacto a tus especialistas");
} 


const oncoloreochange = (e) => {
	let elegido=e.value/10;
	let color='';
	setColoreo(e.value);
	console.log(elegido);
	switch(Math.round(elegido)){
	case 0:
	color='#fecc00';
	break;
	case 1:
	color='#ff0000';
	break;
	case 2:
	color='bisque';	
	break;
	case 3:
	color='#000000';					//'#efe6e6';
	break;
	case 4:
	color='#d7d7d7';	
	break;	
	case 5:
	color='#ffffff';
	break;
	case 6:
	color='#fdf9d7';	
	break;
	case 7:
	color='#5ab70e';
	break;
	case 8:
	color='#dcefff';	
	break;		
	case 9:
	color='#00adef';
	break;
	case 10:
	color='#006aa7';	
	break;	
    default: 
    console.log("No hay coincidencias");	
	}
	dispatch({ type: types.contacto, payload: {...contacto, color:color} }); 
  }


const fotoupload = (caso) => {
if(caso===1){setUploadWeb('SubirBanner')} 
if(caso===2){setUploadWeb('subirStaff')}
if(caso===3){setUploadWeb('SubirBanner1')}
if(caso===4){setUploadWeb('SubirBanner2')}
if(caso===5){setUploadWeb('SubirBanner3')}
SetEncabezado(preview === 'ver' ? contacto.tipo>2? 'Subir foto de perfil':'Subir logo' : caso === 2 ?  'Subir foto web adicional' : caso === 3 ? 'Subir foto 2 de portada': caso === 4 ? 'Subir foto 3 de portada' : caso === 5 ? 'Subir foto 4 de portada' : 'Subir foto de portada' );
setMostrar('ok');
  }

  
const SuccessUpload = async (data,contactoID,tipo) => {	
	let resX = await InfoData('foto', { data:data,contacto:contactoID,tipo:tipo});	

	console.log(ruta+resX.data);
	switch(tipo){
	case '1':
	dispatch({ type: types.contacto, payload: {...contacto, logo:resX.data } })
	break;
	case '2':
	dispatch({ type: types.contacto, payload: {...contacto, logo:resX.data } })
	break;
	case '3':
	dispatch({ type: types.contacto, payload: {...contacto, Foto:resX.data  } })
	break;
	case '4':
	dispatch({ type: types.contacto, payload: {...contacto, Foto:resX.data  } })
	break;
	case '5':
	dispatch({ type: types.contacto, payload: {...contacto, banner:resX.data  } })
	break;	
	case '6':
	dispatch({ type: types.contacto, payload: {...contacto, staff:resX.data  } })
	break;	
	case '7':
	dispatch({ type: types.contacto, payload: {...contacto, staff:resX.data  } })
	break;
	case '9':
	dispatch({ type: types.contacto, payload: {...contacto, banner1:resX.data  } })
	break;
	case '10':
	dispatch({ type: types.contacto, payload: {...contacto, banner2:resX.data  } })
	break;
	case '11':
	dispatch({ type: types.contacto, payload: {...contacto, banner3:resX.data  } })
	break;	
    default: 
    console.log("No hay coincidencias");
	}
	setMostrar("");	
}


const onUserWebChange = (e) => {
	disponibilidadUsuario(e.target.value);  
	dispatch({ type: types.contacto, payload: {...contacto, userweb:e.target.value } }) 
}	

	
const onTelefonoChange = (e) => {
	dispatch({ type: types.contacto, payload: {...contacto, telefono:e.target.value} }) 	
}
	
	
const onCelularChange = (e) => {
	disponibilidadCelular(e.target.value);   
	dispatch({ type: types.contacto, payload: {...contacto, celular:e.target.value} }) 		
}
	
	
const validarCelular = (valor) => {
	let validez = '';
  if (valor?.length >8 ||valor===''){
   setCelularValido(""); validez = '';
  } else {
   setCelularValido('invalido'); validez = 'invalido';
  }
  return validez;
}
	
	
const onEmailChange = (e) => {
 	disponibilidadEmail(e.target.value);  
	dispatch({ type: types.contacto, payload: {...contacto, email:e.target.value} }) 	
    }
	
	
const validarEmail = (valor) => {
	let validez = '';
  if (testMail.test(valor)||valor===''){
   setEmailValido(""); validez = '';
  } else {
   setEmailValido('invalido'); validez = 'invalido';
  }
  return validez;
}

	
	
const disponibilidadUsuario = async (user) => {	
	let resource = await InfoData('disponibilidad', {usuario:user,celular:'',email:''});	
	if(resource.data.disponibilidad==='ocupado'){
	document.getElementById('inweblocal').style.backgroundColor = '#fff2ef';
	}else{
	document.getElementById('inweblocal').style.backgroundColor = '#FFFFFF';
	}
	return resource.data.disponibilidad;
	} 	
	
const disponibilidadEmail = async (user) => {	
	let resource = await InfoData('disponibilidad', {usuario:'',celular:'',email:user});	
	if(resource.data.disponibilidad==='ocupado'){
	document.getElementById('inemail').style.backgroundColor = '#fff2ef';
	}else{
	document.getElementById('inemail').style.backgroundColor = '#FFFFFF';
	}
	setEmailDisp(resource.data.disponibilidad || '');
	return resource.data.disponibilidad;
	}
	
const disponibilidadCelular = async (user) => {
	let resource = await InfoData('disponibilidad', {usuario:'',celular:user,email:''});		
	console.log(resource.data);
	if(resource.data.disponibilidad==='ocupado'){
	document.getElementById('incel').style.backgroundColor = '#fff2ef';
	}else{
	document.getElementById('incel').style.backgroundColor = '#FFFFFF';
	}
	setCelularDisp(resource.data.disponibilidad || '');
	return resource.data.disponibilidad;
	}

	
const obtenerCategorias = async (padre) => {
    let resource = await InfoData('categoriaSelect', padre);
	setCategorias(resource.data);
}


const obtenerSubcategoria = async (padre) => {
    let resource = await InfoData('categoriaSelect', padre);
	setSubCategorias(resource.data);
}


const obtenerCategoriaID = async (padre) => {
    let resource = await InfoData('categoriaSelect', padre);
	setCategoriasID(resource.data);
}


const onCategoriaChange = (e) => {
	obtenerSubcategoria(e.value);
	setCategoria(e.value)
	setSubCategoria('');
    }

	
const onSubCategoriaChange = (e) => {
	obtenerCategoriaID(e.value);
	setSubCategoria(e.value)
	setCatID('');
    }

	
const onHojaChange = (e) => {
	setCatID(e.value);
	obtenerCategoriaAC(e.value);
    }	

	
const selectedCatNombre = (option, props) => {
	if (option) {
		setNombreAbuelo(option.label);      
		return ( <span>{option.label}</span>  );
		}
		return (  <span>{props.placeholder}</span> );
    }

	
const onCountryChange = (e) => {
		obtenerciudades(e.value);
        setSelectedCountry(e.value);
    }

	
const onCiudadChange = (e) => {
if(selectedCountry==='89'){obtenerProvincias(e.value);}else{dispatch({type:types.provincias, payload:[]});obtenerDistritos('',e.value);}
	setDepartamento(e.value);
	document.getElementsByClassName("p-dropdown-label p-inputtext p-placeholder")[0].style.background='#ffffff';
	setProvincia('');
    }
	
	
const obtenerciudades = async (pais) => {
	let db = await InfoData(types.ciudades, pais);
	dispatch({type:types.ciudades, payload: db.ciudades}); 
	}
  
  
const onProvinciaChange = (e) => {
	obtenerDistritos(e.value,'');
	setProvincia(e.value);
	dispatch({ type: types.contacto, payload: {...contacto, distrito:''} }) 	
    }

	
const obtenerProvincias = async (dpto) => {
    let db = await InfoData(types.provincias, dpto);
    dispatch({type:types.provincias, payload: db.provincias}); 
	}

	 
const onDistritoChange = (e) => {
	dispatch({ type: types.contacto, payload: {...contacto, distrito:e.value} }) 	
	obtenerUbicacion(e.value);
    }
	


const obtenerDistritos = async (prov,dpto) => {
    let db = await InfoData(types.distritos, {prov:prov,dpto:dpto});
    dispatch({type:types.distritos, payload: db.distritos});  
}


  
const AgregarSocio = async (event) => {	
    let resource = await InfoData('insertaSocio', {Contacto_ID:event?.Contacto_ID,catID:event.catID, socio:event.especialista.Contacto_ID, modo:event.modo});
	if(resource.data.Contacto_ID===socio.especialista.Contacto_ID){
	alert(resource.data.Nombre_Comercial+' fue agregado como '+event.label)
	setMostrar(''); }
	setMostrar('');
	Especialidades(contacto.Contacto_ID); 
}

  
const socioChange = (e) => {
	setSocio({...socio, especialista:e.value, nombre:e.value.Nombre_Comercial});
}	

const filtroSocios = async (event) => {		
	var resource = [];
	var ubica = [];
	 if(event.query.length >= 1){resource = await InfoData('socio', event.query.toLowerCase());  ubica = resource.data; }; 
    setTimeout(() => {
		var results = ubica.filter((sociosAC) => {
		return sociosAC.userweb?.toLowerCase();
		});
		setSocio({...socio, filtro:results});  
        }, 350);
}	


const obtenerCategoriaAC = async (catID) => {
	let resource = await InfoData('categoriaCto', {nombre:'', catID:catID});
	let e = resource.data[0];
	setCategoriaAC(e);
	obtenerCategoriaID(e.padreID);
	setCatID(e.Arbol_ID);
	setCategoria(e.abueloID);
	obtenerSubcategoria(e.abueloID);
 	setSubCategoria(e.padreID);
	dispatch({ type: types.contacto, payload: {...contacto, Nombre_Padre:e.padre, Nombre_Cat:e.categoria, Nombre_Cat_Solo:e.Nombre_Cat_Solo,
	Nombre_Padre_Grupo:e.Nombre_Padre_Grupo, Nombre_Padre_solo:e.Nombre_Padre_solo} }) 
}

  
const categoriaACChange = (e) => {
	setCategoriaAC(e.value);
	setCatID(e.value.Arbol_ID);
	let arbol = e.value.Arbol_ID;
	if(typeof arbol!=='undefined') { 
	dispatch({ type: types.contacto, payload: {...contacto, Nombre_Padre:e.value.padre, Nombre_Cat:e.value.categoria, Nombre_Cat_Solo:e.value.Nombre_Cat_Solo,
	Nombre_Padre_Grupo:e.value.Nombre_Padre_Grupo, Nombre_Padre_solo:e.value.Nombre_Padre_solo} }) 	
	}
	if(e.value.padreID!==''||e.value.padreID!=='0'){obtenerCategoriaID(e.value.padreID);}
}


const filtrocategoriasAC = async (event) => {   
  var resource = [];
  var ubica = [];
   if(event.query.length >= 1){resource = await InfoData('categoriaCto', {nombre:event.query.toLowerCase(), catID:''}); 
   console.log(resource.data);  ubica = resource.data;}; 
    setTimeout(() => {
    var results = ubica.filter((categoriaAC) => {
    return categoriaAC.label.toLowerCase();
    });
    setCategoriasf(results);
        }, 250);
    }	
	

const obtenerUbicacion = async (distrito_ID) => {
	let resource = await InfoData('ubicacion', {nombre:'', distrito_ID:distrito_ID});
	setUbicacion(resource.data[0]);
	setSelectedCountry(resource.data[0].Pais_ID);
	setDepartamento(resource.data[0].Departamento_ID);
	setProvincia(resource.data[0].Provincia_ID);
	dispatch({ type: types.contacto, payload: {...contacto, distrito:resource.data[0].Distrito_ID} }) 
}
	
	
const ubicacionChange = (e) => {
	setUbicacion(e.value);
	let country = e.value.Pais_ID;
	let depart = e.value.Departamento_ID;	
	let province = e.value.Provincia_ID;
	let distrit = e.value.Distrito_ID;
	if(typeof province!=='undefined')  setProvincia(province);
	if(typeof distrit!=='undefined' && distrit!=='') { 
	dispatch({ type: types.contacto, payload:{...contacto, distrito:distrit, Nombre_Distrito:e.value.Distrito, Nombre_Provincia:e.value.Provincia }   }) 
	if(typeof depart!=='undefined'){
	setDepartamento(depart); }
	if(typeof country!=='undefined' && selectedCountry==='') {
	setSelectedCountry(country);
	obtenerciudades(country);
	if(country==='89'){obtenerProvincias(depart);obtenerDistritos(province,'')}else{obtenerDistritos('',depart)};
	document.getElementsByClassName("p-inputtext p-component p-autocomplete-input")[0].style.background='#ffffff';}
	if(distrit===''){setEditarUbica('editar');
	document.getElementsByClassName("p-dropdown-label p-inputtext p-placeholder")[2].style.background='#fff2ef';
	}
}
}


const filtroubicaciones = async (event) => {		
	var resource = [];
	var ubica = [];
	 if(event.query.length >= 1){resource = await InfoData('ubicacion', {nombre:event.query.toLowerCase(), distrito_ID:''}); 
	 ubica = resource.data;}; 
    setTimeout(() => {
		var results = ubica.filter((ubicacion) => {
		return ubicacion.label.toLowerCase();
		});
		setUbicacionesf(results);
        }, 350);
}


const selectedCountryTemplate = (option, props) => {
  if (option) {
      return (
          <div className="country-item country-item-value">
              <img alt={option.label} src={ruta+img.flag} onError={e => e.target.src = ruta+img.flagEr} className={`flag flag-${option.code.toLowerCase()}`} />
              <div style={{flex: 'none'}}>{option.label}</div>
          </div>
      );
  }
  return (
      <span>
          {props.placeholder}
      </span>
  );
}


const countryOptionTemplate = (option) => {
  return (
      <div className="country-item">
          <img alt={option.label} src={ruta+img.flag} onError={(e) => e.target.src=''} className={`flag flag-${option.code.toLowerCase()}`} />
          <div style={{flex: 'none'}}>{option.label}</div>
      </div>
  );
}
  
  
const acCatEstado = (e) => {
if(catID===''){
		document.getElementsByClassName("p-inputtext p-component p-autocomplete-input")[1].style.background='#fff2ef';
		setCategoriaAC('');
    } else {document.getElementsByClassName("p-inputtext p-component p-autocomplete-input")[1].style.background='#ffffff';}
	habilitarG();
} 

	
const acEstado = (e) => {
if(departamento===''){
		setUbicacion('');
    } else {/*ac.style.background='#ffffff';*/}
	habilitarG();
}
	
	
const userWebEstado = (e) => {
if(contacto.userweb===''){
		document.getElementById('inweblocal').style.background='#fff2ef';
    } else {document.getElementById('inweblocal').style.background='#ffffff';}
}	
	
	
const nombreComEstado = (e) => {
if(contacto.Nombre_Comercial===''){
		document.getElementById('innombrecom').style.background='#fff2ef';
    } else {document.getElementById('innombrecom').style.background='#ffffff';}
	habilitarG();
}
	
	
const direccionEstado = (e) => {
if(contacto.direccion===''){
		document.getElementById('indireccion').style.background='#fff2ef';
    } else {document.getElementById('indireccion').style.background='#ffffff';}
	habilitarG();
}
	
	
const emailEstado = (e) => {
let validez= validarEmail(contacto.email); 
if(emailDisp==='libre'){
			if((contacto.email===''||validez==='invalido' )&& contacto.celular ===''){
			document.getElementById('inemail').style.background='#fff2ef';
			} else {
			document.getElementById('inemail').style.background='#ffffff';/*habilitarG();*/}
		}
}


const movilEstado = (e) => {
let validez= validarCelular(contacto.celular); 
if(celularDisp==='libre'){
			if(contacto.celular ===''||validez==='invalido'){
			document.getElementById('incel').style.background='#fff2ef';
			} else {
			document.getElementById('incel').style.background='#ffffff';}
		}
	habilitarG();
}


const TelefonoEstado = (e) => {
			if(contacto.telefono===''){
			document.getElementById('intel').style.background='#fff2ef';
			} else {
			document.getElementById('intel').style.background='#ffffff';}
			habilitarG();
	}

	
const paisEstado = (e) => {
if(selectedCountry===''){
		document.getElementsByClassName("p-dropdown-label p-inputtext")[0].style.background='#fff2ef';
    } else {document.getElementsByClassName("p-dropdown-label p-inputtext")[0].style.background='#ffffff';habilitarG();}
}	


const dptoEstado = (e) => {
if(departamento===''){
		document.getElementsByClassName("p-dropdown-label p-inputtext")[1].style.background='#fff2ef';
    } else {document.getElementsByClassName("p-dropdown-label p-inputtext")[1].style.background='#ffffff';habilitarG();}
}


const proviEstado = (e) => {
if(provincia===''){
		document.getElementsByClassName("p-dropdown-label p-inputtext")[2].style.background='#ffffff';
    } else {document.getElementsByClassName("p-dropdown-label p-inputtext")[2].style.background='#ffffff';habilitarG();}
	}

	
const distriEstado = (e) => {
if(contacto.distrito===''){
		document.getElementsByClassName("p-dropdown-label p-inputtext")[3].style.background='#ffffff';
    } else {document.getElementsByClassName("p-dropdown-label p-inputtext")[3].style.background='#ffffff';habilitarG();}
	}	 
 

const invitacionEstado = (e) => { 
if(invitacion===''){
		document.getElementById('incodigo').style.background='#fff2ef';
			} else {
			if(invitacion==='ALA314'){setMatch(true);}else{setMatch(false);}
			document.getElementById('incodigo').style.background='#ffffff';} 
			habilitarG(); 
}
 
 
const habilitarG = (e) => {
	if([contacto.Nombre_Comercial,contacto.direccion,contacto.celular||contacto.telefono,contacto.distrito,catID, invitacion].includes('') || !match)
	{	setBotonRegistro('disabled');}else{
	setBotonRegistro(''); 
	if(contacto.tipo==='0'){setCajaImg('mostrar');document.getElementsByClassName("fotoCombo")[0].style.background='#fff2ef'; }}
    }

const validacion = async (e) => {	
	let validacionDatos='';
	let celularVal = '';
	let contactoLibre = '';
	let camposObligatorios = '';
	let invita = contacto.Contacto_ID? 'ok':invitacion;
if([contacto.Nombre_Comercial,contacto.direccion,contacto.celular||contacto.telefono,contacto.distrito,catID, invita].includes(''))
	{	
		setCampos('')
		camposObligatorios = ''; 
		nombreComEstado();
		direccionEstado();
		movilEstado();
		TelefonoEstado();
		distriEstado();
		acEstado();
	}
	else
	{
		setCampos('ok'); 
		camposObligatorios = 'ok';
	}
	if([celularVal].includes('ocupado'))
	{
		setCampos('')
		contactoLibre = ''; 
	}
	else
	{
		setCampos('ok'); 
		contactoLibre = 'ok';
	}
		
	if([camposObligatorios,contactoLibre].includes(''))
	{
		let c=''; if(celularVal==='ocupado')c='o';
		  alert(c?'Este teléfono móvil ya se encuentra registrado.':'Completar los campos');
	}else{
	validacionDatos='ok';
	}
	return validacionDatos;
}


const registrar = async (e) => {
	let tipo='';
	let datosValidos = await validacion();

	if(contacto.tipo === ''){alert('Indicar tipo de contacto, se representa a una persona o una organización');
	setCajaImg('mostrar');
	document.getElementsByClassName("fotoCombo")[0].style.background='#fff2ef'; document.getElementsByClassName("fotoCombo")[0].style.borderColor='red';}
	else{tipo='ok'; document.getElementsByClassName("fotoCombo")[0].style.background='#ffffff';}

	if (datosValidos==='ok' && tipo==='ok'){
//		let resource = await InfoData('contactoReg', {contacto:contacto, usuario:user.Usu_ID, catID:catID});
		let resource = await registrarCtoServer(contacto, user.Usu_ID, catID);
		resource.data.item = contactosUser.length;
		console.log(resource.data);	
		if(resource.data.last_id!=='' && resource.data.last_id!==undefined)
		{
			alert(resource.data.Nombre_Comercial+' fue registrado satisfactoriamente');
			dispatch({ type: types.contacto, payload: {...contacto, ...resource.data, item:contactosUser.length } });
			let contactos=contactosUser;
			contactos.push(resource.data)
			dispatch({ type: types.contactosUser, payload: contactos });
		}
	}	

}


const registrarCtoServer = async (contacto, usuario, catID ) => {		
	var config = { headers: { 'content-type': 'multipart/form-data' }};
	const formData = new FormData();	
	formData.append('operacion', 12);
	formData.append('nombre', contacto.Nombre_Comercial.trim());
	formData.append('distrito', contacto.distrito);  
	formData.append('direccion', contacto.direccion.trim());
	formData.append('Celular', contacto.celular.trim());
	formData.append('telefono', contacto.telefono.trim());
	formData.append('tipo', contacto.tipo); //'' - 1 - 2
	formData.append('Usu_ID',usuario);
	formData.append('color',contacto.color);
	formData.append('catID', catID);
    return await axios.post(api, formData, config);
	}

  
const guardar = async (e) => {	

	let cto = contacto;
	cto.Presentacion = textoWeb?textoWeb:contacto.Presentacion;
	let contactos=contactosUser;
	contactos[contacto.item]=cto
	console.log(contactos[contacto.item]);
	dispatch({ type: types.contactosUser, payload: contactos });
	let datosValidos ='';
	if(mando.select === 'Panel'){ datosValidos = await validacion();} else {datosValidos ='ok';}
	if (datosValidos==='ok'){
//		let resource = await InfoData('contactoSave', {contacto:contacto, usuario:user, textoWeb:textoWeb});
		let resource = await guardarCtoServer(contacto, user, textoWeb);		
		if(resource.data?.Contacto_ID!==''&& resource.data?.Contacto_ID!==undefined)
		{
			alert(resource.data.Nombre_Comercial+' actualizó su información');
			dispatch({ type: types.contacto, payload: { editar:'ok', user:user.Usu_ID, ...resource.data } });
			mando.select==='Panel'?setEditar(''):setPreview('ver');
		}
	}	
}


  const guardarCtoServer = async (contacto, usuario, textoWeb) => {
	var config = { headers: { 'content-type': 'multipart/form-data' }};
	const formData = new FormData();	
	formData.append('operacion', 14);
	formData.append('Contacto_ID', contacto.Contacto_ID);	
	formData.append('nombre', contacto.Nombre_Comercial.trim());
	formData.append('distrito', contacto.distrito);  
	formData.append('direccion', contacto.direccion.trim());
	formData.append('Celular', contacto.celular.trim());
	formData.append('telefono', contacto.telefono.trim());
	formData.append('tipo', contacto.tipo); //'' - 1 - 2
	formData.append('Usu_ID',usuario.Usu_ID);
	formData.append('color',contacto.color);
	formData.append('web', contacto.web.trim());
	formData.append('userweb', contacto.userweb?.trim() || '');
	formData.append('usoweb', contacto.usoweb);
	formData.append('Email', contacto.email.trim());
	formData.append('gmaps', contacto.Gmaps.trim());    
	formData.append('presentacion', textoWeb?textoWeb:contacto.Presentacion);   
	formData.append('fb', contacto.Facebook.trim());     
	formData.append('Instagram', contacto.Instagram);     
	formData.append('Youtube', contacto.Youtube);     
	formData.append('Twitter', contacto.Twitter);   
	formData.append('Tiktok', contacto.Tiktok);   	
    return await axios.post(api, formData, config);
} 


const borrarCto = async (Contacto) => {
	if(window.confirm('¿Seguro que deseas eliminar el contacto?')){
	console.log(Contacto);
	let resource = await InfoData('contactoDel', Contacto);
	console.log(resource.data);
	props.reload(true);
	dispatch({ type: types.contacto, payload: {editar:''} });
	alert('contacto borrado');	
	}
}

const onListBoxChange = (e) => {
	let indicador = e.value.length-especialidad1.length;
	if (indicador > 0) { Especialidades(contacto.Contacto_ID,e.value[e.value.length-1],'')
	setEspecialidad1(e.value);		
	} else 
	{ let extraido = especialidad1.filter(elem => e.value.indexOf(elem) === -1); //$(especialidad1).not(e.value).get()[0]; 
	console.log(extraido);
	if(window.confirm('¿Seguro que deseas eliminar la categoría?')){
    Especialidades(contacto.Contacto_ID,extraido,'borrar');
	setEspecialidad1(e.value);	
	}}

}


const Especialidades = async (contacto_ID,catID,borrar,texto) => {
	if(borrar==='texto'){dispatch({ type: types.contacto, payload: {...contacto, editArea:''} })}
//	let cadena = await InfoData( types.especialidad, {Contacto_ID:contacto_ID, catID:catID, borrar:borrar, texto:texto} );
    let resource = await serverEspecialidades( contacto_ID, catID, borrar, texto );
	let cadena = resource.data;
	let cadena1 =cadena.pop(); 	
	setEspecialidad1(cadena1);
	if(borrar==='texto'){alert(texto);}
	dispatch({ type: types.especialidad, payload: cadena });
	obtenerCategoriaAC(cadena[0]?.Arbol_ID);
  }
  
  
  const serverEspecialidades = async (Contacto_ID,catID,borrar,texto) => {
	var config = { headers: { 'content-type': 'multipart/form-data' }};
	const formData = new FormData();	
	formData.append('operacion', 6);
	formData.append('Contacto_ID', Contacto_ID);
	formData.append('presentacion', texto || '');  
	formData.append('catID', catID || '');
	formData.append('accion', borrar || '');
    return await axios.post(api, formData, config);
    } 

  
const checka = (e) => {  
	if(e.checked){
	if(window.confirm('Ahora tendrás un perfil adicional con una página web dinámica a tu servicio'))
	dispatch({ type: types.contacto, payload: {...contacto, usoweb:e.checked?'1':'0'} })
	}else{
	dispatch({ type: types.contacto, payload: {...contacto, usoweb:e.checked?'1':'0'} })  
	}
 }

  
const checkp = (e) => {  
	if(e.checked){
	if(window.confirm('Tu perfil no aparecerá en las búsquedas de Servicontacto'))
	dispatch({ type: types.contacto, payload: {...contacto, usoweb:e.checked?'2':'0'} })
	}else{
	dispatch({ type: types.contacto, payload: {...contacto, usoweb:e.checked?'2':'0'} })  
	}
 }


  const fotoIco1 = (caso) => {
  return <i className="pi pi-camera fotoIco" onClick={()=>fotoupload(caso)} style={{top:mando.select==='Web'?'':'5.05em','display': contacto.Contacto_ID ? preview === 'ver'&& editar==='' ? 'none' : 'block':'none'}}></i>;
}


const checkPag = (caso) => {
	let salida = false;
	if( [contacto.banner,contacto.staff,contacto.Presentacion].includes('')){
	salida = true;
	}
	return salida;
}


const elegir = (contacto) => {
	console.log(contacto);  
	}


const editorweb1 = (area) => {
let texto='';
let codigo=false;
return <div className='acerca edit-box' style={{'display': preview==='ver' ? 'none':'block', paddingBottom:'0'}}><h3>{'Escribir descripción del área: '+area.label  }</h3>
			<Editor id="editat" value={area.Descripcion} onTextChange={(e) => { texto=e.htmlValue; }} />
			<Button className="agendar oculto" label="" icon="fa fa-code" onClick={() =>  {codigo=!codigo; console.log(codigo);}} style={{margin:'5px', width:'2.8em'}}/>
			<Button className="agendar" label="Guardar"  onClick={() => Especialidades(contacto.Contacto_ID,area.Arbol_ID,'texto',texto)} style={{margin:'5px', width:'8em'}}/>
		</div>;
}


const presentacionChange = (e) => {
	textoWeb = e.htmlValue;
	document.getElementById('quilla').innerHTML = e.htmlValue; 
	document.getElementsByClassName('ql-editor')[0].classList.toggle('presentacion');
 }

 
const banners = (Socio) => {
return (
		<Fragment>{preview === 'ver' ? '':
		<div className="icofl" style={{bottom:'30px'}}>
		<ul className="menufl" style={{fontWeight:'bold', textAlign:'center', margin:'10px 0 3px'}}>
			<li style={{display:'grid', maxWidth:'84px', height:'auto', border:contacto.fondo==='1'?'1px solid '+contacto.color:'none'}} onClick={() => dispatch({ type: types.contacto, payload: {...contacto, fondo:'1'} })}><span style={{padding:'0.5em 1em 0', whiteSpace:'nowrap'}}>fondo 1</span>{contacto.banner?<img src={ruta+contacto.banner} alt="banner" width='100%'/>:<i className='pi pi-image' style={{fontSize:'30px', width:'100%'}}/>}</li>
			<li style={{display:'grid', maxWidth:'84px', height:'auto', border:contacto.fondo==='2'?'1px solid '+contacto.color:'none'}} onClick={() => dispatch({ type: types.contacto, payload: {...contacto, fondo:'2'} })}><span style={{padding:'0.5em 1em 0', whiteSpace:'nowrap'}}>fondo 2</span>{contacto.banner1?<img src={ruta+contacto.banner1} alt="banner" width='100%'/>:<i className='pi pi-image' style={{fontSize:'30px', width:'100%'}}/>}</li>
			<li style={{display:'grid', maxWidth:'84px', height:'auto', border:contacto.fondo==='3'?'1px solid '+contacto.color:'none'}} onClick={() => dispatch({ type: types.contacto, payload: {...contacto, fondo:'3'} })}><span style={{padding:'0.5em 1em 0', whiteSpace:'nowrap'}}>fondo 3</span>{contacto.banner2?<img src={ruta+contacto.banner2} alt="banner" width='100%'/>:<i className='pi pi-image' style={{fontSize:'30px', width:'100%'}}/>}</li>
			<li style={{display:'grid', maxWidth:'84px', height:'auto', border:contacto.fondo==='4'?'1px solid '+contacto.color:'none'}} onClick={() => dispatch({ type: types.contacto, payload: {...contacto, fondo:'4'} })}><span style={{padding:'0.5em 1em 0', whiteSpace:'nowrap'}}>fondo 4</span>{contacto.banner3?<img src={ruta+contacto.banner3} alt="banner" width='100%'/>:<i className='pi pi-image' style={{fontSize:'30px', width:'100%'}}/>}</li>
		</ul>
		</div>}</Fragment>
		);
}


const editarForm = (caso) => { 
	let electo=document.getElementsByClassName('menuContactos');
	document.getElementById("menuMovilEdit").classList.toggle('oculto');
	setMenu(true);
	switch(caso){
	case 0:
	electo[0].classList.add('elegido'); electo[1].classList.remove('elegido'); electo[2].classList.remove('elegido');
	electo[3].classList.remove('elegido'); electo[4].classList.remove('elegido'); electo[5].classList.remove('elegido'); electo[6].classList.remove('elegido');
	setPreview('ver'); setMando({...mando, select:'Panel'})
	break;
	case 1:
	electo[1].classList.add('elegido'); electo[0].classList.remove('elegido'); electo[2].classList.remove('elegido');
	electo[3].classList.remove('elegido'); electo[4].classList.remove('elegido'); electo[5].classList.remove('elegido');  electo[6].classList.remove('elegido');
	setEditar(''); setMando({...mando, select:'Contacto'})
	break;
	case 2:
	electo[2].classList.add('elegido'); electo[1].classList.remove('elegido'); electo[0].classList.remove('elegido');
	electo[3].classList.remove('elegido'); electo[4].classList.remove('elegido'); electo[5].classList.remove('elegido');  electo[6].classList.remove('elegido');
	setEditar(''); setMando({...mando, select:'Web'})
	break;	
	case 3:
	electo[3].classList.add('elegido'); electo[1].classList.remove('elegido'); electo[0].classList.remove('elegido');
	electo[2].classList.remove('elegido'); electo[4].classList.remove('elegido'); electo[5].classList.remove('elegido');  electo[6].classList.remove('elegido');
	setEditar(''); setMando({...mando, select:'Visitas'})
	break;	
	case 4:
	electo[4].classList.add('elegido'); electo[1].classList.remove('elegido'); electo[0].classList.remove('elegido');
	electo[2].classList.remove('elegido'); electo[3].classList.remove('elegido'); electo[5].classList.remove('elegido'); electo[6].classList.remove('elegido'); 
	setEditar(''); setMando({...mando, select:'Consultor'})
	break;	
	case 5:
	electo[5].classList.add('elegido'); electo[1].classList.remove('elegido'); electo[0].classList.remove('elegido');
	electo[2].classList.remove('elegido'); electo[3].classList.remove('elegido'); electo[4].classList.remove('elegido'); electo[6].classList.remove('elegido'); 
	setEditar(''); setMando({...mando, select:'Calendario'})
	break;
	case 6:
	electo[6].classList.add('elegido'); electo[1].classList.remove('elegido'); electo[0].classList.remove('elegido');
	electo[2].classList.remove('elegido'); electo[3].classList.remove('elegido'); electo[4].classList.remove('elegido'); electo[5].classList.remove('elegido'); 
	setEditar(''); setMando({...mando, select:'Calificación'})
	break;	
    default: 
    console.log("No hay coincidencias");	
	}
  }
  
  

	
	//--------------------------------
    // Zona de renderización final



const asociados = <div className="p-fluid reg-content-wrapper" style={{/*height:'16em'*/}} >
    <span>Nombre de usuario web del especialista en Servicontacto</span>
    <div style={{float:'left', width:'100%'}} ><label className="float-label" htmlFor="asociado">Dirección web en Servicontacto</label>
      <AutoComplete id="asociado" value={socio.especialista} suggestions={socio.filtro} completeMethod={filtroSocios} field="userweb" size={30} placeholder="Escribe el usuario web del contacto nuevo" minLength={1} onChange={socioChange}  />
    </div>
    <div style={{float:'right', marginTop:'10px' /*position: 'relative', top:'7%'*/}} >
      <Button  className="btnAmarillo" label="Incorporar" onClick={() => AgregarSocio(socio)} style={{height:'2.2em', width:'100%', background:'#004983'}}/>
    </div>		
    <p style={{clear:'both', padding:'1em 0 0 0'}}>{ socio.especialista  ? '¿Incorporar a: ' + socio?.especialista?.Nombre_Comercial+',  especializado en '+socio?.especialista?.label+'?' : ''}</p>
</div> ;
const EdicionMenu = <ul> 
	<li className="menuContactos" onClick={() => {editarForm(0); setEditar('')}} style={{borderTop:'solid 1px #cfd3e099'}}>Presentación de lista</li>
	<li className="menuContactos" onClick={() => {editarForm(1); setPreview('ver')}} >Perfil de Servicontacto</li>
	<li className="menuContactos" onClick={() => {editarForm(2); setPreview('ver')}} style={{'display':contacto.usoweb==='0'?'none':'list-item'}} >Tu página web</li>
	<li className="menuContactos" onClick={() => editarForm(3)} >{contacto.usoweb==='0'?'Visitas a perfil':'Visitas a página web'}</li>
	<li className="menuContactos" onClick={() => editarForm(4)} >Panel de consultor</li>
	<li className="menuContactos" onClick={() => editarForm(5)} >Calendario<span className="menuitem-badge">4</span></li>
	<li className="menuContactos" onClick={() => editarForm(6)} >Calificación de clientes</li>
</ul> ;
const fotoIco = <i className="pi pi-camera fotoIco" onClick={fotoupload} style={{top:mando.select==='Web'?'-3.5em':'70%','display': contacto.Contacto_ID ? preview === 'ver'&& editar==='' ? 'none' : 'block':'none'}}></i>;
const colorSelect = <Fragment><img width='100%' alt="espectro" src={espectro}/><Slider value={coloreo} onChange={(e) => oncoloreochange(e)} /></Fragment>;
const fotoSetting = editar ? <Fragment>{fotoIco}
	<i className = "pi pi-palette fotoIco"  onClick={() => setPaleta('mostrar')} style={{'display':imagenPerfil[contacto.tipo]?.img===logoB&&contacto.tipo <= 2?'block':'none', right:'45px'}}> </i>
	<span className="p-input-icon-right" style={{'display': paleta ? 'block':'none', padding:'0.3em 0', top:'-0.8em'}}>
	<i className='pi pi-times' style={{right: '0.2em', top:'0.8em', cursor:'pointer'}} onClick={() => setPaleta('')}/>
	{colorSelect}</span></Fragment>:'';
const colorWeb = preview==='no' ? 
<span style = {{width:'14em'}}>{colorSelect}
	<ColorPicker style={{width:'1em', left:'-2.5em', position:'absolute'}} value={contacto.color} onChange={(e) => dispatch({ type: types.contacto, payload: {...contacto, color:'#'+e.value} })}></ColorPicker>
</span>:'';
const fotoCombo = editar ?  
<span className='fotoCombo' onClick={(e) => setCajaImg('mostrar')}><span style={{width:'calc(100% - 12px)', overflow:'hidden'}}>Elige tipo de contacto </span>
	<i className="pi pi-chevron-down" style={{float:'right',margin:'3px 0px 3px 8px'}}></i>
</span>:'';
const fotoOpcion = cajaImg ?
<div className="icofl" style={{'display':'inline-block'}} onMouseLeave={() => setCajaImg('')}>
	<i className='pi pi-times cerrar movil' onClick={() => setCajaImg('')}/>
	<span className="movil" style = {{fontSize:'14px'}}>Elige el tipo de contacto</span>
	<ul className="menufl" >
	<li><img onClick={() => {dispatch({ type: types.contacto, payload: {...contacto, tipo:imagenPerfil[3].value} });setPaleta('');}} alt="hombre" width='70' src={fotoM}/></li>
	<li><img onClick={() => {dispatch({ type: types.contacto, payload: {...contacto, tipo:imagenPerfil[4].value} });setPaleta('');}} alt="mujer" width='70' src={fotoF}/></li>
	<li><img onClick={() => {dispatch({ type: types.contacto, payload: {...contacto, tipo:imagenPerfil[2].value} });setPaleta('');}} alt="logo" width='180' src={logoB}/></li>
	<li><img onClick={() => {dispatch({ type: types.contacto, payload: {...contacto, tipo:imagenPerfil[1].value} });setPaleta('');}} alt="logo" width='180' src={logoC}/></li></ul>
</div>:'';
const revisar = contacto.Contacto_ID?<Fragment>{preview === 'ver'&& editar===''?
	<>{mando.select === 'Panel'	? especialidad.length<2? !contacto.userweb? <i className="fa fa-trash" onClick={() => borrarCto(contacto.Contacto_ID)} style={{position:'relative', top: '-23px',right: '20px',color: '#bbb', cursor:'pointer'}}/>:'':'':''}
	<Button  icon="far fa-edit" className="btnAmarillo" label="Editar"   onClick={() => {mando.select==='Panel'?setEditar('ok'):setPreview('no')}} 
	style={{margin:'0 0.5rem 1rem 0'}}/></>:
	<Button  icon="pi pi-times" className="btnAmarillo" label="Cancelar" onClick={() => {mando.select==='Panel'?setEditar(''):setPreview('ver'); dispatch({ type: types.contacto, payload: {...contactosUser[contacto.item], editar:'ok'} }) ;}} 
	style={{margin:'0 0.5rem 1rem 0'}}/>}
</Fragment>:'';  
const contactoWeb = preview==='ver' ? '':
<div className="reg-content-wrapper p-fluid edit-box" style={{padding:'0.1em 1em'}}>
    <ul className="input-fields container-box"  >
      <li className="cell-input">
        <div className="cell-body"> <label className="float-label" htmlFor="inemail">Correo electrónico</label>
        <InputText id="inemail" value={contacto.email} onChange={onEmailChange} aria-describedby="inemail-help"  onBlur={() => emailEstado()}/><small id="inemail-help" className="p-error p-d-block">{ (emailDisp === 'ocupado' ?  contacto.email+' ya está registrado.':'')+(emailValido ?  contacto.email+' no es un correo electrónico válido':'')}</small></div>
      </li>
      <li className="cell-input">
        <div className="cell-body"><label className="float-label" htmlFor="infb">Dirección de facebook</label>
        <InputText id="infb" value={contacto.Facebook} onChange={(e) => dispatch({ type: types.contacto, payload: {...contacto, Facebook:e.target.value} }) } aria-describedby="infb-help" /><small id="infb-help" className="p-error p-d-block">{ contacto.Facebook ?  'Asegurate de verificar la dirección.':''}</small></div>
      </li>	  
      <li className="cell-input">
        <div className="cell-body"><label className="float-label" htmlFor="inig">Dirección de Instagram</label>
        <InputText id="inig"  value={contacto.Instagram} onChange={(e) => dispatch({ type: types.contacto, payload: {...contacto, Instagram:e.target.value} }) }/></div>
      </li>
      <li className="cell-input">
        <div className="cell-body"><label className="float-label" htmlFor="intt">Dirección de TikTok</label>
        <InputText id="intt"  value={contacto.Tiktok} onChange={(e) => dispatch({ type: types.contacto, payload: {...contacto, Tiktok:e.target.value} }) }/></div>
      </li>      <li className="cell-input">
        <div className="cell-body"><label className="float-label" htmlFor="inyt">Dirección de Youtube</label>
        <InputText id="inyt"  value={contacto.Youtube} onChange={(e) => dispatch({ type: types.contacto, payload: {...contacto, Youtube:e.target.value} }) }/></div>
      </li> 
      <li className="cell-input">
        <div className="cell-body"><label className="float-label" htmlFor="intw">Dirección de Twitter</label>
        <InputText id="intw"  value={contacto.Twitter} onChange={(e) => dispatch({ type: types.contacto, payload: {...contacto, Twitter:e.target.value} }) }/></div>
      </li> 	  
      <li className="cell-input">
        <div className="cell-body"><label className="float-label" htmlFor="ingmaps">Coordenada Google Maps</label>
        <InputTextarea id="ingmaps" value={contacto.Gmaps} onChange={(e) => dispatch({ type: types.contacto, payload: {...contacto, Gmaps:e.target.value} }) } aria-describedby="ingmaps-help" autoResize={true} rows={3} cols={30}/>
		<small id="ingmaps-help" className="p-error p-d-block"> {'Usar el enlace https dentro de <iframe src"..."> que comparte gmaps, el detalle se muestra en la imgen siguiente:'}<i className="far fa-file-image icoBoton" onClick={ e => setVerMapa(!verMapa)} style={{color:'green', fontSize:'1.7em', margin:'0 5px'}}></i> </small>
		</div>
      </li>
    </ul>
</div>;
const editorweb = preview!=='ver'?<div className='acerca edit-box'><h2>Escribo un resumen{contacto.tipo >=3?" acerca de mi":" acerca de nosotros"}</h2>
	<Editor value={contacto.Presentacion} onTextChange={presentacionChange}/>
</div>:'';
const editorhtml = preview!=='ver'?<div className='acerca edit-box'><h2>Modificar el texto en Html</h2>
        <InputTextarea id="inhtml" value={contacto.Presentacion} onChange={(e) => dispatch({ type: types.contacto, payload: {...contacto, Presentacion:e.target.value} }) } aria-describedby="inhtml-help" autoResize={true} rows={3} cols={90} style={{width:'100%'}}/>
</div>:'';
const selectorCat = preview==='ver' ? '':
<div className='acerca'><div className='acerca edit-box'><h2>Selección de especialidades</h2>
	<div className="p-fluid reg-content-wrapper" style={{width:'50%',float:'left', padding:'0 1em 0 0'}}>
	<ul><li className="cell-input">
		<label className="float-label" htmlFor="incategoria">Categoría general</label>
		<Dropdown value={categoria} options={categorias} onChange={onCategoriaChange} id="incategoria" placeholder="Categorías" valueTemplate={selectedCatNombre}/>
		</li>
		<li className="cell-input">
		<label className="float-label" htmlFor="insubcategoria">Sub Categoría</label>
		<Dropdown value={subcategoria} options={subcategorias} onChange={onSubCategoriaChange} id="insubcategoria" placeholder="Sub-Categorías" />
		</li>
		<li className="cell-input">
		<label className="float-label" htmlFor="insubcategoria">Categoría elegida</label>
		<span>{nombreAbuelo}</span>
		</li>
		<li className="cell-input">
		<label className="float-label" htmlFor="insubcategoria">Sub-Categoría elegida</label>
		<span>{contacto.Nombre_Padre}</span>
		</li>
	</ul>
	</div>
	<div className="reg-content-wrapper" style={{width:'50%',float:'right', padding:'0 0 0 1em'}}><label className="float-label" htmlFor="insubcategoria">Elegir una o varias especialidades</label><ListBox optionLabel="label" value={especialidad1} options={categoriasID} onChange={onListBoxChange} multiple listStyle={{maxHeight: '215px'}}/></div>
	<p>Selecciona una categoría para agregarla a tu lista de especialidades, si quieres eliminarla, deseleccionala</p>
</div>
</div>;
const i = <i className='pi pi-chevron-right' style={{margin:'0 7px', fontSize:'9px'}}/>;
const cabecera = <div className='ancho' style={{background:'#f4f4f4', minHeight:'2em', padding:'0.6em', overflow:'hidden', boxShadow:'rgb(0 0 0 / 35%) 0px 2px 3px 0px'}}>
	{!checkPag()?<span>La página web esta completa y será publicada en: <a href={'https://servicontacto.com/'+contacto.userweb}  target="_blank" rel="noreferrer" style={{color:'#006aa7'}}>{'https://servicontacto.com/'+contacto.userweb}</a></span>:
	<span style={{color:'red', fontWeight:'bold'}}><i className='pi pi-exclamation-triangle icoBoton' style={{margin:'0 5px'}}></i>
	La pagina esta incompleta y no puede publicarse en la web
		<ul style={{margin:'0 3.5em'}}>
		  {!contacto.banner?<li> no existe foto de portada
		  </li>:''}
		  {!contacto.staff?<li> no existe foto de texto resumen en la página 
		  </li>:''}
		  {!contacto.Presentacion?<li> no hay un texto de presentación de la página 
		  </li>:''}
		</ul>
	</span>}
</div>;
const mapa = verMapa?<img alt="imagen" src={gmaps} style={{width:'100%'}}/>:'';
const botonEdit = editar||preview==='no' ?
			<div style={{/*marginBottom:'3em'*/ position:'fixed', bottom:'3em', width:'295px'}}>{contacto.Contacto_ID ?
				<Button  className="logboton join-btn" label="Guardar" onClick={() => guardar()} />:
				<Button  className="logboton join-btn" label="Crear contacto" onClick={() => registrar()} disabled={botonRegistro}/>}
			</div>:'';
const editPanel = editar ? <Fragment>
			<ul className="input-fields container-box" /*formulario de registro o edicion de crear contacto*/>  
			  <li className="cell-input">
			  <i className='pi pi-times cerrar' onClick={() => dispatch({ type: types.contacto, payload: {...contacto, editar:''} })}/>
			  {contacto.Contacto_ID ?<h2>Información de Contacto</h2>:<h1>Crear Contacto</h1>}
			  </li>{contacto.Contacto_ID ? '':
			  <li className="cell-input">
				<div className="cell-body" style={{width:invitacion?'90%':'100%',float:'left'}}><label className="float-label" htmlFor="incodigo">Codigo de invitación</label>
				<InputText id="incodigo" placeholder="Código de invitación" value={invitacion} onChange={(e) => setInvitacion(e.target.value) } onBlur={() => invitacionEstado()}/></div>
				{invitacion?<i className={match?'pi pi-check':'pi pi-times'} style={{ color:match?'green':'red', float:'right', position:'relative', top:'3.25em', right:'5px', fontWeight:'bold'}}/>:''}
			  </li>}
			  <li className="cell-input">
				<div className="cell-body"><label className="float-label" htmlFor="innombrecom">Nombre Comercial</label>  
				<InputTextarea id="innombrecom" placeholder="Escribe tu nombre comercial"  value={contacto.Nombre_Comercial} onChange={(e) => dispatch({ type: types.contacto, payload: {...contacto, Nombre_Comercial:e.target.value} }) } onBlur={() => nombreComEstado()} autoResize={true} rows={1} cols={30}/></div>
			  </li>
			  {editarUbica ? <Fragment>
			  <li className="cell-input">
				<div className="cell-body"><label className="float-label" htmlFor="inpais">País</label>
				<Dropdown value={selectedCountry} options={paises} onChange={onCountryChange} placeholder="Pais" onBlur={() => paisEstado()}
				valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} id="inpais" /></div>
			  </li>
			  <li className="cell-input">
				<div className="cell-body"><label className="float-label" htmlFor="indepa">Departamento</label> 
				<Dropdown value={departamento} options={ciudades} onChange={onCiudadChange} placeholder="Departamento" id="indepa" onBlur={() => dptoEstado()}/></div>
			  </li>
			  <li className="cell-input" style={{'display': selectedCountry==='89'?'block':'none'}}>
				<div className="cell-body"><label className="float-label" htmlFor="inprov">Provincia *</label>
				<Dropdown value={provincia} options={provincias} onChange={onProvinciaChange} placeholder="Provincia" id="inprov" onBlur={() => proviEstado()}/></div>
			  </li>
			  <li className="cell-input">
				<div className="cell-body"><label className="float-label" htmlFor="indistrit">Distrito<i className='pi pi-search' style={{ color: '#ff0000', float :'right', cursor:'pointer'}} onClick={() => setEditarUbica('')} tooltip="Ingresar ubicación manualmente"/>
				<span style={{fontWeight:'normal', float:'right', marginRight:'4px'}}>(búsqueda automática)</span></label>
				<Dropdown value={contacto.distrito} options={distritos} onChange={onDistritoChange} placeholder="Distrito" id="indistrit" onBlur={() => distriEstado()}/></div>
			  </li></Fragment>:
			  <li className="cell-input">
				<div className="cell-body"><label className="float-label" htmlFor="inubicacion">Ubicación<i className='pi pi-pencil' style={{ color: '#ff0000', fontSize: '18px', marginTop:'-6px', float :'right', cursor:'pointer'}} onClick={() => setEditarUbica('editar')} tooltip="Ingresar ubicación manualmente"/>
				<span style={{fontWeight:'normal', float:'right', marginRight:'4px'}}>(búsqueda manual)</span></label>
				<AutoComplete id="inubicacion" value={ubicacion} suggestions={ubicacionesf} completeMethod={filtroubicaciones} field="label" size={30} placeholder="Escribe el nombre de tu distrito" minLength={1} onChange={ubicacionChange}  onBlur={() => acEstado()} style={{'background':campos||departamento!==''?'#ffffff':'#fff2ef'}}/></div>
				<small id="inubicacion-help" className="p-d-block">{ selectedCountry ?  'Busqueda de distritos en '+user.Pais :'Busqueda de distritos en todo el mundo'}</small>
			  </li>}
			  {editarCat ? <Fragment>
			  <li className="cell-input">
				<div className="cell-body"><label className="float-label" htmlFor="incategoria">Categoría general</label>
				<Dropdown value={categoria} options={categorias} onChange={onCategoriaChange} id="incategoria" placeholder="Categorías" /></div>
			  </li>
			  <li className="cell-input">
				<div className="cell-body"><label className="float-label" htmlFor="insubcategoria">Sub Categorías general</label>
				<Dropdown value={subcategoria} options={subcategorias} onChange={onSubCategoriaChange} id="insubcategoria" placeholder="Sub-Categorías" /></div>
			  </li>
			  <li className="cell-input">
				<div className="cell-body"><label className="float-label" htmlFor="incategoriaitem">Categoría de Contacto<i className='pi pi-search' style={{ color: '#ff0000', float :'right', cursor:'pointer'}} onClick={() => setEditarCat('')} tooltip="Ingresar ubicación manualmente"/>
				<span style={{fontWeight:'normal', float:'right', marginRight:'4px'}}>(búsqueda automática)</span></label>
				<Dropdown value={catID} options={categoriasID} onChange={onHojaChange} id="incategoriaitem" placeholder="Categorías de Contacto" /></div>
			  </li></Fragment>:
			  <li className="cell-input">
				<div className="cell-body"><label className="float-label" htmlFor="incategoriaAC">Categoría<i className='pi pi-pencil' style={{ color: '#ff0000', fontSize: '18px', marginTop:'-6px', float :'right', cursor:'pointer'}} onClick={() => setEditarCat('editar')} tooltip="Ingresar categoría manualmente"/>
				<span style={{fontWeight:'normal', float:'right', marginRight:'4px'}}>(búsqueda manual)</span></label>
				<AutoComplete id="incategoriaAC" value={categoriaAC} suggestions={categoriasf} completeMethod={filtrocategoriasAC} field="label" size={30} placeholder="Escribe el nombre de la categoría" minLength={1} onChange={categoriaACChange}  onBlur={() => acCatEstado()} style={{'background':campos||departamento!==''?'#ffffff':'#fff2ef'}}/></div>
				<small id="incategoriaAC-help" className="p-d-block">{ categoriaAC.padre ?  'Busqueda de especialidades en '+categoriaAC.padre :'Busqueda de todas las categorías'}</small>
			  </li>}	  
			  <li className="cell-input">
				<div className="cell-body"><label className="float-label" htmlFor="indireccion">Dirección</label>
				<InputText id="indireccion" placeholder="Dirección del inmueble o local comercial" value={contacto.direccion} onChange={(e) => dispatch({ type: types.contacto, payload: {...contacto, direccion:e.target.value} }) } onBlur={() => direccionEstado()}/></div>
			  </li>
			  <li className="cell-input">
			  <div className="cell-body">
				<div className="cell-body" style={{width:'48%',float:'left'}}><label className="float-label" htmlFor="incel">Teléfono móvil</label>
				<InputText id="incel" value={contacto.celular} onChange={onCelularChange} aria-describedby="incel-help"  onBlur={() => movilEstado()}/><small id="incel-help" className="p-error p-d-block">{ (celularDisp === 'ocupado' ?  contacto.celular+' ya está registrado.':'')+(celularValido ?  'Este número no es válido':'')}</small></div>
				<div className="cell-body" style={{width:'48%',float:'right'}}><label className="float-label" htmlFor="intel">Teléfono fijo</label>
				<InputText id="intel" value={contacto.telefono} onChange={onTelefonoChange} aria-describedby="intel-help"  onBlur={() => TelefonoEstado()}/><small id="intel-help" className="p-error p-d-block">{ contacto.telefono ?  'Use un teléfono fijo':''}</small></div>
			  </div>
			  </li>
			  <p className="signin-link" style={{marginTop:'3em'}}>Después de crear el contacto, puedes agregar imágenes y otros detalles.</p>
			</ul>
			{botonEdit}
			</Fragment>:'';
const editPerfil = preview==='no' ?<Fragment>
			<ul className="input-fields container-box">
			   <li className="cell-input">
			   <i className='pi pi-times' style={{right: '0', fontSize:'1.5em', cursor:'pointer', position:'absolute'}} onClick={() => setEditar('')}/>
			   <h2>Completa tu perfil</h2> 
			  </li>					
			  <li className="cell-input1">
				<div className="cell-body"><label className="float-label" htmlFor="inweblocal">Dirección web en Servicontacto</label>  
				<InputTextarea id="inweblocal"  value={contacto.userweb} onChange={onUserWebChange} onBlur={() => userWebEstado()} autoResize={true} rows={1} cols={30}/></div>
				<small id="inweblocal-help" className="p-error p-d-block">Es un nombre de usuario que representa tu contacto</small>
			  </li>
			  <li className="cell-input1"> 
				<Checkbox inputId="binary" checked={(contacto.usoweb==='2')?true:false} onChange={checkp} />
				<span htmlFor="binary" style={{verticalAlign:'middle',marginLeft:'0.7em'}} >Perfil individual interno privado / no listado</span>	
			  </li>			  
			  <li className="cell-input1" style={{marginTop:'2em'}}>
				<div className="cell-body"><label className="float-label" htmlFor="inweb">Dirección de página web</label>
				<InputText id="inweb"  value={contacto.web} onChange={(e) => dispatch({ type: types.contacto, payload: {...contacto, web:e.target.value} }) }/></div>
				<small id="inweb-help" className="p-error p-d-block">Si tienes página web, escribe tu dirección web aquí</small>
			  </li>
			  <li className="cell-input1">
				<div className="cell-body"><label className="float-label" htmlFor="inweb">Página web de Servicontacto</label></div>
				<div style={{'display':'list-item', background:'white', padding:'0.35em 1em', /*border:'1px solid #e0e0e0',*/ boxShadow:'rgb(0 0 0 / 35%) 0px 2px 3px 0px', borderRadius:'8px'}}>Si no tienes aún página web, nosotros te podemos ofrecer una alternativa</div>
			  </li>		
			  <li className="cell-input1"> 
				<Checkbox inputId="binary" checked={['1','4'].includes(contacto.usoweb)?true:false} onChange={checka} />
				<span htmlFor="binary" style={{verticalAlign:'middle',marginLeft:'0.7em'}} >Crear y publicar mi página web</span>	
				<div style={{'display':'list-item', background:'white', padding:'0.35em 1em', boxShadow:'rgb(0 0 0 / 35%) 0px 2px 3px 0px', borderRadius:'8px'}}>
				La página web, será publicada en Internet, únicamente cuando todos los campos y fotos que se requieren sean completados</div>
			  </li>							
			  <p className="signin-link" style={{marginTop:'2em'}}>Cuando termines de llenar este formulario, tendrás tu página web.</p>
			</ul>
			{botonEdit}
			</Fragment>
			:'';

const menuModulo = 
	<div className="layout-sidebar layout-sidebar-light p-fluid" style={{width:'100%', position:'unset' }} >
		{editar || preview==='no'?'':
		<div>
		  <span className="menuContactosH">
			<span className="textoTruncado">{contacto.Nombre_Comercial}</span>
			<i className="pi pi-chevron-left icoCombo" onClick={() => dispatch({ type: types.contacto, payload: {editar:''} })}></i>
		  </span>
		  {EdicionMenu}
		</div>
		}
		<div className="formulario">
		  <div className="reg-content-wrapper single" style={{width:'auto'}}>
		  <div className="join-form-container form-container">
		  <fieldset className="altoPC">
			{editPanel}
			{editPerfil}
		  </fieldset>
		  </div>
		  </div>
		</div>  
	</div>;
const menuModulo1 = 

	<div className="layout-sidebar layout-sidebar-light p-fluid" style={{position:'fixed', width:'320px', left:'0', height:'auto' }} >
		{editar || preview==='no'?'':
		<div>
		  <span className="menuContactosH">
			<span className="textoTruncado">{contacto.Nombre_Comercial}</span>
			<i className="pi pi-chevron-left icoCombo" onClick={() => dispatch({ type: types.contacto, payload: {editar:''} })}></i>
		  </span>
		  {EdicionMenu}
		</div>
		}
		<div className="formulario">
		  <div className="reg-content-wrapper single" style={{width:'auto'}}>
		  <div className="join-form-container form-container">
	  
		  <fieldset className="altoPC" style={{ marginInlineEnd:'0', paddingInlineEnd:'0', display: editar || preview==='no' || contacto.editar ?'inherit':'none'}}>
			<ScrollPanel style={{width: '100%', height:'75vh'}}>
			{editPanel}
			{editPerfil}
			</ScrollPanel>
		  </fieldset>
		  
		  </div>
		  </div>
		</div>  
	</div>

const menuMovil = 
	<button className="p-link layout-menu-button movil" onClick={()=> {let caso=document.getElementById("menuMovilEdit").classList.toggle('oculto'); console.log(caso); setMenu(caso)}} type="button" style={{float:'right', padding:'0 0.3em', fontSize:'2em', margin:'0',color:'#999', position:'relative', width:'36px'}}>
	  {menu?<span className="pi pi-bars"/>:<span className="pi pi-times"/>}
	</button>;

return (
<Fragment> 
<Dialog className="ancho" header={Encabezado} visible={mostrar?true:false} style={{width: '50vw'}} modal onHide={() => {setMostrar('');setSocio({...socio, mostrar:''});}}>
{socio.mostrar?<div>{asociados}</div>:<UploadForm tipo='documentos' Contacto_ID={contacto.Contacto_ID} imagen={contacto.tipo} uploadCase={preview==='ver'?'SubirFotoPerfil':uploadWeb} callBackOnSuccessUpload={SuccessUpload}/>}
</Dialog>
<div className="p-col-3 escritorio oculto" style={{padding:'0'}}>	
	<div style={{width:'23.8vw', position:'fixed'}}>
	{menuModulo}
	</div> 
</div>
<div className="p-col-3 escritorio" style={{padding:'0', width:'320px'}}>
	{menuModulo1}
</div>
<div className="p-col-8 negocio ancho" style={{paddingTop:'0', margin:'0 auto', width:'calc(91.6667% - 320px)'}}>
	<div className="p-col-12 movil" style={{display: 'flex', background:'#fff', padding:'5px', boxShadow:'#00000059 1px 1px 1px'}}>
		<h1 className="h1M" style={{width:'calc(100% - 36px)', textAlign:'center', margin:'5px'}}>{mando.select}</h1>{menuMovil}
	</div>

	<div className="rutaweb" style={{visibility:(!!ubicacion.Pais || !!categoriaAC.abuelo)?'visible':'hidden'}} ><span >{ubicacion?.Pais}{i}{ubicacion.Departamento}{i}{ubicacion.Provincia?ubicacion.Provincia:ubicacion.Distrito}{i}{categoriaAC.abuelo}{i}{categoriaAC.padre}{i}{categoriaAC.categoria}</span>
	</div>
	{mando.select === 'Panel'	? <Panel 	  contacto={contacto} router='no' revisar={revisar} editPanel={editPanel} fotoSetting={fotoSetting} fotoCombo={fotoCombo} fotoOpcion={fotoOpcion} editar={editar} elegir={elegir}/>:
	mando.select === 'Contacto' ? <Superpanel contacto={contacto} router='no' revisar={revisar} editorweb={editorweb} especialidad={especialidad} preview={preview} contactoWeb={contactoWeb} selectorCat={selectorCat} editPerfil={editPerfil} editorweb1={editorweb1} categorias={especialidad1.length} subContacto={subContacto} mapa={mapa}/>:
	mando.select === 'Web'		? <WebSite 	  contacto={contacto} router='no' revisar={revisar} editorweb={editorhtml} especialidad={especialidad} preview={preview} contactoWeb={contactoWeb} selectorCat={selectorCat} editPerfil={editPerfil} fotoIco={fotoIco1} colorWeb={colorWeb} cabecera={cabecera} banners={banners} className="layout-Seeker"/> :
	mando.select === 'Consultor'? <Consultor  contacto={contacto} especialidad={especialidad} />:
	mando.select === 'Calendario'? <Calendario contacto={contacto} />:
	mando.select === 'Visitas'	 ? <Stats contacto={contacto} />:
	mando.select === 'Calificación'	 ? <Score contacto={contacto} />:''}
</div> 
<div className="p-col-1 escritorio" style={{paddingTop:'0', width:'50px'}}>
</div>
<div id="menuMovilEdit" className="menuMovil movil oculto layout-sidebar layout-sidebar-light" style={{background:'#fff', left:'0', boxShadow:'rgb(0 0 0 / 35%) 0px 1px 5px 0px'}}>
	<span className="menuContactosH">
		<span className="textoTruncado">{contacto.Nombre_Comercial}</span>
		<i className="pi pi-chevron-left icoCombo" onClick={() => dispatch({ type: types.contacto, payload: {editar:''} })}></i>
	</span>
		{EdicionMenu}
		<span className="menuContactosH" onClick={() => dispatch({ type: types.contacto, payload: {editar:''} })}>Regresar</span>
</div> 
</Fragment>  
); 

}





export const Score = (props) => {

const [store ] = useContext(StoreContext);
const {contacto, ruta, img, score} = store;
const val = {v1:Math.round(contacto.alcance), v2:Math.round(contacto.comm), v3:Math.round(contacto.tiempo), v4:Math.round(contacto.costo)};
const [coments, setComents] = useState([]);
const [paso, setPaso] = useState(1);
const fotoF = ruta+ img.fotoF;
const fotoM = ruta+ img.fotoM;
const logo = ruta+ img.logo;
console.log(contacto.alcance);
console.log(score.alcance);

 
useEffect(() => {
comentarios(contacto.Contacto_ID);
//eslint-disable-next-line
}, []);

const comentarios = async (Contacto_ID) => {
    let resource = await InfoData('leeComentarios', Contacto_ID);	
	setComents(resource.data);
	console.log(resource.data);
   }


return (
<div className='p-fluid panelContacto'>
	<div id="Calificacion" style={{width:'100%',minHeight: '370px'}}>
		<h1 style={{marginBottom:'15px' }}>Calificación desde usuarios</h1>
		<div style={{background:'#f9f9f9', padding:'10px',/*, height:'10em'*/ display:'inline-block'}}>
			<img src={ contacto.tipo >= '3'?contacto.Foto?ruta + contacto.Foto:(contacto.tipo === '3' ? fotoM : fotoF):contacto.logo?ruta + contacto.logo:logo} alt="Foto de perfil" style={{background:contacto.color, padding:contacto.tipo ==='2'?'0.5em':'0', width: contacto.tipo >= '3'?'15%':'210px', marginBottom:'1em'}}/>
			<div className='ancho' style={{float:'right', width:contacto.tipo >= '3'?'85%':'calc(100% - 210px)', padding: '0 0.7em'}}><h2 style={{display:'flex', flexWrap:'wrap'}}>{contacto.Nombre_Comercial}<span style={{marginLeft:'5px', fontSize:'17px', color:'#006aa7' }}>(<i className='fa fa-star' style={{color:'#fecc00', fontSize:'12px', position:'relative', top:'-2px'}}/>{contacto.alcance +' - '+contacto.votos+' opiniones' })</span></h2>
			<>{(Math.round(100*contacto.positivo/(contacto.positivo+contacto.negativo+contacto.neutral)*10)/10 || 0)+' % comentarios positivos'}</>
			<p style={{float:'right', width:'64%', marginTop: '3em'}}>{'Según nuestra base de datos, '+contacto.Nombre_Comercial+' es parte de Servicontacto desde '+new Date(contacto.fecha*1000).toLocaleDateString("es-PE", {month: "long", day: "numeric", year:'numeric'})}</p>
			</div>
		</div>
		
		<h2 style={{marginTop:'20px', whiteSpace:'nowrap'}}>Valoración de comentarios</h2>
		<div style={{padding:'10px', display:'flex', flexWrap:'wrap', fontSize:'12px', clear:'both'}}>
				<div className='ancho' style={{width:'33.33%' }}>		
					<ul className="columnas1" style={{clear:'both', padding:'10px', display:'contents'}}>
					<li className='lst'><Rating style={{float:'left', minWidth:'70px'}} value={val.v1} cancel={false}/><span className='rank'>{contacto.alcance}</span><div style={{position:'relative', top:'9px', width:'calc(100% - 100px)', lineHeight:'1.1'}}>{score.alcance}</div></li>
					<li className='lst'><Rating style={{float:'left', minWidth:'70px'}} value={val.v2} cancel={false}/><span className='rank'>{contacto.comm}</span><div style={{position:'relative', top:'9px', width:'calc(100% - 100px)', lineHeight:'1.1'}}>{score.comunica}</div></li>
					<li className='lst'><Rating style={{float:'left', minWidth:'70px'}} value={val.v3} cancel={false}/><span className='rank'>{contacto.tiempo}</span><span>{score.tiempo}</span></li>
					<li className='lst'><Rating style={{float:'left', minWidth:'70px'}} value={val.v4} cancel={false}/><span className='rank'>{contacto.costo}</span><span>{score.costo}</span></li>		
					</ul>
				</div>		
				
				<div className='ancho bordecal' style={{ textAlign:'center'}}>
					<div style={{width:'33%',position:'relative', top:'31%' }}>
						<i className='fa fa-plus-circle' style={{color:'#54a754'}}/>
						<span style={{margin:'0 0.4em', fontWeight:'bold'}}>{contacto.positivo}</span>
						<div style={{clear:'both'}}></div>	
						<span>Positivos</span>
					</div>	
					<div style={{width:'34%',position:'relative', top:'31%'}}>
						<i className='fa fa-stop-circle' style={{color:'#333333'}}/>
						<span style={{margin:'0 0.4em', fontWeight:'bold'}}>{contacto.neutral}</span>
						<div style={{clear:'both'}}></div>	
						<span>Neutrales</span>
					</div>	
					<div style={{width:'33%',position:'relative', top:'31%'}}>
						<i className='fa fa-minus-circle' style={{color:'#c0503a'}}/>
						<span style={{margin:'0 0.4em', fontWeight:'bold'}}>{contacto.negativo}</span>
						<div style={{clear:'both'}}></div>	
						<span>Negativos</span>
					</div>
					<div style={{width:'100%', position:'relative', top:'15%'}}>	
						<span>Comentarios de los últimos 12 meses</span>
					</div>	
				</div>
				
				<div className='ancho bordecal'>
					<span className='mas' style={{width:'100%', marginBottom:'15px', textAlign:'center'}}>ver todos los comentarios</span>
					<div style={{width:'10%', textAlign:'center'}}>
					{coments.[coments.length-paso]?.score===3?<i className='fa fa-plus-circle' style={{color:'#54a754'}}/>:
					 coments.[coments.length-paso]?.score===2?<i className='fa fa-stop-circle' style={{color:'#333333'}}/>:
					 coments.[coments.length-paso]?.score===1?<i className='fa fa-minus-circle' style={{color:'#c0503a'}}/>:''}
					</div>	
					<div style={{width:'90%', display:coments.length>0?'block':'none'}}>
						<span style={{color:'#54a754'}}>{coments.[coments.length-paso]?.comentario}</span>
						<p style={{marginTop:'5px', fontSize:'11px'}}>{new Date(coments.[coments.length-paso]?.estampa*1000).toLocaleDateString("es-PE", {month: "long", day: "numeric", year:'numeric'})}</p>
						<p style={{marginTop:'5px', fontSize:'11px', textAlign:'center'}}>
						 <i className='fa fa-circle' onClick={() => setPaso(1)} style={{cursor:'pointer', fontSize:'8px', color:paso===1?'#006aa7':'#929292', margin:'0 1em'}}/>
						 <i className='fa fa-circle' onClick={() => setPaso(2)} style={{cursor:'pointer', fontSize:'8px', color:paso===2?'#006aa7':'#929292', margin:'0 1em'}}/>
						 <i className='fa fa-circle' onClick={() => setPaso(3)} style={{cursor:'pointer', fontSize:'8px', color:paso===3?'#006aa7':'#929292', margin:'0 1em'}}/>	 
						 <i className='fa fa-circle' onClick={() => setPaso(4)} style={{cursor:'pointer', fontSize:'8px', color:paso===4?'#006aa7':'#929292', margin:'0 1em'}}/>	 
						 <i className='fa fa-circle' onClick={() => setPaso(5)} style={{cursor:'pointer', fontSize:'8px', color:paso===5?'#006aa7':'#929292', margin:'0 1em'}}/>	 
						</p>
					</div>
				</div>
				
				
		</div>
	</div>	
</div>
);

}