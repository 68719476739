import React, { useContext, useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { InfoData } from "./InfoData";
import { StoreContext } from "./store/StoreProvider";
import { types } from "./store/StoreReducer";
import classNames from "classnames";
import axios from "axios";
const host="https://servicontacto.com/";
const apiLogin = host+"supercontacto/login.php";
const config = { headers: {'content-type': 'multipart/form-data'}};
const formData = new FormData();

export const Login = (props) => {


  return (
    <div className="p-fluid dashboard">
      <div id="screen-banner" className="escritorio marco reg">
        <section className="envoltura">
          <div id="contenido">
            <div className="imagenizq">
              <h2 id="textoTitulo">
                Todo lo que necesitas para hacer de tu vida m&aacute;s
                productiva
              </h2>
              <p id="textoSubTitulo">
                Lo encontrar&aacute;s aqu&iacute; al alcance de un clic
              </p>
            </div>
          </div>
          <div id="logSC">
            <LogForm className={"join-form-wrapper form-wrapper"} />
          </div>
        </section>
      </div>
      <div className="movil marco" style={{padding:'5em 10px'}}>
      <LogForm className={"join-form-wrapper form-wrapper movil"} />
      </div>

    </div>
  );
};



export const LogForm = (props) => {
  const [store, dispatch] = useContext(StoreContext);
  const {zona, home, logo, ruta} = store;
  const [checked, setChecked] = useState(false);
  const [entrada, setEntrada] = useState("");
  const [password, setPassword] = useState("");
  const [pwdtipo, setPwdtipo] = useState("password");
  

  useEffect(() => {
	ajuste();
    document.getElementById("inpwd").type = "password";
	//eslint-disable-next-line  
  }, []);
  
  
const ajuste = (normal) => { 
	if(props.ajuste==='1'){
	if(normal==='1'){
	document.getElementsByClassName("p-dialog-header")[0].style.padding='0.3rem 0.3rem 1.5rem 1.5rem';
	document.getElementsByClassName("p-dialog-content")[0].style.padding='0 1.5rem';
	document.getElementsByClassName("p-dialog-content")[0].style.background='#ffffff';	
		}else{
	document.getElementsByClassName("p-dialog-header")[0].style.padding='0';
	document.getElementsByClassName("p-dialog-content")[0].style.padding='0';
	document.getElementsByClassName("form-wrapper")[0].style.border='0';
	document.getElementsByClassName("form-wrapper")[0].style.background='transparent';
	document.getElementsByClassName("p-dialog-content")[0].style.background='transparent';		
		}
	}
  }
  

const entradaEstado = async (e) => {
    reconocer(entrada);
};

const reconocer = async (e) => {
    let mail = validarEmail(e);
    let cell = validarCelular(e);
    let tipo = "";
    let Usuario = "";
    let Email = "";
    let Celular = "";
    let db = "";
    if (mail === "valido") {
      db = await disponibilidadEmail(e);
      if (db === "ocupado") {
        Email = e;
      }
      tipo = 1;
    } else {
      if (cell === "valido") {
        db = await disponibilidadCelular(e);
        if (db === "ocupado") {
          Celular = e;
        }
        tipo = 2;
      } else {
        db = await disponibilidadUsuario(e);
        if (db === "ocupado") {
          Usuario = e;
          }
        tipo = 3;
      }
    }
    let retorno = { tipo, db, Usuario, Email, Celular };
    return retorno;
};

  
const entradaChange = (e) => {
    setEntrada(e.target.value);
  };

const validarEmail = (valor) => {
    let validez = "";
    if (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,4})*(\.\w{2,3})+$/.test(valor) || valor === "" ) {
      validez = "valido";
    } else {
      validez = "invalido";
    }
    return validez;
  };

const validarCelular = (valor) => {
    let validez = "valido";
    if (isNaN(valor)) {
      validez = "invalido";
    } else {
      if (valor.length > 8 || valor === "") {
        validez = "";
      } else {
        validez = "invalido";
      }
    }
    return validez;
	};

const disponibilidadUsuario = async (user) => {
	let resource = await InfoData('disponibilidadUsr', {usuario:user,celular:'',email:''});
    return resource.data.disponibilidad;
	};

const disponibilidadEmail = async (user) => {
	let resource = await InfoData('disponibilidadUsr', {usuario:'',celular:'',email:user});
    return resource.data.disponibilidad;
	};

const disponibilidadCelular = async (user) => {
	let resource = await InfoData('disponibilidadUsr', {usuario:'',celular:user,email:''});
    return resource.data.disponibilidad;
	};

const verPwd = (e) => {
    if (pwdtipo === "password") {
      document.getElementById("inpwd").type = "text";
      setPwdtipo("text");
    } else {
      document.getElementById("inpwd").type = "password";
      setPwdtipo("password");
    }
};

const pwdClass = classNames({
    "pi pi-eye": pwdtipo === "password",
    "pi pi-eye-slash": pwdtipo === "text",
  });

  
const tecla =(event) => {
	if(event.key === 'Enter')
	{ iniciar();  }
  } 
  
  
const inicioServer = async (tipo, password, checked) => {
    formData.append("operacion", 1);
    formData.append("inT1", tipo.Usuario.trim());
    formData.append("inT1a", tipo.Email.trim());
    formData.append("inT1b", tipo.Celular.trim());
    formData.append("inT2", password);
    formData.append("equipo", checked ? 1 : 0);
    return await axios.post(apiLogin, formData, config);
  };  

const iniciar = async (e) => {
    let tipo = await reconocer(entrada);
    console.log(tipo);
 //   let resource = await InfoData('login', {tipo:tipo, password:password,checked:checked});
	let resource = await inicioServer(tipo,password,checked);
    let perfil = resource.data;
    console.log(resource.data);

    if (perfil !== "0") {
      //alert('Bienvenido ' +perfil.Nombres);
      let ubicacion = {
        Departamento: perfil.Departamento,
        Departamento_ID: perfil.Departamento_ID?perfil.Departamento_ID:"",
        Distrito: perfil.Distrito,
        Distrito_ID: perfil.Distrito_ID?perfil.Distrito_ID:"",
        Pais: perfil.Pais,
        Pais_ID: perfil.Pais_ID?perfil.Pais_ID:'',
        Provincia: perfil.Provincia,
        Provincia_ID: perfil.Provincia_ID?perfil.Provincia_ID:'',
        label: perfil.Distrito + ", " + perfil.Provincia + " - " + perfil.Departamento + " - " + perfil.Pais, value: "",
      };
      dispatch({ type: types.ubicacion, payload: ubicacion });
        console.log(perfil.Pais_ID);
        console.log(zona.data?.Pais_ID);
      if( perfil.Pais_ID !== zona.data?.Pais_ID){
      dispatch({ type: types.ciudades, payload: [{ value: perfil.Departamento_ID, label: perfil.Departamento, id: perfil.Departamento_ID, },], });
      }
      if(perfil.Departamento_ID!==zona.data?.Departamento_ID){
            if(perfil.Pais_ID==='89'){
            dispatch({ type: types.provincias, payload:[{ value: perfil.Provincia_ID, label: perfil.Provincia, id: perfil.Provincia_ID, },],});
            }else{
            dispatch({ type: types.distritos, payload: [{ value: perfil.Distrito_ID, label: perfil.Distrito, id: perfil.Distrito_ID, },], });
            }
      }
      dispatch({ type: types.authLogin, payload: {...perfil, login:true} });
      localStorage.setItem('user',JSON.stringify(perfil));
	  ajuste('1');	  
      dispatch({ type: types.contactos, payload: [{ Contacto_ID: "0" }] });
      //let db2 = await InfoData(types.alertas, perfil.Usu_ID);
      //dispatch({ type: types.alertas, payload: db2.alertas });
      let db3 = await InfoData(types.contactosUser, perfil.Usu_ID);
	  dispatch({ type: types.contacto, payload: [] });
      dispatch({ type: types.contactosUser, payload: db3.contactos });
      var offset = checked ? 57720000 : 3600000;
      var equipo = checked ? 1 : 0; 
      var expira = new Date((new Date()).valueOf() + offset).toUTCString();
      var ip='192.168.0.1';
      document.cookie = "Usuario="+ip+'@'+ perfil.Usu_ID + '@'+ perfil.Modo + '@'+ equipo + "@; expires=" + expira;


return <Redirect to={home}></Redirect>

    } else {
      if (tipo.db === "ocupado") {
        alert("Tu contraseña es incorrecta");
      } else {
        alert(entrada + " no está registrado");
      }
    }
  };


  return (
    <div className="reg-content-wrapper single" style={{ maxWidth: "400px" }}>
      <div className="join-form-container form-container">
        <div className="join-form-wrapper form-wrapper">
          <div className="alerts-cont" style={{ textAlign: "center", position: "relative", top:'-1em', display:props.ajuste?'block':'none' }}>
		  <Link to='/'><img src={ruta+logo} height='47' alt={"servicontacto.com"}/></Link>
		  </div>
          <div className="loading-indicator hidden"></div>
          <fieldset>
            <legend>Únete a Supercontacto</legend>
            <ul
              className="input-fields container-box"
              style={{ listStyle: "none", paddingInlineStart: "0px" }}
            >
              <li className="cell-input1">
                <div className="cell-body" style={{ display: "grid"}}>{" "}<label className="float-label" htmlFor="user">
                    Correo electrónico, teléfono o nombre de usuario</label>
                  <InputText id="user" value={entrada} onChange={entradaChange} onBlur={() => entradaEstado()}/>
                </div>
              </li>
              <li className="cell-input1">
                <div className="cell-body" style={{ display: "grid"}}><label className="float-label" htmlFor="inpwd">
                    <span style={{ float: "right", fontWeight: "lighter" }}>
                      <Link to="/usuario/recovery">¿Has olvidado tu contrase&ntilde;a?</Link></span>
                    Contraseña</label>
                  <span className="p-input-icon-right">
                    <i className={pwdClass} style={{ right: "0.5em", cursor: "pointer", top: "0.8em", fontSize: "1.5em", color: "#ddd", }}
                      onClick={() => verPwd()} />
                    <InputText id="inpwd" value={password} onChange={(e) => setPassword(e.target.value)} onKeyPress={(e) => tecla(e)}/>
                  </span>
                </div>
              </li>
              <li className="cell-input1">
                <Checkbox inputId="binary" checked={checked}  onChange={(e) => setChecked(e.checked)}/>
                <span htmlFor="binary" style={{ verticalAlign: "middle", marginLeft: "0.7em" }}>
                  Equipo propio</span>
              </li>
            </ul>
            <Button className="logboton join-btn" label="Iniciar sesión" onClick={() => iniciar()} />
          </fieldset>
          <p className="signin-link">{" "}{"¿No eres miembro de Servicontacto? "}{" "}
            <Link to="/usuario/registro">Reg&iacute;strate aqu&iacute;</Link>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};
