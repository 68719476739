import classNames from 'classnames';
import {Route, Switch, useLocation} from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import {ScrollPanel} from 'primereact/scrollpanel';
import {Home} from './Home';
import {AppTopbar /*, obtenerCookie*/} from './AppTopbar';
import {AppFooter} from './AppFooter';
import {AppMenu} from './AppMenu';
import {Listado} from './Listado';
import {Contacto} from './Contacto';
import {Billing} from './Billing';
import {Perfil} from './Perfil';
import {Contactos} from './Contactos';
import {Puntuacion} from './Puntuacion';
import {Feedback} from './Feedback';
import {Categoria} from './Categoria';
import {Ventana} from './Ventana';
import {Pagina} from './Pagina';
import {Registro} from './Registro';
import {Login} from './Login';
import {Notificacion} from './Notificacion';
import {Recovery} from './Recovery';
import {Site} from "./Site";
import {InfoData} from "./InfoData";
import { Supermenu } from './Supermenu';
import { Agreement } from './legal/Agreement';
import { About } from './legal/About';
import {StoreContext} from './store/StoreProvider';
import {types}  from './store/StoreReducer';
import PrivRoute from './routers/PrivRoute';
import PublicRoute from './routers/PublicRoute';
import React, { useContext, useState, useRef, useEffect, Fragment } from 'react';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './layout/layout.css';
import './layout/css_sc.css';
import './layout/flags.css';
import './layout/log.css';
import './layout/tema.css';
import './layout/font-awesome-4.3.0/css/font-awesome.css';
import tratoA from './assets/images/TratoBPT-A.png';
import tratoB from './assets/images/TratoBPT-B.png';

import 'primereact/resources/primereact.min.css';
//import './layout/theme.css';



export const App = () => {

    const [store, dispatch] = useContext(StoreContext);
    const {user, alertas, home, zona} = store;
    const [layoutMode/*, setLayoutMode*/] = useState('overlay');
    const [layoutColorMode/*, setLayoutColorMode*/] = useState('light');
 //   const [inputStyle, setInputStyle] = useState('outlined');
 //   const [ripple, setRipple] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const sidebar = useRef();
 //  const mod =  obtenerCookie('Usuario')?.split('@')[2];
    const [subirC, setSubirC] = useState('hidden');  
	const location = useLocation();


    let menuClick = false;


    useEffect(() => {
    //    console.log(obtenerCookie('Usuario')?.split('@')[2]);
         if (sidebarActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    //eslint-disable-next-line
    }, [sidebarActive]);


    useEffect(() => {
        window.onscroll = () => {
            if(window.scrollY > window.innerHeight - 100) {
                setSubirC("visible");
             }else setSubirC("hidden");
        }
		
	visita();
    //eslint-disable-next-line
      }, []);
	  
	  
	const visita = async () => {
	console.log(tipo);
	let db1 = await InfoData(types.visita, tipo); 	
	console.log(db1);	
	}
	  
	const contador = (texto) => {
	  let cadena = texto.slice(0,-1);
	  var indices = [];	  
	  for(var i = 0; i < cadena.length; i++) {
		if (cadena[i] === '/') indices.push(i);
	  }
	return indices.length;
	}
	const barras = contador(location.pathname);
	const tipo = barras===1?(location.pathname.length>1?2:barras):barras===2?4:barras;	
 
    const menuPublico = [{label: 'Iniciar sesión', to: '/usuario/login'},
                         {label: 'Registrate',  to: '/usuario/registro'}]; 

    var menuSesion = [{label: 'Perfil de usuario',  to: '/'+user?.Nombres?.split(' ').join('-')+'/perfil'},
						{label: 'Contactos web', to: '/'+user?.Nombres?.split(' ').join('-')+'/contactos'},
                        {label: 'Notificaciones', to: '/'+user?.Nombres?.split(' ').join('-')+'/notificacion', badge: alertas?alertas.length:'' },
						{label: 'Salir',  to: home, command:()=>{ dispatch({type:types.authLogout});} }]; 


    const modo = (menu) => {
       if(user.Modo==='1'){
        let salir = menu.pop(); 
            menu.push({label: 'Administrador', to: '/'+user?.Nombres?.split(' ').join('-')+'/ventana', command:()=>{ document.getElementsByClassName('p-link layout-menu-button')[0].classList.toggle('oculto');}}); 
            menu.push(salir);}
    return menu;
    }    

    /*
    const login = (id) => {
    let estado=(id !== undefined  || id !== null)?true:false;
       return estado;
    }


    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

 
    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }
    */

    const onWrapperClick = (event) => {
        if (!menuClick && layoutMode === "overlay") {
            setSidebarActive(false);
        }
        menuClick = false;
    }

    const onToggleMenu = (event) => {
        menuClick = true;
/*        console.log(sidebarActive);
setSidebarActive(!sidebarActive);
setLayoutMode(sidebarActive ? "overlay":"static");*/
         setSidebarActive((prevState) => !prevState);
  //       setLayoutMode(sidebarActive ? "overlay":"static");
//console.log(sidebarActive);
        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }
	
	
    const ventana = () => {
    document.getElementById('menuUser').classList.add('oculto')		
    document.getElementById('superMenu').style.display='none';
    }	


    const onMenuItemClick = (event,item) => {
        if (!event.item.items && layoutMode === "overlay") {
            setSidebarActive(false);
										// document.getElementById('boto').click();
        }
		if(event.item.label!=='Salir'){
		if(event.item.label!=='Páginas web'){ventana();    }}
    }
    const menu = [
            {label: 'Home', icon: 'pi pi-fw pi-home', command: () => {window.location = '/'}},
            {label: 'Recovery', icon: 'pi pi-fw fas fa-comments-dollar', to:'/usuario/recovery'},
            {label: 'Login', icon: 'pi pi-fw fas fa-comments-dollar', to:'/usuario/login'}, 
            {label: 'Categoria', icon: 'pi pi-fw fas fa-comments-dollar', to:'/configurar/categoria'},
            {
                label: 'Menu Colors', icon: 'pi pi-fw pi-align-left',
                items: [
                    {label: 'Dark', icon: 'pi pi-fw pi-bars',  command: () => this.setState({layoutColorMode: 'dark'}) },
                    {label: 'Light', icon: 'pi pi-fw pi-bars',  command: () => this.setState({layoutColorMode: 'light'}) }
                ]
            }
    ];




    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const isSidebarVisible = () => {
        return sidebarActive;
    };

    const logo = layoutColorMode === 'dark' ? tratoA : tratoB;

/*    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-active': sidebarActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false
    });*/

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': !sidebarActive && layoutMode === 'static',
        'layout-overlay-sidebar-active': sidebarActive && layoutMode === 'overlay',
      //  'layout-mobile-sidebar-active': this.state.mobileMenuActive
    });

    const sidebarClassName = classNames('layout-sidebar', {
        'layout-sidebar-dark': layoutColorMode === 'dark',
        'layout-sidebar-light': layoutColorMode === 'light'
    });




    const subirClassName = classNames("fas fa-angle-up  et_pb_scroll_top et-pb-icon", {
      'et-visible': subirC === 'visible',
      'et-hidden': subirC === 'hidden'
    });
    const altura = '100vh';
    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
              <Route exact path="/:userweb" component={Pagina} />
                {(tipo!==2 )?<Fragment>
				<AppTopbar onToggleMenu={onToggleMenu} menuUsuario={user?.Usu_ID?modo(menuSesion):menuPublico} onMenuItemClick={onMenuItemClick} sidebarClassName={sidebarClassName}/>
                <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
                    <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick} style={{height:altura, overflow: 'hidden'}}>
                        <ScrollPanel style={{width: '100%', height:altura}}>
                        <div className="layout-logo" style={{marginTop: '5px'}}>
                            <img alt="Logo" src={logo} />
                        </div>
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
                        </ScrollPanel>
                    </div>
                </CSSTransition>
                <div className="layout-main" id="layoutMain" style={{display:zona.data?.Pais_ID?'block':'none'}}>
				
                <Supermenu/>

                    <Switch>
                     <Route exact path="/:pais/:departamento/:distProv/:categoria/:SubCategoria/:hoja" component={Listado} />
                     <Route exact path="/contacto/:contacname/:userweb" component={Contacto} />
                     <Route exact path="/modelo/web" component={Site} />
					 <Route exact path="/feedback/:userweb" component={Feedback} />
					 <Route exact path="/legal/:document" component={Agreement} />
					 <Route exact path="/acerca/about" component={About} />
                     <PublicRoute exact path="/usuario/login" component={Login} />
                     <PublicRoute exact path="/usuario/registro" component={Registro} />
                     <PublicRoute exact path="/usuario/recovery" component={Recovery} />
					 <PrivRoute exact path="/:contacname/puntuacion" component={Puntuacion} />
                     <PrivRoute exact path="/:username/perfil" component={Perfil} />
                     <PrivRoute exact path="/:username/contactos" component={Contactos} />
					 <PrivRoute exact path="/:username/facturacion" component={Billing} />
                     <PrivRoute exact path="/:username/notificacion" component={Notificacion} />
                     <PrivRoute exact path="/:username/categoria" component={Categoria} />
                     <PrivRoute exact path="/:username/ventana" component={Ventana} />
                     <Route exact path="/" component={Home} />
                    </Switch> 
                </div>
				</Fragment>:''}
                <span className={subirClassName} onClick={(e)=> {window.scrollTo({top: 0, behavior:'smooth'});}} style={{display:'inline'}}></span>
                <AppFooter />
        </div>
    );

}

export default App;