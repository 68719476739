import React, { useContext, useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import { AppSeeker } from './AppSeeker';
import { AppMenu } from './AppMenu';
import { StoreContext } from './store/StoreProvider';
import { types }  from './store/StoreReducer';
import { InfoData } from "./InfoData";


export function obtenerCookie(c_name)
{
	var c_value = document.cookie;
	var c_start = c_value.indexOf(" " + c_name + "=");
	if (c_start === -1){ c_start = c_value.indexOf(c_name + "="); }
	if (c_start === -1){ c_value = null; }
	else {
	  c_start = c_value.indexOf("=", c_start) + 1;
	  var c_end = c_value.indexOf(";", c_start);
	  if (c_end === -1){ c_end = c_value.length; }
	  c_value = unescape(c_value.substring(c_start,c_end));
	  }
	return c_value;
}


export const AppTopbar = (props) => {

const [store, dispatch] = useContext(StoreContext);
const {user, paises, ciudades, provincias, distritos, ubicacion, zona, ruta, img, alertas, home, logo, logom} = store;
const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem("selectedCountry")===null?'89':JSON.parse(localStorage.getItem("selectedCountry")) );
const [departamento, setDepartamento] = useState(localStorage.getItem("ciudad")===null?'':JSON.parse(localStorage.getItem("ciudad")) );
const [provincia, setProvincia] = useState(localStorage.getItem("provincia")===null?'':JSON.parse(localStorage.getItem("provincia")) );
const [distrito, setDistrito] = useState(localStorage.getItem("distrito")===null?'':JSON.parse(localStorage.getItem("distrito")) );
const [ubicacionesf, setUbicacionesf] = useState([{label: "", value: ""}]); 
const [menu, setMenu] = useState(''); 
//const usuario = obtenerCookie('usuario_nombre_intranet')===null?'':obtenerCookie('usuario_nombre_intranet')?.split('@')[1];
const usuario = obtenerCookie('Usuario')===null?'':obtenerCookie('Usuario')?.split('@')[1];
console.log(usuario);

useEffect(() => {
	if(user.logout){
	logout();
	console.log('Se acaba de desloguear alguien');
	}
	if(!!user.login){
	asignaUbicacion(user.Pais_ID, user.Departamento_ID, user.Provincia_ID, user.Distrito_ID);  
	obtenerlugares(user.Pais_ID, user.Departamento_ID);
	console.log('Se acaba de loguear alguien');
	}else{
	console.log('Se ha recargado la página');  
	obtenerPaises();
	estadoUsuario();
	localStorage.setItem('selectedCountry',JSON.stringify(selectedCountry)); 
	}
//eslint-disable-next-line
}, [!!user.login]);


const logout = () => {
	InfoData(types.authLogout, user.Usu_ID); 
	//dispatch({type:types.authLogout}); 
	dispatch({type: types.alertas, payload:''});
	dispatch({ type: types.formulario, payload: {editar:''} }); 
	dispatch({ type: types.especialidad, payload: [] });
	dispatch({ type: types.contactosUser, payload: [] });
}


const estadoUsuario = () => {
console.log('revisando estado de usuario');
	if(usuario==='' || user.logout){
	dispatch({type:types.authLogin, payload:{ login:false} });
	console.log('Sin usuario autenticado');	
	}
	else{
	if(usuario!==user.Usu_ID){
	subirDataUser(usuario); 
	console.log('subir data de usuario');  }
		}
    }
	

const obtenerPaises = async () => {
	if(paises.length===0){
    let db1 = await InfoData(types.paises, '');
    dispatch({type:types.paises, payload: db1.paises}); 
    localStorage.setItem('paises',JSON.stringify(db1.paises));}
	
	if(selectedCountry!==ciudades[0]?.Pais_ID){
		console.log('Si hay pais hay ciudades');
		localStorage.removeItem('ciudades');
		ubicacionNula();
		obtenerciudades(selectedCountry);
	}
	if(selectedCountry==='89') {Parametros(departamento, provincia, '', selectedCountry); }else{ Parametros(departamento, '', distrito, selectedCountry);  }
}


const obtenerlugares = async (pais, dpto) => {
	let db = await InfoData(types.ciudades, pais);
	dispatch({type:types.ciudades, payload: db.ciudades}); 
	localStorage.setItem('ciudades',JSON.stringify(db.ciudades));
	if(pais==='89'){
	let db1 = await InfoData(types.provincias, dpto);
	dispatch({type:types.provincias, payload: db1.provincias}); 
	localStorage.setItem('provincias',JSON.stringify(db1.provincias));		
	}else{
	obtenerDistritos('',dpto);		
	}
}


const subirDataUser = async (User) => {
	let db = await InfoData(types.authLogin, User);
	dispatch({type:types.ubicacion, payload:db?.user?.ubicacion});
	asignaUbicacion(db.user.perfil.Pais_ID, db.user.perfil.Departamento_ID, db.user.perfil.Provincia_ID, db.user.perfil.Distrito_ID);
	dispatch({type:types.ciudades,	payload: db.ciudades});  
	if(db.user.perfil.Pais_ID==='89'){
	dispatch({type:types.provincias, payload:db.provincias});
	}else{
	dispatch({type:types.distritos,	payload:db.distritos});
	}
	dispatch({type:types.authLogin, payload:db.user.perfil});
	localStorage.setItem('user',JSON.stringify(db.user.perfil));
	obtenerlugares(db.user.perfil.Pais_ID, db.user.perfil.Departamento_ID);
	let db3 = await InfoData(types.contactosUser, User);  
	dispatch({ type: types.contactosUser, payload: db3.data });
}


const asignaUbicacion = (pais, dpto, prov, dist ) => {
	setSelectedCountry(pais);
	setDepartamento(dpto);
	setProvincia(prov);
	setDistrito(dist);
	localStorage.setItem('selectedCountry',JSON.stringify(pais));  
	localStorage.setItem('ciudad',JSON.stringify(dpto));
	localStorage.setItem('provincia',JSON.stringify(prov));        
	localStorage.setItem('distrito',JSON.stringify(dist));  
	if(pais==='89'){Parametros(dpto, prov, '', pais); }else{
	Parametros(dpto, '', dist, pais);
	}
}
 
 
const Parametros = (Dpto,Prov,Dist,pais) => {
	let data={
	Pais_ID : pais,
	Pais : paises?.find(item => item.value === pais)?.label || 'el país',
	Departamento_ID : Dpto || '',
	Departamento : ciudades?.find(item => item.value === Dpto)?.label || '',
	Provincia_ID : Prov || '',
	Provincia : provincias?.find(item => item.value === Prov)?.label || '',  
	Distrito_ID : Dist || '',
	Distrito : distritos?.find(item => item.value === Dist)?.label || '', 
	};
	let query=Dist?'&d='+Dist:Prov?'&p='+Prov:Dpto?'&D='+Dpto:'&P='+pais;
	let Pais=data.Pais;
	let dpto=data.Departamento?data.Departamento:('todo '+data.Pais);
	let alterno='Todo '+data.Departamento;
	let prov=data.Provincia?data.Provincia:data.Departamento?alterno:'toda la región';
	let zona=data.Distrito?('distrito de '+data.Distrito):data.Provincia?('provincia de '+prov):prov;
	let v='/'
	let parametro=v+Pais+v+dpto+v+zona+v;
	parametro=parametro.split(' ').join('-');
	dispatch({type:types.zona, payload:{data,parametro,query}});
	return {data,parametro,query};
  }


const onCountryChange = (e) => {
	setSelectedCountry(e.value);
	localStorage.setItem('selectedCountry',JSON.stringify(e.value));
	Parametros('','','',e.value);
	obtenerciudades(e.value);  
	ubicacionNula();
    }

	
const ubicacionNula = () => {
	setDepartamento('');
	setProvincia('');
	setDistrito('');  
	localStorage.removeItem('ciudad');
	localStorage.removeItem('provincia');        
	localStorage.removeItem('distrito');  
}


const onCiudadChange = (e) => {
	setDepartamento(e.value);
	setProvincia('');
	setDistrito('');
	localStorage.setItem('ciudad',JSON.stringify(e.value));
	localStorage.removeItem('provincia');        
	localStorage.removeItem('distrito');          
	if(selectedCountry==='89'){
		obtenerProvincias(e.value);
		}else{
		dispatch({type:types.provincias, payload:[]});
		localStorage.removeItem('provincias');
		obtenerDistritos('',e.value);
		}
	Parametros(e.value,'','',selectedCountry);
	}

const expandir = (caso) => {
  if(caso===1){ dispatch({type:types.provincias, payload:[]}); setProvincia(''); setDistrito(''); obtenerDistritos('',departamento); }
  if(caso===2){ setProvincia(''); setDistrito(''); obtenerProvincias(departamento); Parametros(departamento,provincia,'',selectedCountry);}
    }


const box = (caso) => {
	let opciones=document.getElementById('opcionesBusca');
	let buscador=document.getElementById('buscador');  
	let caja=document.getElementsByClassName('buscarDistrito');
	switch(caso){
	case 0:
	opciones.classList.add('oculto');
	buscador.classList.remove('oculto');
	setMenu('ok');
	break;
	case 1:
	opciones.classList.remove('oculto');
	buscador.classList.add('oculto');
	caja[0].classList.add('oculto');
	setMenu('');
	break;
	case 2:
	if(menu === ''){
	opciones.classList.remove('oculto');
	buscador.classList.add('oculto');
	caja[0].classList.add('oculto');}
	break; 
	default: 
	console.log("No hay coincidencias");
  }
}   


const onHome = () => {

	}


const obtenerciudades = async (pais) => {
	let db = await InfoData(types.ciudades, pais);
	dispatch({type:types.ciudades, payload: db.ciudades}); 
	localStorage.setItem('ciudades',JSON.stringify(db.ciudades));
	console.log(db.ciudades);
	if(pais==='89'){}
	dispatch({type:types.provincias, payload:[]}); 
	localStorage.setItem('provincias',JSON.stringify([]));		
	dispatch({type:types.distritos, payload: []});  
	localStorage.setItem('distritos',JSON.stringify([]));
}


const onProvinciaChange = (e) => {
    setProvincia(e.value);
    obtenerDistritos(e.value,'');
    setDistrito('');
    localStorage.setItem('provincia',JSON.stringify(e.value));        
    localStorage.removeItem('distrito'); 
    Parametros(departamento,e.value,'',selectedCountry);
    }
  
  
const obtenerProvincias = async (dpto) => {
    let db = await InfoData(types.provincias, dpto);
    dispatch({type:types.provincias, payload: db.provincias}); 
    localStorage.setItem('provincias',JSON.stringify(db.provincias));
    dispatch({type:types.distritos, payload: []});  
    localStorage.removeItem('distritos');
}


const onDistritoChange = (e) => {
    setDistrito(e.value);
    localStorage.setItem('distrito',JSON.stringify(e.value));    
    Parametros(departamento,provincia,e.value,selectedCountry);
    }
  
  
const obtenerDistritos = async (prov,dpto) => {
    let db = await InfoData(types.distritos, {prov:prov,dpto:dpto});
    dispatch({type:types.distritos, payload: db.distritos});  
    localStorage.setItem('distritos',JSON.stringify(db.distritos));
}


const selectedCountryTemplate = (option, props) => {
    if (option) {
//	<div style={{flex: 'none'}}>{window.innerWidth>'800'?option.label:option.code.toUpperCase()}</div>	
        return (
            <div className="country-item country-item-value">
                <img alt={option.label} src={ruta+img.flag} onError={e => e.target.src = ruta+img.flagEr} className={`flag flag-${option.code.toLowerCase()}`} />
                <div style={{flex: 'none'}}><span className='escritorio'>{option.label}</span><span className='movil'>{option.code.toUpperCase()}</span></div>				
            </div>
        );
    }
    return ( <span>{props.placeholder}</span> );
  }



const countryOptionTemplate = (option) => {
    return (
        <div className="country-item">
            <img alt={option.label} src={ruta+img.flag} onError={(e) => e.target.src=''} className={`flag flag-${option.code?.toLowerCase()}`} />
            <div style={{flex: 'none'}}>{option.label}</div>
        </div>
    );
  }

  
const ubicacionChange = (e) => {
	dispatch({type:types.ubicacion, payload:e.value});
	let country = e.value.Pais_ID;
	let depart = e.value.Departamento_ID; 
	let distrit = e.value.Distrito_ID;
	if(typeof distrit!=='undefined' && distrit!=='') { 
	setDistrito(distrit); }
	if(typeof depart!=='undefined'){
	setDepartamento(depart); }
	if(typeof country!=='undefined' ) {
	setSelectedCountry(country);
	localStorage.setItem('selectedCountry',JSON.stringify(country));
	localStorage.setItem('ciudad',JSON.stringify(depart));
	localStorage.removeItem('provincia');  
	localStorage.setItem('distrito',JSON.stringify(distrit));
	obtenerciudades(country);
	obtenerDistritos('',depart); dispatch({type:types.provincias, payload:[]});
	box(1);
	Parametros(depart,'',distrit,country);
  }
} 


const filtroubicaciones = async (event) => {    
	var resource = [];
	var ubica = [];
	if(event.query.length >= 1){
	resource = await InfoData('ubicacion', {nombre:event.query.toLowerCase(), distrito_ID:''});   
	ubica = resource.data; }; 
    setTimeout(() => {
    var results = ubica.filter((ubicacion) => {
    return ubicacion.label.toLowerCase();
    });
    setUbicacionesf(results);
        }, 350);
    }

  
const regresar = () => {
	document.getElementById('lateral').style.width='20em'
	document.getElementById('categorias').style.display='block';
	document.getElementById('cerrar').style.display='block';
	document.getElementById('regreso').style.display='none';
	document.getElementById('superMenu').style.position='fixed';	
  };    


  const paisesEs = paises.map((pais) => (pais.idioma==='es'&&(pais.code!=='gq'||pais.code!=='do'))?<li key={pais.value} style={{display:'flex', margin:'0.35em 0'}} onClick={()=>onCountryChange({value:pais.value})}> 
                <img alt={pais.label} src={ruta+img.flag} onError={e => e.target.src = ruta+img.flagEr} className={`pop flag flag-${pais.code.toLowerCase()}`} />
				<div style={{flex: 'none'}}><span>{pais.label}</span></div>
  </li>:'');
  const mensajes = alertas ? <span className="layout-topbar-badge">{user.Nombres ? alertas:0}</span>:'';
  return (
    <Fragment>
      <div className="layout-topbar">
              {user.Modo==='1'?<button type="button" className="p-link layout-menu-button oculto" onClick={props.onToggleMenu} style={{'float':'left', padding:'0.8em'}}>
                  <span className="pi pi-bars"/>
              </button>:''}
          <div style={{'float':'left'}}>
             <Link to= {home}> 
             <img src={ruta+logo} className="escritorio" height='47' alt={"servicontacto.com"} onClick={e => onHome()}/>
             <img src={ruta+logom} className="movil" height='47' alt={"servicontacto.com"} onClick={e => onHome()}/>
             </Link>
          </div>
          <div className="layout-topbar-icons" style={{'float':'right'}}>
              <button id='boto' onClick={()=> document.getElementById('menuUser').classList.toggle('oculto')} >
                  <span className="username" style={{verticalAlign:'top', color:'#495057', fontSize:'15px'}}>{user.Nombres ? user.Nombres: 'Hola, Identifícate'}</span>
                  <span className="layout-topbar-icon pi pi-fw pi-angle-down" style={{fontSize:'1.3em',marginLeft:'0.5em'}}/>
                  {mensajes}
              </button>
			  <div id="menuUser" className="oculto"  onMouseLeave={()=> document.getElementById('menuUser').classList.add('oculto')}> 
				  <div className={props.sidebarClassName} style={{width:'11em', height:'auto', position:'unset' }} >
					  <AppMenu model={props.menuUsuario}  onMenuItemClick={props.onMenuItemClick}/>
				  </div>            
			  </div>
          </div>
          <div className="layout-topbar-pais escritorio" style={{'display': departamento ? 'inline-block' : 'none', margin:'0.7em 0px'}}>
             <Dropdown value={departamento} options={ciudades} onChange={onCiudadChange} placeholder="Departamento"/>
          </div>
          <div className="layout-topbar-pais" style={{margin:'0.7em 0px'}}>
             <Dropdown value={selectedCountry} options={paises} onChange={onCountryChange} placeholder="Pais"
              valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} />
          </div>
      </div>
	  <div id="regreso">
		  <div className="btnAmarillo" onClick={(e) => regresar() }  style={{float:'left', width:'40%', padding:'1em'}}>
		  REGRESAR</div>
		  <div id="mensaje" style={{float:'right', padding:'1em 0', width:'60%', fontSize:'1.25em', color: '#006aa7' }}>
		  Mensaje</div>					  
	  </div> 	  
      <div  className="buscarDistrito oculto"> 
		  <ul id='opcionesBusca' onMouseLeave={(e) => box(2)}>
			<li className="menuHome" onClick={(e) => expandir(1) } style={{'display': provincias?.length !== 0 ? selectedCountry === '89' ? departamento!==''?'block':' none' : 'none' : 'none'}}>{'Listar todos los distritos del departamento de '+zona.data?.Departamento}</li>
			<li className="menuHome" onClick={(e) => expandir(2) } style={{'display': provincias?.length === 0 ? selectedCountry === '89' ? departamento!==''?'block':' none' : 'none' : 'none'}}>{'Listar las provincias del departamento de '+zona.data?.Departamento}</li>
			<li className="menuHome" onClick={(e) => box(0)}>Busqueda directa por nombre de distrito</li>
		  </ul>
          <div id='buscador' className="reg-content-wrapper oculto" style={{padding:'2em', color:'#006aa7'}}>
          <i className='pi pi-times cerrar' onClick={() => box(1)}/><h1>Búsqueda de ubicación</h1><label className="float-label" htmlFor="inubicacion">Buscar distrito por nombre</label>
          <AutoComplete style={{width:'100%'}} id="inubicacion" value={ubicacion} suggestions={ubicacionesf} completeMethod={filtroubicaciones} field="label" size={30} placeholder="Escribe el nombre de tu distrito" minLength={1} onChange={ubicacionChange}/>
		  </div>
      </div>
      <AppSeeker style={{top:'54px', position:'fixed', paddingTop:'8px', width:'100%', zIndex:'7'}} box = {box} expandir = {expandir} onCiudadChange={onCiudadChange} ciudades={ciudades} pais={zona.data?.Pais} departamento={departamento}  onProvinciaChange={provincias?.length!==0?onProvinciaChange:onDistritoChange} provincias={provincias?.length!==0?provincias:distritos} dpto={zona.data?.Departamento} provincia={provincias?.length!==0?provincia:distrito}/>
		{selectedCountry===''?
	  <div className="evaluar" style={{background:'#fecc00', color:'#fff', position:'relative',zIndex:'10',width:'85%', maxWidth:'400px', margin:'auto', top:'2.5em',  textAlign:'center'}}>
		<img className="escritorio" src={ruta+logo} height='47' alt={"servicontacto.com"}/>
		  <ul className="pais">
			{paisesEs}			
		  </ul>		  
	  </div>
	  :''}
	  
    </Fragment>
  );

}