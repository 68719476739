import React, { /*useContext,*/ useRef, useEffect } from 'react';
// import { StoreContext } from "./store/StoreProvider";
import styled from "styled-components";
import {ReactComponent as FlechaIzq} from './assets/images/angel-left-thin.svg';
import {ReactComponent as FlechaDer} from './assets/images/angel-right-thin.svg';

const Slideshow = ({children}) => {
/*  const [store] = useContext(StoreContext);
  const {ruta,img} = store;
  const images = [
	{ "itemImageSrc": ruta + img.portada,  "alt":"Descripción de imagen 1" },
	{ "itemImageSrc": ruta + img.portada1, "alt":"Descripción de imagen 2" },
  ];  	*/
  const slideshow = useRef(null);
  const intervaloSlideshow = useRef(null);  
  
  useEffect(()=> {
	intervaloSlideshow.current = setInterval(() => { siguiente() }, 5000);

	// Eliminamos los intervalos
	slideshow.current.addEventListener('mouseenter', ()=>{ clearInterval(intervaloSlideshow.current);} );

	// Ponemos de nuevo el intervalo al sacar el cursor
	slideshow.current.addEventListener('mouseleave', ()=>{ intervaloSlideshow.current = setInterval(() => { siguiente() }, 5000); });
  }, []);
  
  const siguiente = () => {
	// Comprobar que slideshow tenga elementos
	if(slideshow.current?.children.length > 1){
		// obtener primer elemento
		const primerElemento = slideshow.current.children[0];
		
		//establecemos la transición para el slideshow
		slideshow.current.style.transition = '500ms ease-out all';
		
		const anchoSlide = slideshow.current.children[0].offsetWidth;

		// movemos el slideshow
		slideshow.current.style.transform = `translateX(-${anchoSlide}px)`;
				
		// 
		const transicion = () => {
			// Reiniciamos la posición del Slideshow.
			slideshow.current.style.transition = 'none';
			slideshow.current.style.transform = `translateX(0)`;
			
			// Tomamos el primer elemento y lo mandamos al final.
			slideshow.current.appendChild(primerElemento);
			
			slideshow.current.removeEventListener('transitionend', transicion);
		}
		
		// EventListener para cuando termine la animación
		slideshow.current.addEventListener('transitionend', transicion);
	}
  }
  
  const anterior = () => {
	if(slideshow.current.children.length > 1){
		// obtener último elemento.
		const index = slideshow.current.children.length - 1;
		const ultimoElemento = slideshow.current.children[index];
		slideshow.current.insertBefore(ultimoElemento, slideshow.current.firstChild);
		
		slideshow.current.style.transition = 'none';
		const anchoSlide = slideshow.current.children[0].offsetWidth;
		slideshow.current.style.transform = `translateX(-${anchoSlide}px)`;
		
		setTimeout(() => { 
		slideshow.current.style.transition = '500ms ease-out all';
		slideshow.current.style.transform = `translateX(0)`;
		}, 30);
	}
  }  
  
  
  

  

	return (
		<ContenedorPrincipal>
			<ContenedorSlideshow ref={slideshow}>
				{children}
			</ContenedorSlideshow>
			{slideshow.current?.children.length > 1 ?
			<Controles>
				<Boton onClick={anterior}>
					<FlechaIzq/>
				</Boton>
				<Boton derecho onClick={siguiente}>
					<FlechaDer/>
				</Boton>
			</Controles>:''}
		</ContenedorPrincipal>	
	);
}


const ContenedorPrincipal = styled.div`
/*	max-width:1000px;
	margin: 50px auto;*/
	overflow:hidden;
	position: relative;
	width: 100%;
`

const ContenedorSlideshow = styled.div`
	display:flex;
	flex-wrap: nowrap;
`

const Slide = styled.div`
	min-width: 100%;
	overflow: hidden;
	transition: .3s ease all;
	z-index:10;
	position: relative;
	
	img {
		width: 100%;
		vertical-align: top;
	}

	@media screen and (min-width:1320px)
	{ min-width: ${props => props.sc ? '158%':'100%'};
	left: ${props => props.sc ? '-30%':''};	}
`

const Controles = styled.div`
	position: absolute;
	top: 0;
	z-index:20;
	width: 100%;
	height: 100%;
	pointer-events:none;
`

const Boton = styled.button`
	pointer-events:all;
	background:none;
	border:none;
	cursor:pointer;
	outline:none;
	width:50px;
	height:100%;
	text-align: center;
	position:absolute;
	transition: .3s ease all;
	
	&:hover {
	/*background: rgba(0,0,0,.2);*/
	path { fill:#fff;}
	}
	
	path { filter: ${props => props.derecho ? 'drop-shadow(-2px 0px 0px #fff)':'drop-shadow(2px 0px 0px #fff)'}}
	
	${props => props.derecho ? 'right:0':'left:0'}
`


export { Slideshow, Slide};