import 'react-app-polyfill/ie11';
import { BrowserRouter } from 'react-router-dom';
import StoreProvider from './store/StoreProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
<BrowserRouter> 
    <StoreProvider>
    <App/>
    </StoreProvider>
</BrowserRouter>,
document.querySelector("#root")
);

